import React from 'react'
import CandidateDetailsUI from '../../layouts/Twilio/CandidateDetailsUI'
import ProviderDetailsUI from '../../layouts/Twilio/ProviderDetailsUI'
import { useParams } from 'react-router-dom'
import { BSON } from 'realm-web'

const TwilioDetailsContainer = ({ mongoContext : {  client, setUserData, userData } }) => {
  //get settings id
  const { newUserId } = useParams()
 
  const [ textUser, setTextUser ] = React.useState({})

  React.useEffect(() => {
    const getRole = async () => {   
       //get a reference to the settings collection
      const collection = client?.db('kinshealth').collection('users')
      //use params id to fetch document with supplied id
      setTextUser(await collection?.findOne({ _id: BSON.ObjectID( newUserId )}))     
    }

    getRole()

  }, [ newUserId, client])

  console.log('user dat ', textUser)

  return (
    <div>
      {
        textUser?.role === 'provider' && (<ProviderDetailsUI client={client} newUserId={newUserId} setUserData={setUserData} />) 
      }
      {
        textUser?.role ==='caregiver' && (< CandidateDetailsUI client={client} newUserId={newUserId} setUserData={setUserData} /> )
      }
    </div>
  )

}

export default TwilioDetailsContainer
