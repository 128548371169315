import {
    Form,
    SubmitButton,   
    Checkbox
} from 'formik-semantic-ui-react'
import { BSON } from 'realm-web'
import { useHistory } from 'react-router-dom'
import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm,
    Header as SemanticHeader,
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import TagManager from 'react-gtm-module';

const AddCandidatesDetailsFormUI = ({ client, newUserId, setUserData }) => {      

    const [ loading, setLoading ] = React.useState(false)
    
    const history = useHistory()
   
    const userSettingsSchema = Yup.object().shape({        
        alert_preferences: Yup.array().min(1, 'Select at least 1 way for caregivers to contact you').required('required'),        
        availability: Yup.array().min(1, "Select at least 1 schedule you are available to work.").required('required'), //.min(1, "at least 1")
        licenses: Yup.array().min(1).required('Please select one type of license.')       
    })  

    // console.log('client in the application form ', client, 'user ', user)
    return (     
        <div>
            <Container style={{ maxWidth: 550, marginTop: 30, marginBottom: 75 }}>
                <Grid centered stackable columns={1} >  
                    <Grid.Row>                        
                        <Grid.Column width={10}>
                        <SemanticHeader as='h3'>Please enter the following information</SemanticHeader>
                            <Segment> 
                            <Formik
                                initialValues = {{                                    
                                    alert_preferences: [],                                                                   
                                    availability: [],                                     
                                    licenses: []                                                                
                                }}

                                validationSchema = { userSettingsSchema }

                                onSubmit={ async (values, { setSubmitting }) => {
                                    // console.log('values ', values, 'and set submitting ', setSubmitting)
                                    //set submitting to true
                                    setSubmitting(true)
                                    //set user data
                                    setLoading(true)
                                    //create a reference to users collections in db
                                    const collection = client?.db('kinshealth').collection('users')
                                    //update settings with new data
                                    const results = await collection.updateOne(
                                        { _id : BSON.ObjectID(newUserId) }, 
                                        { $set : 
                                            {                                               
                                                "settings.alert_preferences" : values.alert_preferences, 
                                                availability: values.availability,
                                                licenses: values.licenses                                       
                                            }
                                        },
                                       // { upsert: true }
                                    )

                                    if(results.modifiedCount === 1 ){
                                        setSubmitting(false)
                                        //set loading to false
                                        setLoading(false)

                                        const tagManagerArgs = {
                                            dataLayer: {
                                                event: `caregiver_add_twilio_details`, 
                                                added: new Date(),   
                                                type: 'Twilio',                                                                          
                                                userId: newUserId      
                                            }           
                                        }
                                
                                        TagManager.dataLayer(tagManagerArgs)
                                        //update user data
                                        setUserData(await collection?.findOne({ _id: BSON.ObjectID(newUserId)}))
                                        //push user to the sign up page
                                        history.push(`/auth/${newUserId}`)
                                    }    
                                }}                        
                            > 
                            {
                                ({ errors, touched, values }) => (
                                   
                                    <Form  >
                                        <label><b>Select your availability:</b></label><br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Checkbox
                                                value="Full time"
                                                name="full_time"
                                                label="Full time"
                                                className='caregiver_add_availability'
                                                onChange={() => {
                                                        if(values.availability.includes("Full time")){
                                                            const index = values.availability.indexOf("Full time");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Full time")
                                                    }                                                   
                                                }
                                                required                     
                                            />
                                            <Checkbox
                                                value="Part time"
                                                name="part_time"
                                                label="Part time"
                                                className='caregiver_add_availability'
                                                onChange={() => {
                                                        if(values.availability.includes("Part time")){
                                                            const index = values.availability.indexOf("Part time");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Part time")
                                                    }                                                   
                                                }
                                                required                         
                                            />
                                            <Checkbox
                                                value="Weekends"
                                                name="weekends"
                                                label="Weekends" 
                                                className='caregiver_add_availability'
                                                onChange={() => {
                                                        if(values.availability.includes("Weekends")){
                                                            const index = values.availability.indexOf("Weekends");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Weekends")
                                                    }                                                   
                                                }
                                                required           
                                            />
                                            <Checkbox
                                                value="On Call"
                                                name="on_call"
                                                label="On Call"       
                                                className='caregiver_add_availability'                                      
                                                onChange={() => {
                                                        if(values.availability.includes("On Call")){
                                                            const index = values.availability.indexOf("On Call");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("On Call")
                                                    }                                                   
                                                }
                                                required                
                                            />
                                            <Checkbox
                                                value="Live In"
                                                name="live_in"
                                                label="Live In"  
                                                className='caregiver_add_availability'                                           
                                                onChange={() => {
                                                        if(values.availability.includes("Live In")){
                                                            const index = values.availability.indexOf("Live In");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Live In")
                                                    }                                                   
                                                }
                                                required                
                                            />
                                        </SemanticForm.Group> 
                                             
                                        <label><b>What license do you have?</b></label><br/>
                                        <SemanticForm.Group widths="equal" inline required>                                            
                                            <Checkbox                                                
                                                value="CNA"
                                                name="CNA"
                                                label="CNA or NAC" 
                                                className='caregiver_add_license'
                                                onChange={() => {
                                                        if(values.licenses.includes("CNA")){
                                                            const index = values.licenses.indexOf("CNA");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("CNA")
                                                    }                                                   
                                                }                                              
                                                required                     
                                            />
                                            <Checkbox
                                                className='caregiver_add_license'
                                                value="HCA"
                                                name="HCA"
                                                label="HCA"        
                                                onChange={() => {
                                                        if(values.licenses.includes("HCA")){
                                                            const index = values.licenses.indexOf("HCA");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("HCA")
                                                    }                                                   
                                                }                                                
                                                required                         
                                            />
                                            <Checkbox
                                                className='caregiver_add_license'
                                                value="NAR"
                                                name="NAR"
                                                label="NAR"                                             
                                                required    
                                                onChange={() => {
                                                        if(values.licenses.includes("NAR")){
                                                            const index = values.licenses.indexOf("NAR");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("NAR")
                                                    }                                                   
                                                }           
                                            />
                                            <Checkbox
                                                className='caregiver_add_license'
                                                value="None"
                                                name="None"
                                                label="None"                                             
                                                required
                                                onChange={() => {
                                                        if(values.licenses.includes("None")){
                                                            const index = values.licenses.indexOf("None");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("None")
                                                    }                                                   
                                                }                  
                                            />
                                        </SemanticForm.Group>   

                                        <label><b>How do you want caregivers to contact you? [Must choose one]:</b></label> <br/>                                        
                                        <SemanticForm.Group widths="equal">                                            
                                            <Checkbox                             
                                                name="phone_call"                                                
                                                label="Phone call"
                                                value="phone_call"
                                                className='caregiver_add_alert_preference'
                                                required
                                                onChange={() => {
                                                    if(values?.alert_preferences?.includes("Phone call")){
                                                        const index = values.alert_preferences.indexOf("Phone call");
                                                        if (index > -1) {
                                                            values.alert_preferences.splice(index, 1);
                                                        }
                                                    } else values.alert_preferences.push("Phone call")
                                                    }                                                   
                                                }                                                
                                            />   
                                            <Checkbox                               
                                                name="sms"
                                                value="sms"
                                                label="SMS/Text message"
                                                className='caregiver_add_alert_preference'
                                                required
                                                onChange={() => {
                                                        if(values?.alert_preferences?.includes("SMS/Text message")){
                                                            const index = values.alert_preferences.indexOf("SMS/Text message");
                                                            if (index > -1) {
                                                                values?.alert_preferences?.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("SMS/Text message")
                                                    }                                                   
                                                }                                                 
                                            />     
                                            <Checkbox                                
                                                name="email"
                                                value="email"
                                                label="Email"
                                                className='caregiver_add_alert_preference'
                                                required
                                                onChange={() => {
                                                        if(values.alert_preferences.includes("Email")){
                                                            const index = values.alert_preferences.indexOf("Email");
                                                            if (index > -1) {
                                                                values.alert_preferences.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("Email")
                                                    }                                                   
                                                } 
                                            />                                                      
                                        </SemanticForm.Group>

                                        { touched.alert_preferences && errors.alert_preferences ? <Message negative>{errors.alert_preferences}</Message> : null }                                        
                                    
                                        <SubmitButton 
                                            fluid                               
                                            id="caregiver_submit_details"     
                                            // disabled={registerFormValid || loading }                                
                                            loading={loading}
                                            primary
                                            type="submit"
                                        >
                                            Submit
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default AddCandidatesDetailsFormUI
