import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Form, 
  Button, 
  Divider,
  Message,
  Grid,
  Header as SemanticHeader,
  Segment,
} from "semantic-ui-react"

const ResetPasswordFormUI = ( { mongoContext: { app, setUser, user }, form : { onChange,  registerFormValid, form, passwordCheck }} ) => {    

    //initiate state
    const [ error, setError ] = useState({        
        message: "",
        error_state: false
    })
    
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const tokenId = params.get("tokenId");

    console.log('token ', token, 'and token id ', tokenId)

    const history = useHistory()

    const onSubmit = async (e) => {    

        e.preventDefault()   
        
        try{

            console.log('password ', form.password)
            await app?.emailPasswordAuth?.resetPassword({
                password: form.password,
                token,
                tokenId,
            })
            
            alert('Your password has been reset. Now please sign in.')

            history.push('/signin')
 
        } catch (err){
          
            //capitalize the first letter of the error message before sending to user         
            setError({
                message: err.error.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign in instead.',
                error_state: true
            })
        }            
    }  
   
  return (
    <div> 
        <Grid centered>
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 550, marginTop: 20 }}>                       
                    <SemanticHeader as='h2'>Reset password</SemanticHeader>

                    <Segment>
                        <Form onSubmit={onSubmit}>
                            {/* <Form.Field>
                                <SemanticHeader as='h3'>Reset your password </SemanticHeader>
                            </Form.Field> */}
                             
                            
                            <Form.Field>
                                <Form.Input
                                    value={ form.password || "" }
                                    onChange={onChange}
                                    type="password"
                                    name="password"
                                    placeholder="Enter new password"
                                    label="Password"
                                    minLength="6"                                
                                    required
                                />
                            </Form.Field>
                            { passwordCheck() && (<Message negative>
                                <Message.Header>Your password and confirm password must match </Message.Header>                            
                            </Message>) }

                            <Form.Field>
                                <Form.Input
                                    value={form.password2 || ""}                                   
                                    type="password"
                                    name="password2"
                                    placeholder="Confirm password"
                                    label="Confirm Password"
                                    onChange={ onChange }
                                    minLength="6"                               
                                    required
                                />
                            </Form.Field>

                            <Button
                            // onClick={onSubmit}
                                disabled={registerFormValid }
                                fluid
                                // loading={loading}
                                primary
                                type="submit"
                            >
                            Reset password
                            </Button>
                        </Form>                
                    </Segment>
                    { error.error_state && (<Message centered negative>
                            <Message.Header> {error.message} </Message.Header>                            
                    </Message>) }
                   
                    <Divider horizontal hidden />
                </Grid.Column>
            </Grid.Row>
            
            
        </Grid>   
          
    </div>
  )
}

export default ResetPasswordFormUI

//google