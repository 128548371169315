import React from 'react'
//import { Redirect,  withRouter } from 'react-router-dom';
import {
    Card,
    Button,  
    Message,
    Container,
    Grid,  
    Divider,
    Placeholder,
    Icon,
    List,
    Header
} from "semantic-ui-react";
import { Link } from 'react-router-dom'
import EmployerPanel from '../../../components/EmployerPanel';

const ViewSinglePostingUI = ({ job, error, loading, user }) => {   
    //get the price to charge provider to charge
    //if the provider is a subscriber, the price will be $17.99 or else it's $39.99
    const amount = user?.customData?.plan === 'basic' ?  39.99 : 17.99 
    return (     
        <div>            
            <style>
                {`#p-wrap {
                    white-space: pre-line;
                }`}
            </style> 
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid centered stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                        {
                            error && !loading && (                                
                                <Message>
                                <Message.Header>Oops!  Something went amock!</Message.Header>
                                    <p>
                                        We couldn't fetch you the caregiver's resume.  Log out and then back in and try again :(.
                                    </p>
                                </Message>                               
                            )
                        }

                        {
                            loading && !error && (
                                
                                <Placeholder>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                                // </Grid.Column>
                            )
                        }

                        { job && !loading && 
                        (
                            <>
                                <div id='job_post_preview'>
                                    <Message color='black'>
                                    <Message.Header>Draft job post</Message.Header>
                                        <p>
                                            Scroll down to complete posting your job.
                                        </p>
                                    </Message>
                                </div>
                                 

                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>{ job.title }</Card.Header>                                       
                                    </Card.Content> 

                                    <Card.Content>
                                        <Header as='h4'>Licenses</Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { job?.licenses?.map((license, index)=> (
                                                    <List.Item key={index}>{license}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                    </Card.Content>                                    
                                
                                    <Card.Content>
                                        <Header as='h4'>Certifications</Header>
                                        <Card.Description><p id='p-wrap'>{job?.certifications}</p></Card.Description>  
                                    </Card.Content>

                                    <Card.Content>
                                        <Header as='h4'>Description</Header>
                                        <Card.Description><p id='p-wrap'>{job?.description }</p></Card.Description>  
                                    </Card.Content>                                                                    
                                
                                    <Card.Content>
                                        <Header as='h4'>Availability</Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { job?.schedule?.map((schedule, index)=> (
                                                    <List.Item key={index}>{schedule}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                    </Card.Content>

                                    <Card.Content>
                                        <Header as='h4'>Compensation</Header>
                                        <Card.Description> {job?.compensation} </Card.Description>
                                    </Card.Content>
                                    {
                                        job?.mobility==='car_needed' && 
                                        (<Card.Content>
                                            <Header as='h4'>Mobile </Header>  
                                            <Card.Description>
                                                Job requires caregiver to drive
                                            </Card.Description>                                  
                                        </Card.Content>) 
                                    }  
                                    {
                                        job?.mobility==='no_car_needed' && 
                                        (<Card.Content>
                                            <Header as='h4'>Not mobile</Header> 
                                            <Card.Description>
                                                Job doesn't require caregiver to drive
                                            </Card.Description>                                      
                                        </Card.Content>) 
                                    } 

                                    <Card.Content>
                                        <Header as='h4'>Job location</Header>
                                        <Card.Description> {job?.contacts?.address}, {job?.contacts?.city}, {job?.contacts?.zipcode} </Card.Description>                                       
                                    </Card.Content> 
                                    <Card.Content>
                                        <Header as='h4'>How you want to be contacted - [Visit settings tab to change your alert preferences].</Header>
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { user?.customData?.alert_preferences?.map((preference, index)=> (
                                                    <List.Item key={index}>{preference}</List.Item>
                                                ))}
                                            </List>
                                        </Card.Description>
                                    </Card.Content>                                   
                                </Card>                        
                                <Divider hidden />                               
                                
                                {!user?.customData?.trial ? 
                                    (<Button as={Link}
                                        to={{
                                            pathname: `/provider/payment/posting/${job._id}`,                                     
                                            state: {jobId: job._id}                               
                                        }}                            
                                        floated="right" 
                                        id="provider_pay_for_job_post"
                                        color='blue'
                                        size='big' 
                                        icon>
                                        <Icon name="credit card"></Icon>
                                        Pay ${amount} to post
                                    </Button>)
                                    :
                                    <Button as={Link}
                                        to={{
                                            pathname: `/provider/job/view/${job._id}`,                                     
                                            state: {jobId: job._id} 
                                        }}
                                        floated="right" 
                                        color='blue'
                                        size='big' 
                                        id="provider_on_trial_post_job"
                                        icon>
                                        <Icon name="add"></Icon>
                                        Post job opening
                                    </Button>
                                }
                                
                                <Button as={Link}
                                    to={{
                                        pathname:`/provider/job/form/update/${job._id}`                                  
                                    }}                            
                                    floated="right" 
                                    positive
                                    size='big' 
                                    id='provider_update_new_job'
                                    icon>
                                    <Icon name="edit"></Icon>
                                    Edit job post
                                </Button>                            
                            </>                      
                        )
                        }
                        
                        </Grid.Column>
                    </Grid.Row>                   
                </Grid> 
            </Container>
        </div>
    )
}

export default ViewSinglePostingUI