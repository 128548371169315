import React from 'react'
import {  
    Button,
    Container,   
    Grid,
    Header,
    Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom"

const FooterFindJobUI = () => {         

    return (     
        <Container style={{ maxWidth: 650, margin: 50 }}>
            <Grid centered stackable>              
                <Grid.Row>                   
                    <Grid.Column width={8}>                   
                    <Header as='h4'>
                        Become a caregiver/CNA
                    </Header>
                    <p>
                        The health care industry abounds with great careers and one of the best way to get a start is to become a caregiver or a CNA.
                        <br/><br/>
                    
                        There has never been a better time to be a caregiver.  <b>The pay is great; you can choose how often and how long you want to work e.g., part time, live-in, weekends etc; you can work in clinics, hospitals, nursing homes or adult family homes and you can get tuition support from some employers.</b>
                        <br/><br/>
                        A career in caregiving is also a great first step on the path towards medicine, nursing (LPN/RN/BSN/MSN), physician assistant (PA) pharmacy, respiratory tech, ultra sound tech etc.  <br/><br/>

                        <b>Our focus is to make it easy for you to find a caregiving/CNA jobs. </b> So, whether you are jobless or you are looking for a second job, Kinscare is the best way to find a caregiving job.  

                        <b>You can search jobs by shift availability and/or license requirements.</b> To assist you with your job search, Kinscare will:
                        <ol>
                            <li>alert you when a new job opening is posted</li>
                            <li>let you apply for a job opening and contact providers directly</li>
                            <li>send you a weekly summary of caregiver jobs every Morning morning</li>
                        </ol>
                        
                        <b>Sign up below to find search for full time, part time, on call, and live in caregiving jobs.</b><br/><br/>

                        <Button as={Link} to="/signup" fluid primary icon>
                        <Icon name="sign-in"></Icon>
                            Sign up
                        </Button>                  
                    </p>                                                               
                    </Grid.Column>
                </Grid.Row>                  
            </Grid>
        </Container>
    )
}

export default FooterFindJobUI