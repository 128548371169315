import {
    Form,
    SubmitButton,  
    Radio, 
    Checkbox,
    Input
} from 'formik-semantic-ui-react'
import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import EmployerPanel from '../../../components/EmployerPanel'
import { useHistory } from 'react-router-dom'
import React from 'react'
import TagManager from 'react-gtm-module';

const UpdateSettingsForm =  ({mongoContext: { setUserData, user, userData }}) => {      

    const history = useHistory();  
    const [ loading, setLoading ] = React.useState(false)

    //console.log('provider settings outside use effect ', providerSettings)
    const userSettingsSchema = Yup.object().shape({
        address: Yup.string().required("Please enter provider's street address"),//.max(2, 'Full name can only be 6 characters long.'),
        alert_preferences: Yup.array().min(1, 'Select at least 1 way for caregivers to contact you').required('required'),
        city: Yup.string().required('Please enter city'),
        first: Yup.string().required('Please enter the first name of the hiring staff'),
        last: Yup.string().required('Please enter the last name of the hiring staff'),
        provider_email: Yup.string().email().required('Please enter the provider\'s telephone number'),
        name: Yup.string().required('Please enter the full name of the provider/organization'),
        provider_tel: Yup.string().required('Please enter your telephone #'),
        trainer: Yup.string().required("Please select whether you offer training or"),
        type_of_setting: Yup.array().min(1, "Please select provider type of care setting(s).").required('Must at least select one type of setting.'), //.min(1, "at least 1")        
        zipcode: Yup.string().min(5, 'Zipcode must be 5 characters long!').required()
    })  

    console.log('settings form')   
    
    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid  stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user} />
                        </Grid.Column>                           
                            <Grid.Column width={12}>                        
                            <Message>
                                <Message.Header>Add provider information</Message.Header>
                                    <p>
                                        Fill out the form below to aid with the searching, sorting and matching caregivers suitable for you and to make it easier for caregiver to contact you.
                                    </p>
                            </Message>
                            <Segment> 
                            <Formik
                                initialValues = {{
                                    address: userData?.address ? userData?.address : "",
                                    alert_preferences: userData?.settings?.alert_preferences ? userData?.settings?.alert_preferences : [],
                                    city: userData?.city ? userData?.city : "",
                                    first: userData?.first ? userData?.first : "",
                                    last: userData?.last ? userData?.last: "",
                                    provider_email: userData?.settings?.hr_email ? userData?.settings?.hr_email : userData?.auth?.email ? userData?.auth?.email: "" ,
                                    name: userData?.name ? userData?.name
                                    : userData?.auth?.full_name ? userData?.auth?.full_name
                                    : userData?.auth?.name ? userData?.auth?.name
                                    : userData?.user_name ? userData?.user_name
                                    : "", 
                                    provider_tel: userData?.settings?.tel ? userData?.settings?.tel : userData?.settings?.cell ? userData?.settings?.cell : userData?.auth?.tel ? userData?.auth?.tel : userData?.auth?.cell ? userData?.auth?.cell:"",
                                    trainer: userData?.trainer ? userData?.trainer: "",
                                    type_of_setting: userData?.type_of_setting ? userData?.type_of_setting : [],
                                    zipcode: userData?.zipcode ? userData?.zipcode : ""
                                }}            
    
                                validationSchema = { userSettingsSchema }
                                enableReinitialize

                                onSubmit={ async (values, { setSubmitting }) => {

                                    setSubmitting(true)
                                    setLoading(true)                                   
                                                        
                                    //create user settings object 
                                    const settingsData = {
                                        userID: user.id,
                                        hash: user?.customData?.hash, 
                                        address: values.address,
                                        alert_preferences: values.alert_preferences,
                                        city: values.city,
                                        fname: values.first,
                                        lname: values.last,
                                        hr_email: values.provider_email,
                                        hr_tel: values.provider_tel,
                                        name: values.name,
                                        trainer: values.trainer,
                                        type_of_setting: values.type_of_setting,
                                        zipcode: values.zipcode
                                    }                                     
                            
                                    const { data, message } = await user.callFunction("web_update_provider_settings", settingsData)

                                    //mcdata to add add to Mailchimp audience via API
                                    const mcData = {
                                        settings_email: userData?.settings?.smsEmail ? userData?.settings?.smsEmail :  userData?.auth?.email,
                                        type: 'update settings',
                                        city: values.city,
                                        name: values.name,                                     
                                        state: 'WA',
                                        zipcode: values.zipcode  
                                    }   

                                    setSubmitting(false)
                                    setLoading(false)
                                    //need to refresh custom data
                                    await user?.refreshCustomData()
                                    //show alert about message
                                    alert(message)

                                    //if userData.complete is false, this is the initial settings submission
                                    if(!userData.complete){
                                        const tagManagerArgs = {
                                            dataLayer: {
                                                event: `provider_add_profile`,
                                                added: new Date(),
                                                userId: user?.id                     
                                            }           
                                        }
                                
                                        TagManager.dataLayer(tagManagerArgs)
                                    }

                                    //update user data
                                    setUserData(data)

                                    //if(userData.auth.mode === 'local-userpass'){
                                    await user.callFunction('mailchimp_subscribe_user', mcData)
                                    
                                    history.push('/provider/candidates/all')
                        
                                }}                        
                            > 
                            {
                                ({ errors, touched, values }) => (                                   
                                    <Form>  
                                        <label><b>First and last name of the hiring staff </b></label> 
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="text"
                                                name="first"
                                                label="First name"
                                                placeholder="First name" 
                                            />   
                                            <Input
                                                type="text"
                                                name="last"
                                                label="Last name"
                                                placeholder="Last name"                                                
                                            />                    
                                        </SemanticForm.Group>
                                        { touched.first && errors.first ? (<Message negative size='small'>{ errors.first }</Message>) : null }   
                                        { touched.last && errors.last ? (<Message negative size='small'>{ errors.last }</Message>) : null }   
                                    
                                        <label><b>Name of the health care provider/organization </b></label> 
                                    
                                        <Input
                                            type="text"
                                            name="name"
                                        // defaultValue={ providerSettings.provider_name} 
                                            placeholder="Enter the entire name of the organization"                                            
                                            required
                                        />
                                        { touched.name && errors.name ? (<Message negative size='small'>{ errors.name }</Message>) : null }      
                                        <label><b>What kind of care provider are you? [Can check more than one]:</b></label> 
                                        
                                        <SemanticForm.Group widths="equal">                                            
                                            
                                            <Checkbox
                                                value="Assisted Living/Nursing Home"
                                                name="alh/nh"
                                                label="Assisted Living/Nursing Home"
                                                onChange={() => {
                                                        if(values?.type_of_setting?.includes("Assisted Living/Nursing Home")){
                                                            const index = values?.type_of_setting?.indexOf("Assisted Living/Nursing Home");
                                                            if (index > -1) {
                                                                values?.type_of_setting?.splice(index, 1);
                                                            }
                                                        } else values?.type_of_setting?.push("Assisted Living/Nursing Home")
                                                    }                                                   
                                                }  
                                                checked={values?.type_of_setting?.includes("Assisted Living/Nursing Home")}    
                                            />

                                            <Checkbox
                                                value="Adult Family/Boarding Home"
                                                name="afh"
                                                label="Adult Family/Boarding Home" 
                                                onChange={() => {
                                                        if(values?.type_of_setting?.includes("Adult Family/Boarding Home")){
                                                            const index = values?.type_of_setting.indexOf("Adult Family/Boarding Home");
                                                            if (index > -1) {
                                                                values?.type_of_setting?.splice(index, 1);
                                                            }
                                                        } else values?.type_of_setting?.push("Adult Family/Boarding Home")
                                                    }                                                   
                                                }
                                                checked={values?.type_of_setting?.includes("Adult Family/Boarding Home")}           
                                            />

                                            <Checkbox
                                                value="Hospital/Clinic"
                                                name="hospital"
                                                label="Hospital/Clinic"                                             
                                                onChange={() => {
                                                        if(values?.type_of_setting?.includes("Hospital/Clinic")){
                                                            const index = values?.type_of_setting?.indexOf("Hospital/Clinic");
                                                            if (index > -1) {
                                                                values?.type_of_setting?.splice(index, 1);
                                                            }
                                                        } else values?.type_of_setting?.push("Hospital/Clinic")
                                                    }                                                   
                                                }
                                                checked={values?.type_of_setting?.includes("Hospital/Clinic")}                                                            
                                            />

                                            <Checkbox
                                                value="Home Care Agency"
                                                name="hca"
                                                label="Home Care Agency"                                             
                                                onChange={() => {
                                                        if(values?.type_of_setting?.includes("Home Care Agency")){
                                                            const index = values?.type_of_setting?.indexOf("Home Care Agency");
                                                            if (index > -1) {
                                                                values?.type_of_setting?.splice(index, 1);
                                                            }
                                                        } else values?.type_of_setting?.push("Home Care Agency")
                                                    }                                                   
                                                }
                                                checked={values?.type_of_setting?.includes("Home Care Agency")}   
                                                            
                                            />
                                            <Checkbox
                                                value="Other"
                                                name="other"
                                                label="Other"                                             
                                                onChange={() => {
                                                        if(values?.type_of_setting?.includes("Other")){
                                                            const index = values?.type_of_setting?.indexOf("Other");
                                                            if (index > -1) {
                                                                values?.type_of_setting?.splice(index, 1);
                                                            }
                                                        } else values?.type_of_setting?.push("Other")
                                                    }                                                   
                                                }
                                                checked={values?.type_of_setting?.includes("Other")}                 
                                            />
                                        </SemanticForm.Group>   
                                        { touched.type_of_setting && errors.type_of_setting ? (<Message negative size='small'>{ errors.type_of_setting }</Message>) : null }   
                                        <label ><b>To find caregivers close to you, enter the following details:</b></label> <br/>                               
                                            
                                            <Input
                                                type="text"
                                                name="address"
                                                label="Street address"
                                                placeholder="Enter your street address"                                                                               
                                            />                                                              
                                        { touched.address && errors.address ? (<Message negative size='small'>{ errors.address }</Message>) : null }   
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="text"
                                                name="city"
                                                label="City"
                                                placeholder="Enter your city" 
                                            />   
                                            <Input
                                                type="text"
                                                name="zipcode"
                                                label="Zipcode"
                                                placeholder="Enter your zipcode"                                               
                                                minLength="5"                                               
                                            />                    
                                        </SemanticForm.Group> 
                                        { touched.city && errors.city ? (<Message negative size='small'>{ errors.city }</Message>) : null }   
                                        { touched.zipcode && errors.zipcode ? (<Message negative size='small'>{ errors.zipcode }</Message>) : null }   
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="tel"
                                                name="provider_tel"
                                                label="Provider's tel"                                                
                                                placeholder="Enter your telephone number" 
                                                minLength="10"
                                            />   
                                            <Input
                                                type="email"
                                                name="provider_email"
                                                label="Provider's email"                                                 
                                                placeholder="Enter your email address"
                                            />                    
                                        </SemanticForm.Group> 
                                        { touched.email && errors.email ? (<Message negative size='small'>{ errors.email }</Message>) : null }   
                                        { touched.tel && errors.tel ? (<Message negative size='small'>{ errors.tel }</Message>) : null } 
                                        <label><b>Do you offer in house training or sponsor training?</b></label><br/>
                                        <span>In house training helps with caregiver onboarding.</span>         
                                        <SemanticForm.Group inline widths="equal">                                            
                                            <Radio  
                                                label="YES"                     
                                                name="trainer"                            
                                                value="yes"                                        
                                                type='radio'                                   
                                            /> 
                                        
                                            <Radio
                                                label="NO"                
                                                name="trainer"
                                                value="no"                                                
                                                type='radio'
                                            />                                                           
                                        </SemanticForm.Group> 
                                        { touched.trainer && errors.trainer ? (<Message negative size='small'>{ errors.trainer }</Message>) : null }                                        

                                        <label><b>How do you want caregivers to contact you? [Must choose one]:</b></label> <br/>
                                        
                                        <SemanticForm.Group widths="equal">                                            
                                            <Checkbox                             
                                                name="phone_call"                                                
                                                label="Phone call"
                                                value="Phone call"
                                                onChange={() => {
                                                    
                                                        if(values?.alert_preferences?.includes("Phone call")){
                                                            const index = values?.alert_preferences?.indexOf("Phone call");
                                                            if (index > -1) {
                                                            
                                                                values?.alert_preferences?.splice(index, 1)
                                                            }
                                                        } else {
                                                            values?.alert_preferences?.push("Phone call")
                                                        
                                                        } 
                                                    }                                                   
                                                } 
                                                checked={values?.alert_preferences?.includes("Phone call")}                                                 
                                            />   
                                            <Checkbox                               
                                                name="sms"
                                                value="SMS/Text message"
                                                label="SMS/Text message"
                                                onChange={() => {
                                                        if(values?.alert_preferences?.includes("SMS/Text message")){
                                                            const index = values?.alert_preferences?.indexOf("SMS/Text message");
                                                            if (index > -1) {
                                                            
                                                                values?.alert_preferences?.splice(index, 1);
                                                            }
                                                        } else {
                                                        
                                                            values?.alert_preferences?.push("SMS/Text message")
                                                        }
                                                    }                                                   
                                                } 
                                            checked={values?.alert_preferences?.includes("SMS/Text message")}                                                 
                                            />     
                                            <Checkbox                                
                                                name="email"
                                                value="Email"
                                                label="Email"
                                                onChange={() => {
                                                        if(values?.alert_preferences?.includes("Email")){
                                                            const index = values?.alert_preferences?.indexOf("Email");
                                                            if (index > -1) {
                                                                values?.alert_preferences?.splice(index, 1);
                                                            
                                                            }
                                                        } else{
                                                            values?.alert_preferences?.push("Email")
                                                        
                                                        } 
                                                    }                                                   
                                                } 
                                                checked={values?.alert_preferences?.includes("Email")}  
                                            />     
                                                                                            
                                        </SemanticForm.Group>
                                        { touched.alert_preferences && errors.alert_preferences ? <Message negative>{errors.alert_preferences}</Message> : null }                                         
                                    
                                        <SubmitButton 
                                            fluid                                    
                                            id="provider_add_settings_info"                               
                                            loading={loading}
                                            primary
                                            type="submit"
                                        >
                                            Update settings
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                            </Grid.Column>                 
                     
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default UpdateSettingsForm
