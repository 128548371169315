import React from 'react'
import { Container, Grid, List } from 'semantic-ui-react'
import { Link } from "react-router-dom"
const Footer = () => {
    return (
      <Container textAlign='center'>
        <Grid divided='vertically'>
          <Grid.Row columns={2}>       
              
            <Grid.Column >
              <List divided relaxed>               
                <List.Item>
                  <List.Content>
                    <List.Header as='h4'>Our services</List.Header>
                    <List.Description><Link to='/subscribe'>Plans</Link></List.Description>
                    <List.Description><Link to='/recruit-caregivers'>Recruit caregivers</Link></List.Description>
                    <List.Description><Link to='/find-job'>Find a job</Link></List.Description>
                    <List.Description><Link to='/alerts'>SMS/Text and Phone call alerts</Link></List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>

            <Grid.Column >
              <List divided relaxed>               
                <List.Item>
                  <List.Content>
                    <List.Header as='h4'>About us</List.Header>
                    <List.Description>Our mission</List.Description>
                    <List.Description>About us</List.Description>
                    <List.Description>Visit our FB page</List.Description>
                    {/* <List.Description>Item A</List.Description> */}
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>          
        </Grid>
      </Container>      
    )
}

export default Footer
