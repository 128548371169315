import React from 'react'
import {
    Container, Grid, List, Message, Divider, Header, Placeholder, Segment
} from "semantic-ui-react"
import { Link } from "react-router-dom"
//import CaregiverPanel from '../../../components/CaregiverPanel'

const PublicPostingsUI = ({ openings, error, loading, search }) => {
    console.log('error ', error)
    return ( 
        <div>
             <style>
                {`#p-wrap {
                    white-space: pre-line;
                }`}
            </style> 
            <Container style={{ marginTop: 30, marginBottom: 75 }}>
                 
                 <Grid stackable columns={1}>
                     <Grid.Row>
                             
                         <Grid.Column>
                         { loading && (
                             <>
                                 {" "}
                                 <Placeholder>
                                     <Placeholder.Header >
                                         <Placeholder.Line />
                                         <Placeholder.Line />
                                     </Placeholder.Header>
                                     <Placeholder.Paragraph>
                                         <Placeholder.Line />
                                         <Placeholder.Line />
                                         <Placeholder.Line />
                                         <Placeholder.Line />
                                     </Placeholder.Paragraph>
                                 </Placeholder>
                             </>
                         )}
     
                         { search &&!loading && !error && openings.length === 0 && (
                             <Message content="No jobs meet your search criteria." />
                         )}
     
                         { !search &&!loading && !error && openings.length === 0 && (
                             <Message content="No current job opening." />
                         )}
     
                         {error && (
                             <Message content="Oops, something went amiss!" />
                         )}
     
                         { !loading && !error && openings.length > 0 && (
                            <>
                                 <Header as='h3'>Job openings</Header>
                                 <Segment>                    
                                     <List>                                                 
                                         {
                                             openings.length > 0 && openings.map((opening, index) => (
                                             
                                            <List.Item key={index}>
                                                <List.Content>
                                                    <Link to={`/job/view/${opening._id}`}><Header as="h4" color="blue"> {opening.title} </Header></Link>
                                                         
                                                        <Divider/>  
                                                </List.Content>
                                            </List.Item>
                                            ))
                                         }                            
                                     </List>
                                 </Segment>
                            </> 
                         )}
                         </Grid.Column>
                     </Grid.Row>
                 </Grid>            
            </Container>     
        </div>

               
    )
}

export default PublicPostingsUI
