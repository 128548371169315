import React from 'react'
import {
    Divider, Grid, List, Message, Header, Placeholder, Segment
} from "semantic-ui-react"
import { Link, useParams } from "react-router-dom"
 
const AllJobListings = ({ openings, error, loading }) => {
    //get type
    const { type } = useParams()

    console.log('there is an error ', error, 'openings', openings, 'loading ', loading)

    return (               
        <Grid stackable style={{ marginTop: 10, marginBottom: 75 }}>
            <Grid.Row>
                
                <Grid.Column width={16}>
                { loading && (
                    <>
                        {" "}
                        <Placeholder>
                            <Placeholder.Header >
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </>
                )}

                {!loading && !error && openings?.length === 0 && (
                    <Message content="No job openings currently listed." />
                )}

                { error && (
                    <Message content="Oops, something went amiss!" />
                )}

                <List>
                    { openings?.length > 0 && <Header as='h3'>Jobs postings...</Header> }
                    { openings?.length > 0 &&  type === 'all' && <Segment>
                        { openings?.map((opening, index) => (
                        <List.Item key={index}>
                            <List.Content>
                                <Link to={`/vitae/view/job/${opening?._id}`} ><Header as="h4" color="blue">{ opening.title }</Header></Link>

                                    <strong>License required: </strong>
                                    <List divided horizontal relaxed>
                                        { opening?.licenses?.map((license, index)=> (
                                            <List.Item key={index}>{license}</List.Item>
                                        ))}
                                    </List> <br/>
                                    <strong>SHIFTS:  </strong> 
                                    <List divided horizontal relaxed>
                                        { opening?.schedule?.map((availability, index)=> (
                                            <List.Item key={index}>{availability}</List.Item>
                                        ))}
                                    </List>  
                                    <p>Job is within {opening.distance} miles.</p>
                                 <Divider /> 
                            </List.Content>
                        </List.Item>
                        ))}
                    </Segment> }    

                    { openings.length > 0 && type === 'favorites' &&<Segment>
                        {openings.map((opening, index) => (
                        
                        <List.Item key={index}>
                            <List.Content>
                                <Link to={`/vitae/view/job/${opening?.jobId}`} ><Header as="h4" color="blue">{ opening.title }</Header></Link>
                                     <List divided horizontal relaxed>
                                        { opening?.licenses?.map((license, index) => (
                                            <List.Item key={index}>{license}</List.Item>
                                        ))}
                                    </List> <br/>
                                    <List divided horizontal relaxed>
                                        { opening?.schedule?.map((availability, index)=> (
                                            <List.Item key={index}>{availability}</List.Item>
                                        ))}
                                    </List>  
                                <Divider /> 
                            </List.Content>
                        </List.Item>
                        ))}
                    </Segment> }                     
                </List>
                </Grid.Column>
            </Grid.Row>
        </Grid>            
    )
}

export default AllJobListings
