
import { useHistory } from 'react-router-dom';
import React from 'react';
import {
    Form,
    SubmitButton,  
    Radio, 
    Checkbox,
    Input,
    TextArea 
} from 'formik-semantic-ui-react'
import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm,
    Header as SemanticHeader,
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import EmployerPanel from '../../../components/EmployerPanel';
import TagManager from 'react-gtm-module';

const RepostJobForm = ({ job, user}) => {      
    //create loading state
    const [ loading, setLoading ] = React.useState(false)
    //get history for routing   
    const history = useHistory()

    const jobPostSchema = Yup.object().shape({
        title: Yup.string().required(),//.max(2, 'Full name can only be 6 characters long.'),
        city: Yup.string().required(),
        zipcode: Yup.string().min(5, 'Zipcode must be 5 characters long!').required('required'),
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'.").required('required'), //.min(1, "at least 1")
        schedule: Yup.array().min(1, "Select at least 1 schedule you are available to work.").required('required'),
        mobility: Yup.string().required(),
        compensation: Yup.string().required(),
        certifications: Yup.string().required(),
        description: Yup.string().required()
    })  
  
    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid  stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user} />
                        </Grid.Column>
                        <Grid.Column width={12}>
                        <SemanticHeader as='h3'>Update job opening</SemanticHeader>
                            <Segment> 
                            <Formik
                                //initialValues = {job}
                                initialValues={{
                                    address: job?.contacts?.address,
                                    alert_preferences: job?.alert_preferences ? job?.alert_preferences : [],
                                    certifications: job?.certifications ? job?.certifications : "",                                    
                                    city: job?.contacts?.city ? job?.contacts?.city: "",
                                    compensation: job?.compensation ? job?.compensation : "",
                                    description: job?.description ? job?.description: "",
                                    email: job?.contacts?.email,
                                    licenses: job?.licenses ,
                                    mobility: job?.mobility ? job.mobility: "",
                                    schedule: job?.schedule,
                                    tel: job?.contacts?.tel,
                                    title: job.title ? job?.title: "",                                    
                                    zipcode: job?.contacts?.zipcode ? job?.contacts?.zipcode: "" 
                                }}

                                enableReinitialize

                               validationSchema = { jobPostSchema }

                                onSubmit={ async (values, { setSubmitting }) => {                                   
                                    
                                    //set loading to true
                                    setLoading(true)
                                    //create user settings object 
                                    const job_post = {
                                        userID: user?.id,       
                                        hash: user?.customData?.hash,                                
                                        alert_preferences: job.alert_preferences,                                                                            
                                        certifications: values.certifications,
                                        contacts: {
                                            address: values.address.trim(), 
                                            city: values.city.trim(),
                                            email: values.email.trim(),
                                            tel: values.tel.trim(),                                           
                                            zipcode: values.zipcode.trim()
                                        },
                                        compensation: values.compensation.trim(),  
                                        description: values.description.trim(),
                                        draft: user?.customData?.trial ? false : true,
                                        licenses: values.licenses,
                                        mobility: values.mobility,
                                        schedule: values.schedule,
                                        title: values.title.trim(),
                                        created: new Date()
                                    }                                  
                                    
                                    console.log('job post ', job_post)
                                    setSubmitting(true)

                                    //tag manager for selecting plan
                                    const tagManagerArgs = {
                                        dataLayer: {
                                            event: `provider_repost_job`,                                              
                                            added: new Date(),   
                                            userId: user?.id,                                       
                                        }           
                                    }

                                    TagManager.dataLayer(tagManagerArgs)
                                    
                                    const results = await user.callFunction("web_add_provider_job_post", job_post)
                                    setSubmitting(false)
                                    setLoading(false)

                                    //alert(`One more step`)
                                    //set the application in the mongo context
                             
                                    history.push(results.url)
                                    
                                }}                        
                            > 
                            {
                                ({ errors, touched, values, handleBlur }) => (                                   
                                    <Form >   
                                        <Input
                                            type="text"
                                            name="title"
                                            label="Job title"
                                            //placeholder="Enter title of job posting" 
                                            size="large"   
                                            defaultValue={job?.title}                                        
                                            required
                                        />   
                                     { touched.title && errors.title ? (<Message>Please enter your job title</Message>) : null }       
                                        <label><b>What licenses are required?</b></label><br/>
                                        <SemanticForm.Group widths="equal" inline required>                                            
                                            <Checkbox                                               
                                                value="CNA"
                                                name="cna"
                                                label="CNA or NAC" 
                                                onChange={() => {
                                                        if(values?.licenses?.includes("CNA")){
                                                            const index = values?.licenses?.indexOf("CNA");
                                                            console.log('index ', index)
                                                            console.log('licenses ', values?.licenses )
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("CNA")
                                                    }                                                   
                                                }          
                                                checked={ job?.licenses?.includes("CNA")}                                     
                                                required                     
                                            />
                                            <Checkbox                                               
                                                value="HCA"
                                                name="hca"
                                                label="HCA"        
                                                onChange={() => {
                                                        if(values?.licenses?.includes("HCA")){
                                                            const index = values?.licenses?.indexOf("HCA");
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("HCA")
                                                    }                                                   
                                                }     
                                                checked={ job?.licenses?.includes("HCA")}
                                                //required                         
                                            />
                                            <Checkbox                                                
                                                value="NAR"
                                                name="nar"
                                                label="NAR"
                                                onChange={() => {
                                                        if(values?.licenses?.includes("NAR")){
                                                            const index = values?.licenses?.indexOf("NAR");
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("NAR")
                                                    }                                                   
                                                }
                                                checked={ job?.licenses?.includes("NAR")}           
                                            />
                                            <Checkbox                                               
                                                value="None"
                                                name="none"
                                                label="None"
                                                onChange={() => {
                                                        if(values?.licenses?.includes("None")){
                                                            const index = values?.licenses?.indexOf("None");
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("None")
                                                    }                                                   
                                                }
                                                checked={ job?.licenses?.includes("None")}                  
                                            />
                                        </SemanticForm.Group>  
                                        
                                        {/* { touched.licenses && errors.licenses ? (<Message>{ errors.licenses }</Message>) : null }                                           */}
                                        <label><b>Please enter required certifications such as CPR/First Aid, Food Handler's etc.</b> </label>
                                        <TextArea                                              
                                            rows={3}                      
                                            name="certifications"
                                            size="large"
                                            placeholder="Enter other certifications, e.g., CPR/First Aid, Food Handler's etc."   
                                            // defaultValue={ job?.certifications }                                                                                                               
                                        /> 
                                        {/* { touched.certifications &&  errors.certifications && <Message>{errors.certifications}</Message>}    */}

                                        <label><b>Please enter job description.</b> </label>
                                        <TextArea                                              
                                            rows={3}                      
                                            name="description"
                                            size="large"
                                            required
                                            placeholder="Add job description i.e., tasks, a more specific schedule, required experience etc."    
                                            // defaultValue={ job?.description }                                                                                                                   
                                        /> 
                                        {/* { touched.description &&  errors.description && <Message>Please enter job description</Message>}    */}
                                        <label><b>Select job schedule:</b></label><br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Checkbox
                                                value="Full time"
                                                name="full_time"
                                                label="Full time"
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Full time")){
                                                            const index = values?.schedule?.indexOf("Full time");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Full time")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Full time")}                   
                                            />
                                            <Checkbox
                                                value="Part time"
                                                name="part_time"
                                                label="Part time"
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Part time")){
                                                            const index = values?.schedule?.indexOf("Part time");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Part time")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Part time")}
                                                required                         
                                            />
                                            <Checkbox
                                                value="Weekends"
                                                name="weekends"
                                                label="Weekends" 
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Weekends")){
                                                            const index = values?.schedule?.indexOf("Weekends");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Weekends")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Weekends")}
                                                required           
                                            />
                                            <Checkbox
                                                value="On Call"
                                                name="on_call"
                                                label="On Call"                                             
                                                onChange={() => {
                                                        if(values?.schedule?.includes("On Call")){
                                                            const index = values?.schedule?.indexOf("On Call");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("On Call")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("On Call")}
                                                required                
                                            />
                                            <Checkbox
                                                value="Live In"
                                                name="live_in"
                                                label="Live In"                                             
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Live In")){
                                                            const index = values?.schedule?.indexOf("Live In");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Live In")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Live In")}
                                                required                
                                            />
                                        </SemanticForm.Group>  
                                        <label ><b>Enter your contact details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Email of the hiring contact."                                    
                                                required
                                            />   
                                            <Input
                                                type="tel"
                                                name="tel"
                                                label="Phone number"
                                                placeholder="Phone # of the hiring contact"                                               
                                                minLength="5"
                                                required
                                            />                    
                                        </SemanticForm.Group>

                                        <label ><b>Enter address of location:</b></label> <br/>                                         
                                        <Input
                                            type="text"
                                            name="address"
                                            label="Street address"
                                            placeholder="Enter your street address"                                    
                                        /> 

                                        {/* { touched.schedule  &&  errors.schedule && <Message>Must select at least one type of schedule</Message>  } */}
                                        <label ><b>To match your opening with caregivers close to you, enter the following details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="text"
                                                name="city"
                                                label="City"
                                                placeholder="City where job is located"       
                                                defaultValue={ job?.city }                             
                                                required
                                            />   
                                            <Input
                                                type="text"
                                                name="zipcode"
                                                label="Zipcode"
                                                placeholder="Zipcode of where job is located"                                               
                                                minLength="5"
                                                defaultValue={ job?.zipcode } 
                                                required
                                            />                    
                                        </SemanticForm.Group>
                                        {/* { touched.zipcode && errors.zipcode ? <Message negative>Please enter location [zipcode].</Message> : null } 
                                        { touched.city && errors.city ? <Message negative>Please enter location [city].</Message> : null }  */}
                                        <label><b>Does the job require caregiver to drive?</b></label>         
                                        <SemanticForm.Group inline widths="equal">                                            
                                            <Radio  
                                                label="YES"                     
                                                name="mobility"                            
                                                value="car_needed"                                        
                                                type='radio'    
                                                //checked={ job?.mobility === 'car_needed' }                                    
                                                required                                    
                                            /> 
                                        
                                            <Radio                
                                                name="mobility"
                                                value="no_car_needed"
                                                label="NO"
                                                type='radio'  
                                                //checked={ job?.mobility === 'no_car_needed'}                                                                         
                                                required
                                            />                                                           
                                        </SemanticForm.Group> 

                                        <label><b>Compensation</b></label>
                                        <Input
                                            type="text"
                                            name="compensation"
                                            //label="Compensation"
                                            // defaultValue={ job?.compensation } 
                                            placeholder="Enter $ amount compensation, DoE, Negotiable etc"                                           
                                            required
                                        />
                                        {/* { touched.mobility && errors.mobility ? <Message negative>Must select mobility requirement.</Message> : null }         */}
                                        <SubmitButton 
                                            fluid                        
                                            id="provider_resubmit_job"            
                                            loading={loading}
                                            primary
                                            type="submit"
                                        >
                                            Repost Job Opening
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default RepostJobForm
