import React from 'react'
import * as Realm from 'realm-web'
import {
    Container,
    Divider,
    Grid,
    Message
} from "semantic-ui-react";

const OathRedirect = ({ mongoContext: { user }}) => {

    React.useEffect(() => {      
        Realm.handleAuthRedirect()       
    }, [user])    
    return (
        <Container>
            <Grid>
            <Divider hidden section />           
                <Grid.Row>
                <Container textAlign="center">
                <Grid.Column style={{ maxWidth: 800, marginTop: 20, marginBottom: 20, marginLeft: 150 }}> 
                    <Message success size='massive'>Redirecting you back to Kinshealth.</Message>
                </Grid.Column>                                          
                </Container>           
                </Grid.Row>       
            </Grid>
        </Container>
    )
}

export default OathRedirect
