import {
    Form,
    SubmitButton,  
    Radio, 
    Checkbox,
    Input,
    TextArea 
} from 'formik-semantic-ui-react'
import { useHistory } from 'react-router-dom'
import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm,
    Header as SemanticHeader,
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import EmployerPanel from '../../../components/EmployerPanel'
import TagManager from 'react-gtm-module';

const JobPostingForm = ({ userData, user }) => {      
    console.log('user data ', userData)
    //get history for routing   
    const history = useHistory()   

    const jobSchema = Yup.object().shape({
        title: Yup.string().required("Please enter title of your job"),    
        address: Yup.string().required("Please enter the address of where your job is located."),       
        email: Yup.string().email().required("Please enter the email address caregivers will use to contact you."),
        tel: Yup.string().required("Please enter the telephone number caregivers will use to contact you."),
        zipcode: Yup.string().required("Please enter the zipcode of your job is located."),   
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'.").required(), //.min(1, "at least 1")
        schedule: Yup.array().min(1, "Select at least 1 schedule you are available to work.").required(),
        mobility: Yup.string().required("Please select if yuour require caregiver to drive"),        
        certifications: Yup.string(),//.required("Enter required "),
        city: Yup.string().required("Please enter the city your job is located."),
        compensation: Yup.string().required("Enter compensation per day/hour or 'DoE' or 'Negotiable'"),
        description: Yup.string().required("Please enter job description")     
    }) 
    // console.log('client in the application form ', client, 'user ', user)
    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid  stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                        <SemanticHeader as='h3'>Post your job opening</SemanticHeader>
                            <Segment> 
                            <Formik
                                initialValues ={{                                    
                                    certifications: "",                                                              
                                    compensation: "",
                                    address: userData?.address,
                                    city: userData?.city,       
                                    email: userData?.settings?.hr_email ? userData?.settings?.hr_email : userData?.auth?.email,                
                                    tel: userData?.settings?.cell ? userData?.settings?.cell : userData?.auth?.tel ? userData?.auth?.tel : "",                             
                                    zipcode: userData?.zipcode,
                                    description: "",
                                    licenses: [],
                                    mobility: "",
                                    schedule: [],                            
                                    title: ""                                                                                                        
                                }}

                                enableReinitialize

                                validationSchema = { jobSchema }                               

                                onSubmit={ async (values, { setSubmitting }) => {                                        
                                   
                                    //create user settings object 
                                    const job_post = {
                                        userID: user?.id,     
                                        hash: user?.customData?.hash,     
                                        contacts: {
                                            address: values.address.trim(), 
                                            city: values.city.trim(),
                                            email: values.email.trim(),
                                            tel: values.tel.trim(),                                           
                                            zipcode: values.zipcode.trim()
                                        },                             
                                        certifications: values.certifications.trim(), 
                                                                             
                                        compensation: values.compensation.trim(),
                                        description: values.description.trim(),
                                        licenses: values.licenses,
                                        mobility: values.mobility,
                                        schedule: values.schedule,
                                        title: values.title.trim(),                                       
                                        created: new Date(),
                                        draft: user?.customData?.trial ? false : true
                                    }                                  
                                    
                                    setSubmitting(true)
                                    
                                    const results = await user.callFunction("web_add_provider_job_post", job_post)

                                    alert(`Your job post has been created.`)
                                    //set the application in the mongo context

                                     //tag manager for selecting plan
                                    const tagManagerArgs = {
                                        dataLayer: {
                                            event: `provider_start_posting_job`,                                              
                                            added: new Date(),   
                                            userId: user?.id,                                       
                                        }           
                                    }

                                    TagManager.dataLayer(tagManagerArgs)
                             
                                    history.push(results.url)
                                    setSubmitting(false)
                                }}                        
                            > 
                            {
                                ({ errors, touched, values }) => (                                   
                                    <Form>                                        
                                        <label><b>Job title</b></label>   
                                        <Input
                                            type="text"
                                            name="title"
                                            //label="Job title"
                                            placeholder="Enter title of job posting" 
                                            size="large"                                           
                                            //required
                                        />   
                                        { touched.title && errors.title ? (<Message negative size='small'>{ errors.title }</Message>) : null }      
                                        <label><b>What licenses are required?</b></label><br/>
                                        <SemanticForm.Group widths="equal" inline required>                                            
                                            <Checkbox                                               
                                                value="CNA"
                                                name="CNA"
                                                label="CNA or NAC" 
                                                onChange={() => {
                                                        if(values.licenses.includes("CNA")){
                                                            const index = values.licenses.indexOf("CNA")
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("CNA")
                                                    }                                                   
                                                }                                                                
                                            />
                                            <Checkbox                                               
                                                value="HCA"
                                                name="HCA"
                                                label="HCA"        
                                                onChange={() => {
                                                        if(values.licenses.includes("HCA")){
                                                            const index = values.licenses.indexOf("HCA");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("HCA")
                                                    }                                                   
                                                }                                                    
                                            />
                                            <Checkbox                                                
                                                value="NAR"
                                                name="NAR"
                                                label="NAR"                                             
                                                required    
                                                onChange={() => {
                                                        if(values.licenses.includes("NAR")){
                                                            const index = values.licenses.indexOf("NAR");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("NAR")
                                                    }                                                   
                                                }           
                                            />
                                            <Checkbox                                               
                                                value="None"
                                                name="None"
                                                label="None"                                             
                                                required
                                                onChange={() => {
                                                        if(values.licenses.includes("None")){
                                                            const index = values.licenses.indexOf("None");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("None")
                                                    }                                                   
                                                }                  
                                            />

                                        </SemanticForm.Group>  
                                        { touched.licenses && errors.licenses ? (<Message negative size='small'>{ errors.licenses }</Message>) : null }                         
                                        <label><b>Please enter required certifications such as CPR/First Aid, Food Handler's etc.</b> </label>
                                        <TextArea                                              
                                            rows={3}                      
                                            name="certifications"
                                            size="large"
                                            placeholder="Enter other certifications, e.g., CPR/First Aid, Food Handler's etc."                                                                                                                  
                                        /> 

                                        <label><b>Please enter job description.</b> </label>
                                        <TextArea                                              
                                            rows={5}                      
                                            name="description"
                                            size="large"
                                            //required
                                            placeholder="Add job description i.e., tasks, a more specific schedule, required experience etc."                                                                                                                  
                                        /> 

                                        {touched.description && errors.description ? <Message negative size='small'>{errors.description }</Message>: null }   
                                        <label><b>Select job schedule:</b></label><br/> 
                                        <SemanticForm.Group widths="equal">                                            
                                            <Checkbox
                                                value="Full time"
                                                name="Full time"
                                                label="Full time"
                                                onChange={() => {
                                                        if(values.schedule.includes("Full time")){
                                                            const index = values.schedule.indexOf("Full time");
                                                            if (index > -1) {
                                                                values.schedule.splice(index, 1);
                                                            }
                                                        } else values.schedule.push("Full time")
                                                    }                                                   
                                                }                    
                                            />
                                            <Checkbox
                                                value="Part time"
                                                name="Part time"
                                                label="Part time"
                                                onChange={() => {
                                                        if(values.schedule.includes("Part time")){
                                                            const index = values.schedule.indexOf("Part time");
                                                            if (index > -1) {
                                                                values.schedule.splice(index, 1);
                                                            }
                                                        } else values.schedule.push("Part time")
                                                    }                                                   
                                                }                    
                                            />
                                            <Checkbox
                                                value="Weekends"
                                                name="Weekends"
                                                label="Weekends" 
                                                onChange={() => {
                                                        if(values.schedule.includes("Weekends")){
                                                            const index = values.schedule.indexOf("Weekends");
                                                            if (index > -1) {
                                                                values.schedule.splice(index, 1);
                                                            }
                                                        } else values.schedule.push("Weekends")
                                                    }                                                   
                                                }        
                                            />
                                            <Checkbox
                                                value="On Call"
                                                name="On Call"
                                                label="On Call"                                             
                                                onChange={() => {
                                                        if(values.schedule.includes("On Call")){
                                                            const index = values.schedule.indexOf("On Call");
                                                            if (index > -1) {
                                                                values.schedule.splice(index, 1);
                                                            }
                                                        } else values.schedule.push("On Call")
                                                    }                                                   
                                                }             
                                            />
                                            <Checkbox
                                                value="Live In"
                                                name="Live In"
                                                label="Live In"                                             
                                                onChange={() => {
                                                        if(values.schedule.includes("Live In")){
                                                            const index = values.schedule.indexOf("Live In");
                                                            if (index > -1) {
                                                                values.schedule.splice(index, 1);
                                                            }
                                                        } else values.schedule.push("Live In")
                                                    }                                                   
                                                }            
                                            />
                                        </SemanticForm.Group> 

                                        { touched.schedule && errors.schedule ? (<Message negative size='small'>{ errors.schedule }</Message>) : null }   
                                        <label ><b>Enter your contact details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="email"
                                                name= "email"
                                                label="Email"
                                                placeholder="Email of the hiring contact."                                    
                                                //required
                                            />   
                                            <Input
                                                type="tel"
                                                name="tel"
                                                label="Phone number"
                                                placeholder="Phone # of the hiring contact"                                               
                                                minLength="5"
                                                //required
                                            />                    
                                        </SemanticForm.Group>
                                        { touched.email && errors.email ? (<Message negative size='small'>{ errors.email }</Message>) : null } 
                                        { touched.tel && errors.tel ? (<Message negative size='small'>{ errors.tel }</Message>) : null } 
                                        <label ><b>Enter address of location:</b></label> <br/>                                         
                                        <Input
                                            type="text"
                                            name="address"
                                            label="Street address"
                                            placeholder="Enter your street address"                                    
                                        />    
                                         { touched.address && errors.address ? (<Message negative size='small'>{ errors.address }</Message>) : null }
                                        <label ><b>To match your opening with caregivers close to you, enter the following details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="text"
                                                name="city"
                                                label="City"
                                                placeholder="City where job is located"                                    
                                               // required
                                            />   
                                            <Input
                                                type="text"
                                                name="zipcode"
                                                label="Zipcode"
                                                placeholder="Zipcode of where job is located"                                               
                                                minLength="5"
                                                //required
                                            />                    
                                        </SemanticForm.Group>
                                        { touched.zipcode && errors.zipcode ? (<Message>{ errors.zipcode }</Message>) : null }
                                        { touched.city && errors.city ? (<Message>{ errors.city }</Message>) : null }
                                        {/* { touched && errors.alert_preferences ? <Message negative>Must select at least one alert preference.</Message> : null }     */}
                                      
                                        <label><b>Does the job require caregiver to drive?</b></label>         
                                        <SemanticForm.Group inline widths="equal">                                            
                                            <Radio  
                                                label="YES"                     
                                                name="mobility"                            
                                                value="car_needed"                                        
                                                type='radio'                                                                         
                                            /> 
                                        
                                            <Radio                
                                                name="mobility"
                                                value="no_car_needed"
                                                label="NO"
                                                type='radio'                                                                           
                                            />                                                           
                                        </SemanticForm.Group> 
                                        { touched.mobility && errors.mobility ? (<Message negative size='small'>{ errors.mobility }</Message>) : null }

                                        <label><b>Compensation - can be a range e.g., $ 18 - $24 per hr, $ 190 per day </b></label>
                                        <Input
                                            type="text"
                                            name="compensation"
                                            placeholder="Enter $ compensation amount or DoE, Negotiable etc"                                          
                                        />
                                        { touched.compensation && errors.compensation ? (<Message negative size='small'>{ errors.compensation }</Message>) : null }
                                        <SubmitButton 
                                            fluid                                    
                                            primary
                                            type="submit"
                                            id="provider_create_job_post"
                                        >
                                            Post job
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default JobPostingForm
