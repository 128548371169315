import {
    Form,
    SubmitButton,  
    Radio, 
    Checkbox,
    Input,
    TextArea 
} from 'formik-semantic-ui-react'
import { useHistory } from 'react-router-dom'
import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm,
    Header as SemanticHeader,
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react';
import CaregiverPanel from '../../../components/CaregiverPanel'
import TagManager from 'react-gtm-module';

const Application = ({  mongoContext : { userData, setUserData, user }}) => {   
    //add loading state   
    const [ loading, setLoading ] = React.useState(false)
    //get history for routing   
    const history = useHistory()
    const applicationSchema = Yup.object().shape({
        alert_preferences: Yup.array().min(1, "Select at least one way to be contacted.").required('required'),
        availability:  Yup.array().min(1, "Select at least 1 schedule you are available to work.").required('required'),
        fname: Yup.string().required('Please enter your first name.'),//.max(2, 'Full name can only be 6 characters long.'),
        lname: Yup.string().required('Please enter your last name.'),
        certifications: Yup.string(),
        city: Yup.string().required("Please enter your city"),
        contact_email:   Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        contact_phone: Yup.string().required("Please enter your phone number"),        
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'.").required(),         
        mobility: Yup.string().required("Select whether you have and drive a car"),
        zipcode: Yup.string().min(5, 'Zipcode must be 5 characters long!').required(),
        //.required()
    })  
    // console.log('client in the application form ', client, 'user ', user)
    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid  stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <CaregiverPanel user={user} />
                        </Grid.Column>
                        <Grid.Column width={12}>
                       
                        <SemanticHeader as='h3'>Fill out application x</SemanticHeader>
                            <Segment> 
                            <Formik
                                initialValues ={{            
                                    
                                    alert_preferences: userData?.settings?.alert_preferences ? userData?.settings?.alert_preferences : [],
                                    availability: userData?.availability ? userData?.availability : [],
                                    // fname: userData?.auth?.name ? userData?.auth?.name
                                    // : userData?.user_name ? userData?.user_name
                                    // : "",  
                                    fname: userData.auth.mode === "local-userpass" ? "" : userData.fname,
                                    lname: userData.auth.mode === "local-userpass" ? "" : userData.lname,
                                    certifications: "",
                                    city: userData?.city ? userData?.city : "",
                                    contact_email: userData?.auth?.email ? userData?.auth?.email  : "",
                                    contact_phone: userData?.settings?.cell ? userData?.settings?.cell  : userData?.auth?.tel ? userData?.auth?.tel : "",
                                    licenses: userData?.licenses ? userData?.licenses : [],
                                    mobility: "",
                                    zipcode: userData?.zipcode ? userData?.zipcode : "",                     
                                   
                                }}

                                validationSchema = {applicationSchema}

                                enableReinitialize

                                onSubmit={ async (values, { setSubmitting }) => {

                                    setSubmitting(true)
                                    setLoading(true)
                                                                      
                                    //create user settings object 
                                    const user_cv = {
                                        userID: user?.id,    
                                        hash: user?.customData?.hash,                                    
                                        availability: values.availability,                                        
                                        fname: values.fname,
                                        lname: values.lname,
                                        certifications: values.certifications,
                                        city: values.city,
                                        settings: {
                                            alert_preferences: values.alert_preferences,
                                            email: values.contact_email,
                                            role: 'caregiver',
                                            tel: values.contact_phone                                            
                                        },                                       
                                        created: new Date(),
                                        licenses: values.licenses,
                                        mobility: values.mobility,
                                        zipcode: values.zipcode                                                                                
                                    }            

                                    //mcdata to add add to Mailchimp audience via API
                                    const mcData = {
                                        settings_email: userData?.settings?.smsEmail ? userData?.settings?.smsEmail :  userData?.auth?.email,
                                        type: 'update settings',
                                        city: values.city,
                                        fname: values.fname,
                                        lname: values.lname,
                                        state: 'WA',
                                        zipcode: values.zipcode  
                                    }                      
                                    
                                    
                                    
                                    const results = await user.callFunction("web_add_caregiver_application", user_cv)

                                    //set the application in the mongo context
                                    setUserData(results.data)
                                    setLoading(false)
                                    //refresh the user custom data
                                    await user.refreshCustomData()

                                    alert(results.message)                                   

                                    await user.callFunction('mailchimp_subscribe_user', mcData)

                                    const tagManagerArgs = {
                                        dataLayer: {
                                            event: `caregiver_add_resume`,
                                            added: new Date(),
                                            userId: user?.id 
                                        }           
                                    }
                            
                                    TagManager.dataLayer(tagManagerArgs)
                                    
                                    history.push(results.url)

                                    setSubmitting(false)
                                }}                        
                            > 
                            {
                                ({ errors, touched, values }) => (
                                   
                                    <Form  >  
                                        <label><b>Enter your first and last name</b></label> 
                                        <SemanticForm.Group widths="equal">
                                        <Input
                                            type="text"
                                            name="fname"
                                            label="First name"
                                            placeholder="Enter your first name"                       
                                        />
                                        <Input
                                            type="text"
                                            name="lname"
                                            label="Last name"
                                            placeholder="Enter your last name"                             
                                        />
                                        </SemanticForm.Group>
                                        { touched.fname && errors.fname ? (<Message negative>{ errors.fname}</Message>) : null }   
                                        { touched.lname && errors.lname ? (<Message negative>{ errors.lname}</Message>) : null }      
                                        <label><b>What license do you have?</b></label><br/>
                                        <SemanticForm.Group widths="equal" inline required>                                            
                                            <Checkbox                                        
                                                value="CNA"
                                                name="CNA"
                                                label="CNA or NAC" 
                                                onChange={() => {
                                                        if(values.licenses.includes("CNA")){
                                                            const index = values.licenses.indexOf("CNA");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("CNA")
                                                    }                                                   
                                                }  
                                                checked={values?.licenses.includes("CNA")}                                                       
                                            />
                                            <Checkbox                                             
                                                value="HCA"
                                                name="HCA"
                                                label="HCA"        
                                                onChange={() => {
                                                        if(values.licenses.includes("HCA")){
                                                            const index = values.licenses.indexOf("HCA");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("HCA")
                                                    }                                                   
                                                }    
                                                checked={values?.licenses.includes("HCA")}                                                          
                                            />
                                            <Checkbox                                            
                                                value="NAR"
                                                name="NAR"
                                                label="NAR"                               
                                                onChange={() => {
                                                        if(values.licenses.includes("NAR")){
                                                            const index = values.licenses.indexOf("NAR");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("NAR")
                                                    }                                                   
                                                }
                                                checked={values?.licenses.includes("NAR")}         
                                            />
                                            <Checkbox
                                                value="None"
                                                name="None"
                                                label="None"                                             
                                                onChange={() => {
                                                        if(values.licenses.includes("None")){
                                                            const index = values.licenses.indexOf("None");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("None")
                                                    }                                                   
                                                } 
                                                checked={values?.licenses.includes("None")}                  
                                            />
                                        </SemanticForm.Group>  
                                        { touched.licenses && errors.licenses ? (<Message>{ errors.licenses}</Message>) : null }                                             
                                        <label><b>Please enter your certifications such as CPR/First Aid, Food Handler's etc.</b> </label>
                                        <TextArea                                              
                                            rows={3}                      
                                            name="certifications"
                                            size="large"
                                            placeholder="Enter other certifications, e.g., CPR/First Aid, Food Handler's etc."                                                                                                                  
                                        /> 

                                        <label><b>Select your availability:</b></label><br/>
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Checkbox
                                                value="Full time"
                                                name="full_time"
                                                label="Full time"
                                                onChange={() => {
                                                        if(values.availability.includes("Full time")){
                                                            const index = values.availability.indexOf("Full time");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Full time")
                                                    }                                                   
                                                }
                                                checked={values?.availability.includes("Full time")} 
                                                                
                                            />
                                            <Checkbox
                                                value="Part time"
                                                name="part_time"
                                                label="Part time"
                                                onChange={() => {
                                                        if(values.availability.includes("Part time")){
                                                            const index = values.availability.indexOf("Part time");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Part time")
                                                    }                                                   
                                                }
                                                checked={values?.availability.includes("Part time")} 
                                                                      
                                            />
                                            <Checkbox
                                                value="Weekends"
                                                name="weekends"
                                                label="Weekends" 
                                                onChange={() => {
                                                        if(values.availability.includes("Weekends")){
                                                            const index = values.availability.indexOf("Weekends");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Weekends")
                                                    }                                                   
                                                }
                                                checked={values?.availability.includes("Weekends")} 
                                                           
                                            />
                                            <Checkbox
                                                value="On Call"
                                                name="on_call"
                                                label="On Call"                                             
                                                onChange={() => {
                                                        if(values.availability.includes("On Call")){
                                                            const index = values.availability.indexOf("On Call");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("On Call")
                                                    }                                                   
                                                }
                                                checked={values?.availability.includes("On Call")} 
                                                             
                                            />
                                            <Checkbox
                                                value="Live In"
                                                name="Live In"
                                                label="Live In"                                             
                                                onChange={() => {
                                                        if(values.availability.includes("Live In")){
                                                            const index = values.availability.indexOf("Live In");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Live In")
                                                    }                                                   
                                                }
                                                checked={values?.availability.includes("Live In")} 
                                                           
                                            />
                                        </SemanticForm.Group>
                                        { touched.availability && errors.availability ? (<Message negative>{ errors.availability}</Message>) : null }      
                                        <label ><b>Please enter your contact details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Input
                                                type="email"
                                                name="contact_email"
                                                label="Enter your contact email"
                                                placeholder="Enter your city"                                    
                                                required
                                            />   
                                            <Input
                                                type="tel"
                                                name="contact_phone"
                                                label="Enter your contact phone"
                                                placeholder="Enter your contact phone"                                               
                                                minLength="10"
                                                required
                                            />                    
                                        </SemanticForm.Group>             
                                        { touched.contact_email && errors.contact_email ? (<Message negative>{ errors.contact_email}</Message>) : null }    
                                        { touched.contact_phone && errors.contact_phone ? (<Message negative>{ errors.contact_phone}</Message>) : null }    
                                        <label ><b>To match you with jobs close to you, enter the following details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Input
                                                type="text"
                                                name="city"
                                                label="Enter your city"
                                                placeholder="Enter your city"                                    
                                     
                                            />   
                                             
                                            <Input
                                                type="text"
                                                name="zipcode"
                                                label="Enter your zipcode"
                                                placeholder="Enter your zipcode"                                               
                                               
                                            />       
                                                         
                                        </SemanticForm.Group>
                                        { touched.zipcode && errors.zipcode ? (<Message negative>{ errors.zipcode}</Message>) : null } 
                                        { touched.city && errors.city ? (<Message negative>{ errors.city}</Message>) : null }  
                                        <label><b>How do you want employers to contact you about their job openings [Must choose one]:</b></label> <br/>
                                        
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Checkbox                             
                                                name="Phone call"                                                
                                                label="Phone call"
                                                value="phone_call"
                                                required
                                                onChange={() => {
                                                        if(values.alert_preferences.includes("Phone call")){
                                                            const index = values.alert_preferences.indexOf("Phone call");
                                                            if (index > -1) {
                                                                values.alert_preferences.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("Phone call")
                                                    }                                                   
                                                }    
                                                checked={values?.alert_preferences.includes("Phone call")}                                         
                                            />   
                                            <Checkbox                               
                                                name='SMS/Text message'
                                                value="sms"
                                                label="SMS/Text message"
                                                required
                                                onChange={() => {
                                                        if(values.alert_preferences.includes("SMS/Text message")){
                                                            const index = values.alert_preferences.indexOf('SMS/Text message');
                                                            if (index > -1) {
                                                                values.alert_preferences.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push('SMS/Text message')
                                                    }                                                   
                                                } 
                                                checked={values?.alert_preferences.includes("SMS/Text message")}                                                  
                                            />     
                                            <Checkbox                                
                                                name="Email"
                                                value="Email"
                                                label="Email"
                                                required
                                                onChange={() => {
                                                        if(values.alert_preferences.includes("Email")){
                                                            const index = values.alert_preferences.indexOf("Email");
                                                            if (index > -1) {
                                                                values.alert_preferences.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("Email")
                                                    }                                                   
                                                }
                                                checked={values?.alert_preferences.includes("Email")}  
                                            />                                                      
                                        </SemanticForm.Group>
                                        { touched.alert_preferences && errors.alert_preferences ? <Message negative>{errors.alert_preferences}</Message> : null } 
                                        {/* { touched && errors.alert_preferences ? <Message negative>Must select at least one alert preference.</Message> : null }     */}
                                      
                                        <label><b>Do you have a car?</b></label>         
                                        <SemanticForm.Group inline widths="equal">                                            
                                            
                                            <Radio  
                                                label="YES"                     
                                                name="mobility"                            
                                                value="has_car"                                        
                                                type='radio'                                        
                                                required                                    
                                            /> 
                                        
                                            <Radio                
                                                name="mobility"
                                                value="no_car"
                                                label="NO"
                                                type='radio'                                                                           
                                                required
                                            />                         
                                                            
                                        </SemanticForm.Group> 
                                        { touched.mobility && errors.mobility ? <Message negative>{errors.mobility}</Message> : null } 
                                        <SubmitButton 
                                            fluid                 
                                            id="caregiver_add_new_resume"                    
                                            // disabled={registerFormValid || loading }                                
                                            loading={loading}
                                            //onClick={handleSubmit}
                                            primary
                                            type="submit"
                                        >
                                            Add CV/resume
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default Application
