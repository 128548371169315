import ViewSingleJobPostUI from '../../../layouts/Job/read/ViewSinglePostingUI'
import { useParams, useHistory } from 'react-router-dom'
import React from 'react'
import { BSON } from 'realm-web'

const SingleJobPostingContainer = ({mongoContext : { user, client, userData }}) => {  
  //declare initial state of the component
  const [ candidates, setCandidates ] = React.useState([]) 
  const [ job, setJob ] = React.useState({}) 
  const [ loading, setLoading ] = React.useState(true)
  const [ error, setError ] = React.useState(null) 
  //get job id params from the route path
  const  { jobId } = useParams()
  const history = useHistory()
  //fetch job using user id and job id everytime component loads
   //declare async get job function inside use effect
  const jobs = client?.db('kinshealth').collection('jobs')  

  const users = client?.db('kinshealth').collection('users') 

  React.useEffect(() => {  
    console.log('....are we here')  
   // setLoading(true)
    const getJob = async() => {
      try{      

        const dbJob = await jobs?.findOne({ _id: BSON.ObjectId(jobId) })

        console.log('db job ', dbJob.geocode_address)

        const potentialCandidatesPipeline = [     
          {
            $search: {
              "index": "caregiversIndex",
              "moreLikeThis": {
                "like": [
                  {
                    geocode_address: dbJob.geocode_address,
                    availability: dbJob.schedule,                  
                    licenses: dbJob.licenses
                  }
                ]
              }
            }
          },
          {
            $match: {
                 complete: true, role: 'caregiver' 
            } 
          },
          {
            $project : {
              fname: 1,
              lname: 1,
              userID: 1,      
              licenses: 1,
              availability: 1,
              created: 1,             
              days: { 
               $subtract: [ 45, { $dateDiff: { startDate: "$created", endDate: "$$NOW", unit: "day"} }  ]
              }
            }
          },
          {
            $match: {
              days: {$gt: 0, $lte: 45 }
            }
          },
          {
              $limit: 5
          }
        ]        

        const potentialMatches = await users.aggregate(potentialCandidatesPipeline)

        console.log('what is in the potential matches ? ', potentialMatches)

        setCandidates(potentialMatches)

        //set the job in job state variable            
        setJob(dbJob)
        //console.log('JOB applicants', job.applicants)
        setLoading(false)

      }  catch (err) {
        setLoading(false)
        setError(err)
      }     
         
    }

    getJob()

    //return () => setLoading(false)
     //
  }, [ error, loading, jobId ])

  const deleteJob = async () => {
    await user?.callFunction('web_job_delete_by_id', jobId)
    alert(`You have successfully deleted your job post titled: ${job.title}.`)
    history.push(`/provider/job/read/all`)
  }
  
  return ( < ViewSingleJobPostUI job={job} user={user} candidates={candidates} loading={loading} error={error} deleteJob={deleteJob} userData={userData} /> )}

export default SingleJobPostingContainer