import React from 'react'
import {  
    Button,
    Container,   
    Grid,
    Header,
    Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom"
const PublicUserAlertsUI = () => {         

    return (     
        <Container style={{ maxWidth: 650, margin: 50 }}>
            <Grid centered stackable>              
                <Grid.Row>                   
                    <Grid.Column width={8}>                   
                    <Header as='h4'>
                        Kinscare alert notifications
                    </Header>

                    <p>
                        <b>We use SMS text message and phone call to connect caregivers and providers because SMS texts and phone calls are immediate, have a higher response rates than emails and are easy to use.</b>  That is why we have adopted these two modes of communications into Kinscare.<br/><br/>

                        <strong>Save our number (206) 309 - 7500 on your phone under Kinscare</strong> .  That way, you will be able to recognize us when we call you to attempt a connection with a caregiver seeking employment or a provider seeking a caregiver.  In future, Kinscare will adopt Whatsapp, an app that is also widely used by caregivers.  Make sure to select either phone call or SMS text messaging as an alert preference.          
                        <br/><br/>                                 
                    </p>
                    <Button as={Link} to="/signup" fluid primary icon>
                        <Icon name="sign-in"></Icon>
                            Sign up
                    </Button>
                                                                                   
                    </Grid.Column>
                </Grid.Row>                  
            </Grid>
        </Container>
    )
}

export default PublicUserAlertsUI