import React from 'react'
import { Icon, Segment } from 'semantic-ui-react'
import { Link, useHistory } from "react-router-dom"

const EmployerPanel = ({ user }) => {
    console.log('custom data ', user?.customData)
    const history = useHistory()

    async function checkUserCustomData() {
        //refresh the user data 
        await user?.refreshCustomData()

    }

    React.useEffect(() => { 
        checkUserCustomData() 
    }, [user])

    return (     
        <>
            {
                user?.customData?.complete ? 
                (
                    <Segment.Group raised>                          
                        {
                            !(user?.customData?.trial || !!user?.customData?.plan) ? 
                                <Segment><Link to='/provider/plans/view'><Icon name='user'></Icon>Become a subscriber</Link></Segment> : 
                            <>
                                <Segment><Link to='/provider/candidates/all'><Icon name='search'></Icon>Find candidates</Link></Segment> 
                                <Segment><Link to='/provider/job/create'><Icon name='pencil'></Icon>Post job opening</Link></Segment>
                                <Segment><Link to='/provider/job/read/all'><Icon name="list"></Icon>Your job postings</Link></Segment>            
                                <Segment><Link to='/provider/candidates/favorites'><Icon name="address book"></Icon>Saved candidates</Link></Segment> 
                                <Segment><Link to='/provider/plans/account'><Icon name='user'></Icon>Account and Settings</Link></Segment>
                                <Segment><Link to='/provider/read/resources'><Icon name="book"></Icon>Training resources</Link></Segment> 
                            </>
                        }
                    </Segment.Group>
                )
                :
                (
                    <Segment.Group raised>                      
                        <Segment><Link to='/provider/settings/form' id='new_provider_settings'>Add provider settings</Link></Segment>                        
                    </Segment.Group>
                )
            }           
        </>
    )
}

export default EmployerPanel
