import React from 'react'
import { Link } from 'react-router-dom'
import {
    Card,
    Button, 
    Container,
    Grid,  
    Divider,
    List,
    Icon
} from "semantic-ui-react";
import EmployerPanel from '../../../components/EmployerPanel';

const ViewSettings = ({ userData, user }) => {    
    return (     
        <div>
            { userData &&
            (<Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid centered stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>                       
 
                            <Card fluid>
                                {userData.full_name && (<Card.Content>
                                    <Card.Header>{userData.full_name}</Card.Header>
                                </Card.Content>)}                               

                                <Card.Content>
                                    <Card.Header>Address</Card.Header>
                                    <Card.Description> { userData.address } </Card.Description>
                                </Card.Content>                            
                                    
                                <Card.Content>
                                    <Card.Header>City, Zipcode</Card.Header>
                                    <Card.Description> { userData.city }, { userData.zipcode } </Card.Description>
                                </Card.Content>       
                               
                                <Card.Content>
                                    <Card.Header>Type of care setting(s)</Card.Header> 
                                    <Card.Description>
                                        <List divided horizontal relaxed>
                                            { userData.type_of_setting.map((setting, index)=> (
                                                <List.Item key={index}>{setting}</List.Item>
                                            ))}
                                        </List>                                        
                                    </Card.Description>
                                </Card.Content>
                                {
                                    userData.trainer==='yes' && 
                                    (<Card.Content>
                                        <Card.Header>Job search assistance </Card.Header>  
                                        <Card.Description>
                                            Provides sponsorship for caregiver training and/or offers caregiver training
                                        </Card.Description>                                  
                                    </Card.Content>) 
                                }  
                                {
                                    userData.trainer ==='no' && 
                                    (<Card.Content>
                                        <Card.Header>Job search assistance</Card.Header> 
                                        <Card.Description>
                                            Does not provides sponsorship for caregiver training and/or offers caregiver training.
                                        </Card.Description>                                      
                                    </Card.Content>) 
                                } 

                            </Card>
                        
                            <Divider hidden />
                            <Grid columns='equal'>
                                { userData?.settings?.alert_preferences.some(preference => preference === 'phone_call') && (<Grid.Column>
                                    <Button circular color='teal' icon='phone' size='massive' />
                                    <span>Call  </span>
                                </Grid.Column>) } 

                                { userData?.settings?.alert_preferences.some(preference => preference === 'sms') && (<Grid.Column>
                                    <Button circular color='yellow' icon='comment outline' size='massive' />
                                    <span>Text/SMS  </span>
                                </Grid.Column>) }

                                { userData?.settings?.alert_preferences.some(preference => preference === 'email') && (<Grid.Column>
                                    <Button circular color='google plus' icon='mail' size='massive'/>
                                    Email
                                </Grid.Column>)}
                            </Grid>                                            
                            
                        </Grid.Column>
                        <Button 
                            as={Link} to="/provider/update" 
                            primary 
                            floated="right" 
                            size='big' 
                            id="provider_update_settings"
                            icon>
                            <Icon name="edit"></Icon>
                            Update
                        </Button>
                    </Grid.Row>                   
                </Grid> 
            </Container>)}
        </div>
    )
}

export default ViewSettings
