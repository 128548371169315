import React from 'react'
import SelectRoleUI from '../../../layouts/User/SelectRoleUI'
//import selectRoleForm from './selectRoleForm'


const SelectRoleContainer = ({ mongoContext }) => {
  return (<SelectRoleUI mongoContext={ mongoContext } />)
}

export default SelectRoleContainer
