import {
    Form,
    SubmitButton,  
    Radio, 
    Checkbox
} from 'formik-semantic-ui-react'
import { BSON } from 'realm-web'
import { useHistory } from 'react-router-dom'
import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm,
    Header as SemanticHeader,
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import TagManager from 'react-gtm-module';

const AddProviderDetailsFormUI = ({ client, newUserId, setUserData }) => {  
    
    const [loading, setLoading] = React.useState(false)
   
    //get history for routing   
    const history = useHistory()
    const userSettingsSchema = Yup.object().shape({        
        alert_preferences: Yup.array().min(1, 'Must select at least 1 way for caregivers to contact you').required('required'),        
        type_of_setting: Yup.array().min(1, "Must select at least 1 type of care setting.").required('required'), //.min(1, "at least 1")
        trainer: Yup.string().required('Please select if you offer in house caregiver training or not')       
    })  

    // console.log('client in the application form ', client, 'user ', user)
    return (     
        <div>
            <Container centered style={{ maxWidth: 550, marginTop: 30, marginBottom: 75}}>
                <Grid centered stackable columns={1} >  
                    <Grid.Row>                        
                        <Grid.Column width={10}>
                        <SemanticHeader as='h3'>Please enter the following information </SemanticHeader>
                            <Segment> 
                            <Formik
                                initialValues = {{                                    
                                    alert_preferences: [],                                                                   
                                    type_of_setting: [],                                     
                                    trainer: ""                                                                
                                }}

                                validationSchema = { userSettingsSchema }

                                onSubmit={ async (values, { setSubmitting }) => {

                                    setSubmitting(true)

                                    setLoading(true)

                                    //create a reference to settings collections in db
                                    const collection = client.db('kinshealth').collection('users')

                                    //update settings with new data
                                    const results = await collection.updateOne(
                                        { _id: BSON.ObjectID(newUserId) }, 
                                        { $set : 
                                            {                                              
                                                "settings.alert_preferences": values.alert_preferences,                               
                                                type_of_setting: values.type_of_setting,
                                                trainer: values.trainer
                                            }
                                        },
                                        { upsert: true }
                                    )

                                    if(results.modifiedCount === 1){
                                        setLoading(false)
                                        setSubmitting(false)

                                        const tagManagerArgs = {
                                            dataLayer: {
                                                event: `provider_add_twilio_details`,    
                                                added: new Date(),   
                                                type: 'Twilio', 
                                                userId: newUserId     
                                            }           
                                        }
                                
                                        TagManager.dataLayer(tagManagerArgs)


                                        //update user
                                        setUserData(await collection?.findOne({ _id: BSON.ObjectID(newUserId)}))

                                        history.push(`/auth/${newUserId}`)
                                    }                             
                                }}                        
                            > 
                            {
                                ({ errors, touched, values }) => (
                                   
                                    <Form  >  
                                             
                                        <label><b>What kind of care provider are you? [Can check more than one]:</b></label> 
                                        <SemanticForm.Group widths="equal">                                            
                                            <Checkbox
                                                value="Hospital"
                                                name="hospital"
                                                label="Hospital/Clinic"
                                                className='provider_add_settings' 
                                                onChange={() => {
                                                        if(values.type_of_setting.includes("Hospital")){
                                                            const index = values.type_of_setting.indexOf("Hospital");
                                                            if (index > -1) {
                                                                values.type_of_setting.splice(index, 1);
                                                            }
                                                        } else values.type_of_setting.push("Hospital")
                                                    }                                                   
                                                }             
                                            />
                                            <Checkbox
                                                value="Assisted Living/Nursing Home"
                                                name="alh/nh"
                                                label="Assisted Living/Nursing Home"
                                                className='provider_add_settings' 
                                                onChange={() => {
                                                        if(values.type_of_setting.includes("Assisted Living/Nursing Home")){
                                                            const index = values.type_of_setting.indexOf("Assisted Living/Nursing Home");
                                                            if (index > -1) {
                                                                values.type_of_setting.splice(index, 1);
                                                            }
                                                        } else values.type_of_setting.push("Assisted Living/Nursing Home")
                                                    }                                                   
                                                }                      
                                            />
                                            <Checkbox
                                                value="Adult Family/Boarding Home"
                                                name="afh"
                                                label="Adult Family/Boarding Home" 
                                                className='provider_add_settings' 
                                                onChange={() => {
                                                        if(values.type_of_setting.includes("Adult Family/Boarding Home")){
                                                            const index = values.type_of_setting.indexOf("Adult Family/Boarding Home");
                                                            if (index > -1) {
                                                                values.type_of_setting.splice(index, 1);
                                                            }
                                                        } else values.type_of_setting.push("Adult Family/Boarding Home")
                                                    }                                                   
                                                }       
                                            />
                                            <Checkbox
                                                value="Home Care Agency"
                                                name="hca"
                                                label="Home Care Agency"    
                                                className='provider_add_settings'                                          
                                                onChange={() => {
                                                        if(values.type_of_setting.includes("Home Care Agency")){
                                                            const index = values.type_of_setting.indexOf("Home Care Agency");
                                                            if (index > -1) {
                                                                values.type_of_setting.splice(index, 1);
                                                            }
                                                        } else values.type_of_setting.push("Home Care Agency")
                                                    }                                                   
                                                }
                                                            
                                            />
                                            <Checkbox
                                                value="Other"
                                                name="other"
                                                label="Other"      
                                                className='provider_add_settings'                                        
                                                onChange={() => {
                                                        if(values.type_of_setting.includes("Other")){
                                                            const index = values.type_of_setting.indexOf("Other");
                                                            if (index > -1) {
                                                                values.type_of_setting.splice(index, 1);
                                                            }
                                                        } else values.type_of_setting.push("Other")
                                                    }                                                   
                                                }
                                                                
                                            />
                                        </SemanticForm.Group>                                     
                                        { touched.type_of_setting && errors.type_of_setting ? <Message negative>{ errors.type_of_setting }</Message> : null }  
                                        <label><b>Do you offer in house training?</b></label><br/>
                                        <span>In house training helps with caregiver onboarding.</span>         
                                        <SemanticForm.Group inline widths="equal">                                            
                                            <Radio  
                                                label="YES"                     
                                                name="trainer"                            
                                                value="yes"                                        
                                                type='radio'                
                                                className='provider_add_training_support'                         
                                                required                                    
                                            /> 
                                        
                                            <Radio
                                                label="NO"                
                                                name="trainer"
                                                value="no"                                                
                                                type='radio'    
                                                className='provider_add_training_support'                                                                        
                                                required
                                            />                                                           
                                        </SemanticForm.Group> 
                                        { touched.trainer && errors.trainer ? <Message negative>{errors.trainer}</Message> : null }  

                                        <label><b>How do you want caregivers to contact you? [Must choose one]:</b></label> <br/>
                                        
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Checkbox                             
                                                name="phone_call"                                                
                                                label="Phone call"
                                                value="phone_call"
                                                className='provider_add_alert_preferences' 
                                                required
                                                onChange={() => {
                                                    if(values?.alert_preferences?.includes("Phone call")){
                                                        const index = values.alert_preferences.indexOf("Phone call");
                                                        if (index > -1) {
                                                            values.alert_preferences.splice(index, 1);
                                                        }
                                                    } else values.alert_preferences.push("Phone call")
                                                    }                                                   
                                                }                                                
                                            />   
                                            <Checkbox                               
                                                name="sms"
                                                value="sms"
                                                label="SMS/Text message"
                                                className='provider_add_alert_preferences' 
                                                required
                                                onChange={() => {
                                                        if(values?.alert_preferences?.includes("SMS/Text message")){
                                                            const index = values.alert_preferences.indexOf("SMS/Text message");
                                                            if (index > -1) {
                                                                values?.alert_preferences?.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("SMS/Text message")
                                                    }                                                   
                                                }                                                 
                                            />     
                                            <Checkbox                                
                                                name="email"
                                                value="email"
                                                label="Email"
                                                className='provider_add_alert_preferences' 
                                                required
                                                onChange={() => {
                                                        if(values.alert_preferences.includes("Email")){
                                                            const index = values.alert_preferences.indexOf("Email");
                                                            if (index > -1) {
                                                                values.alert_preferences.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("Email")
                                                    }                                                   
                                                } 
                                            />                                                      
                                        </SemanticForm.Group>
                                        { touched.alert_preferences && errors.alert_preferences ? <Message negative>{errors.alert_preferences}</Message> : null }                                        
                                    
                                        <SubmitButton 
                                            fluid   
                                            id="provider_submit_details"                                     
                                            // disabled={registerFormValid || loading }                                
                                            loading={loading}
                                            //onClick={handleSubmit}
                                            primary
                                            type="submit"
                                        >
                                            Submit
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default AddProviderDetailsFormUI
