import { useState, useEffect } from 'react'
import {
    Form, 
    Radio,
    Button,    
    Grid,
    Header as SemanticHeader,
    Segment,
  } from "semantic-ui-react"  
import { useHistory } from "react-router-dom"
import TagManager from 'react-gtm-module';

const SelectRoleUI = ({ mongoContext: { app, client, user, setUser, setUserData, userData }}) => {
    const [ loading, setLoading ] = useState(false)
    const [ role, setRole ] = useState("")
    const [ form, setForm ] = useState({      
        role: '',
        tel: ''
    }) 

    const history = useHistory()

    useEffect(() => {  
        
        //if data role is not null
        if(!user?.customData?.role || role === '' ) history.push("/select")

        //if the role is caregiver, direct user to caregiver panel
        if(user?.customData?.role==='caregiver' || role === 'caregiver') history.push(`/vitae/jobs/all`)  

        //if the role is employer, direct user to employer
        if(user?.customData?.role==='provider' || role === 'provider') history.push(`/provider/candidates/all`)                   
        
    }, [user, role ]) 

    const onChange = (e, { name, value }) => {
        
        //setForm({ ...form, [name]: value });
        setForm( state =>({
            ...state,
            [name]: value
        }))
    } 

    const onSubmit = async (e) => {    
        
        e.preventDefault()    

        setLoading(true)
        
        const results = await user.callFunction('web_add_tel_role', {         
            userID: user.id,
            role: form.role,
            tel: form.tel          
        })
        //console.log('results in select role ', results)
     
        if(results){
            //this also refreshes the token
            await app.currentUser.refreshCustomData()

            const tagManagerArgs = {
                dataLayer: {
                    event: `sign_up`,                    
                    authMode: user?.identities[0]?.providerType,
                    role: user?.customData?.role,
                    type: 'Web',
                    userId: user?.id  
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)

            //set user
            setUser(app.currentUser)
            //
            setRole(user?.customData?.role)
            //get the most current record of user data in the db and set it
            setUserData(await client.db('kinshealth').collection('users').findOne({userID: user.id}) )
            //
            await user.callFunction('mailchimp_subscribe_user', {
                    email: userData.auth.email,
                    role: form.role,
                    tel: form.tel,
                    // auth_mode: "social_sign_in",
                    type: 'update role and tel',
                }
            )

            setLoading(false)
           
        }       
    } 
  
    return (
      <div>    
        <Grid centered>
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 550, marginTop: 20 }}>
                    <SemanticHeader as='h2'>Add phone number and role</SemanticHeader>
    
                    <Segment>
                        <Form onSubmit= {onSubmit}>
                                {/* <Form.Field>
                                    <SemanticHeader as='h3'> Are you looking for a: </SemanticHeader>
                                </Form.Field> */}
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        {/* <SemanticHeader as='h4'>job</SemanticHeader> */}
                                        
                                        <Radio
                                            label = 'I AM A CAREGIVER LOOKING FOR A JOB'
                                            name = 'role'
                                            checked = {form.role==='caregiver'}
                                            value = "caregiver"
                                            className="user_role"
                                            onChange = { onChange }
                                            type='radio'
                                            required
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                    {/* <label><b>caregiver</b></label> */}
                                        <Radio
                                            label='I AM A PROVIDER HIRING CAREGIVERS/CNAs'
                                            name='role'
                                            checked={form.role==='provider'}
                                            value= "provider"
                                            type='radio'
                                            className="user_role"
                                            onChange={ onChange }
                                            required
                                        />
                                    </Form.Field>                            
                                </Form.Group>
                                <Form.Field>
                                    <Form.Input
                                        value={ form.tel || "" }
                                        onChange={ onChange }
                                        name="tel"
                                        type="tel"
                                        placeholder="Enter your mobile phone number"
                                        label="Mobile number"                              
                                        required
                                    />
                                </Form.Field>                                          
                        
    
                                <Button
                                    // onClick={onSubmit}
                                    id="user_select_role"
                                    fluid
                                    loading={loading}
                                    primary
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Form>              
                        </Segment>                           
                    </Grid.Column>
                </Grid.Row>         
        </Grid>               
      </div>
    )
  }
  
  export default SelectRoleUI
  
