import { useEffect, useState } from 'react'
import { useHistory, Link  } from 'react-router-dom'
import { isAnon } from '../../utils/isAnon'
import {
    Form,     
    Button, 
    Divider,   
    Grid,
    Message,
    Header as SemanticHeader,
    Segment,
  } from "semantic-ui-react";
import * as Realm from 'realm-web'
import TagManager from 'react-gtm-module';

const SignInUI = ({ mongoContext: { app, client, user, setUser, setUserData }, form: { onChange, registerFormValid, loading, form}}) => {

    //initiate error state
    const [ error, setError ] = useState({        
        message: "",
        error_state: false
    })    
    //get a reference to users collection
    const users = client?.db('kinshealth').collection('users') 

    const history = useHistory()  

    useEffect(() => {      

        const routeUser = async(user) => {

            //refresh user
            await user?.refreshCustomData()
            //check if the user is authenticated anonymously or not
            
            if(!isAnon(user)){
                switch(user?.customData?.role){
    
                    case 'caregiver':       
                    
                        history.push('/vitae/jobs/all') 
                        
                            // if(user?.customData?.complete) { 
                            //     //if the caregiver has a cv/resume in DB, direct caregiver to view jobs
                            //     history.push('/vitae/jobs/all') 
                            // } else {
                            //     //else direct the caregiver to add job
                            //     history.push('/vitae/current')
                            // } 
                        break;

                    case 'provider':

                        history.push('/provider/candidates/all')
                        //if the provider has not added the settings data, send them to the 
                        // if(!user?.customData?.complete) {
                        //     history.push('/provider/settings/form')
                        // } 
                        
                        // //else {
                        //     if( user?.customData?.trial && user?.customData?.complete ){
                        //         //direct user to provider to candidate listings if the provider is in trial phase
                        //         history.push('/provider/candidates/all')
                        //     } 
                            
                        //     if(!user?.customData?.trial && user?.customData?.complete) {
                        //         //check if the provider is a subscriber
                        //         if( user?.customData?.subscriber ){
                        //             //if provider is a subscriber, direct provider to caregivers' listings
                        //             //otherwise have the provider view subscription plans
                        //             history.push('/provider/candidates/all')
                        //         } else history.push('/provider/plans/view')
                        //     }   
                       // }
    
                                    
    
                        break;
                    
                    case undefined :
                
                        //if user is authenticated and without a role
                        //ask user to select role
                        if(!user?.customData?.role ) history.push("/select")
    
                        break;
                         
                } 
            } 
            
            // else {
            //     history.push('/signin')
            // }
        }
  
        routeUser(user)
       
    }, [app, client, user ]) 

    const fbSignIn = async() => {
        // The redirect URI should be on the same domain as this app and
        // specified in the auth provider configuration.
        const redirectUri = process.env.NODE_ENV==="production" ? "https://www.kinscare.org/oauthredirect" : "localhost:3000/oauthredirect" 
        const credentials = Realm.Credentials.facebook(redirectUri) 
        try{
            //login user
            await app.logIn(credentials)          

            if(!app.currentUser.customData.userID){                
                //create full name to add
               const full_name = app.currentUser.profile.firstName + " " + app.currentUser.profile.lastName  
               //create payload
               const payload = {
                   email : app.currentUser.profile.email, 
                   userID: app.currentUser.id, 
                   full_name, 
                   auth_mode : 'oauth2-facebook', 
                   created: new Date()
               }
               //call with function
               await user.callFunction("web_add_social_user_custom_data", payload)                                      
            }

            //this also refreshes the token
            await app.currentUser.refreshCustomData() 

            const tagManagerArgs = {
                dataLayer: {
                    event: `sign_in`,
                    authMode: 'oauth2-facebook',                   
                    role: app?.currentUser?.customData?.role,
                    userId: app?.currentUser?.customData?.userID      
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)      
            
            //set the user data
            setUserData(await users.findOne({ "auth.email": app?.currentUser?.customData?.email })) 
            //set the user
            setUser(app.currentUser)
      
        } catch(err){            
            setError({
                message: err.error,
                error_state: true
            })
        }
    }

    const googleSignIn = async() => {
        // The redirect URI should be on the same domain as this app and
        // specified in the auth provider configuration.
        const redirectUri = process.env.NODE_ENV==="production" ? "https://www.kinscare.org/oauthredirect" : "localhost:3000/oauthredirect" 
        const credentials = Realm.Credentials.google(redirectUri) //: Realm.Credentials.google(redirectUri)
        try{
            //login user
            await app.logIn(credentials)          

            if(!app.currentUser.customData.userID){                
                //create full name to add
               const full_name = app.currentUser.profile.firstName + " " + app.currentUser.profile.lastName  
               //create payload
               const payload = {
                   email : app.currentUser.profile.email, 
                   userID: app.currentUser.id, 
                   full_name, 
                   auth_mode : 'oauth2-google', 
                   created: new Date()
               }
               //call with function
               await user.callFunction("web_add_social_user_custom_data", payload)                                      
            }

            //this also refreshes the token
            await app.currentUser.refreshCustomData() 

            const tagManagerArgs = {
                dataLayer: {
                    event: `sign_in`,
                    authMode: 'oauth2-google',                   
                    role: app?.currentUser?.customData?.role,
                    userId: app?.currentUser?.customData?.userID      
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)      
            
            //set the user data
            setUserData(await users.findOne({ "auth.email": app?.currentUser?.customData?.email })) 
            //set the user
            setUser(app.currentUser)
      
        } catch(err){            
            setError({
                message: err.error,
                error_state: true
            })
        }
    }

    const onSubmit = async(e) => {
        //prevent default behavior
        e.preventDefault()
        try{

            //convert email supplied to lower case
            const email = form.email.toLowerCase()           

            //login user
            const credentials = Realm.Credentials.emailPassword(email, form.password)

            //login user
            await app.logIn(credentials)         
     
            //refresh custom data/token
            await app.currentUser.refreshCustomData() 
            
            setUserData(await users.findOne({ "auth.email": email })) 
            
            const tagManagerArgs = {
                dataLayer: {
                    event: `sign_in`,
                    authMode: "local-userpass",                   
                    role: app.currentUser.customData.role,
                    userId: app.currentUser.customData.userID      
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)

            //set the user
            setUser( app.currentUser )        

            

        } catch(err){
            console.log('Sign in error ', err)
            setError({
                message: err?.error?.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign up instead.',
                error_state: true
            })            
        }
    }
  
    return (
        <div>   
            <Grid centered>
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 600, marginTop: 20 }}>
                        <SemanticHeader as='h2'>Sign in here</SemanticHeader>
        
                        <Segment>
                            <Form onSubmit= {onSubmit} id='web_user_sign_in'>                                       
                                <Form.Field>
                                    <Form.Input
                                        value={form.email || ""}
                                        onChange={onChange}
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        label="Email"                     
                                        required
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        value={form.password || ""}
                                        onChange={onChange}
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        label="Password"
                                        minLength="6"                                
                                        required
                                    />
                                </Form.Field>            

                                <Button
                                    onClick={onSubmit}
                                    disabled={ registerFormValid || loading }
                                    fluid
                                    id="user_local_sign_in"
                                    primary
                                    type="submit"
                                >
                                    Sign in
                                </Button>
                            </Form>
                    
                        </Segment>
                        { error.error_state && (<Message centered negative>
                            <Message.Header> {error.message} </Message.Header>                            
                        </Message>) }
                            <Segment>
                                <span className="bold">  Don't have an account? <Link to="/signup">Register for one.</Link> </span>
                                <br /> <br />
                                <span className="bold">  Forgot your password? <Link to="/reset/email">Reset your password.</Link> </span>
                            </Segment>
                    
                        <Divider horizontal>Or</Divider>
                                
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 500, marginBottom: 50 }}>
                        <Button                
                            color='blue'
                            id="user_facebook_sign_in"
                            content='Continue with Facebook'
                            icon='facebook f'
                            labelPosition='right'
                            onClick={ fbSignIn }
                        />
        
                        <Button                    
                            color='red'
                            content='Continue with Google'
                            id="user_google_sign_in"
                            icon='google plus g'
                            labelPosition='right'
                            onClick={ googleSignIn }
                        />   
                    </Grid.Column>
                </Grid.Row>            
            </Grid>   
                
        </div>
    )
}
  
export default SignInUI
  
  //google