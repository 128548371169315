import React from 'react'
import EmployerPanel from '../../../components/EmployerPanel'
import JobCandidates from '../read/ViewCandidatesUI'
import {
    Form,
    SubmitButton,  
    Checkbox    
} from 'formik-semantic-ui-react'

import {
    Form as SemanticForm,
    Button,
    Container,
    Grid,  
    Icon,
    Message,
    Segment,
    Header as SemanticHeader,
} from "semantic-ui-react"
import { Link, useParams } from "react-router-dom";
import { Formik } from 'formik'
import * as Yup from 'yup'
import TagManager from 'react-gtm-module';

const CandidateSearchPanelUI = ({ user, userData } ) => {  
    //set initial states for search, candidates, error, loading
    const [ search, setSearch ] = React.useState(false)
    const [ candidates, setCandidates ] = React.useState([])
    const [ error, setError ] = React.useState(null)
    const [ loading, setLoading ] = React.useState(true) 

    //get type
    const { type } = useParams()

    React.useEffect(() => {

        const getCandidates = async() => {
            if(!search){           
                //set the pipeline for all recent jobs
                if(type === 'all'){
    
                    setCandidates([])

                    //check if user data has geocode coordinates
                    const pipeline = userData?.geocode_address?.coordinates ?
                        [ 
                            {                            

                                "$geoNear": {
                                    "near":  { 
                                        "type": "Point",
                                        "coordinates":[userData?.geocode_address?.coordinates?.lng, userData?.geocode_address?.coordinates?.lat]
                                    },
                                    "query": { role:"caregiver", complete: true },
                                    "spherical": true,
                                    "distanceField": "distance",
                                    "maxDistance": 70000,
                                    "distanceMultiplier": 1/1609
                                }
                            }, {
                                $project: {
                                    distance: { $ceil: "$distance" },
                                    fname: 1, lname: 1,
                                    licenses: 1,
                                    availability: 1,
                                    created: 1,
                                    userID: 1
                                }
                            },{
                                '$sort': {
                                    'distance': 1, 'created': -1
                                }
                            }               
                        ] : [
                            
                            {
                                $match : {
                                    role: 'caregiver', 
                                    complete: true
                                }
                            },       
                            {
                                $sort: { 'created': -1 }
                            }
                        ]
                    
                   
                    const {data, err} = await user?.callFunction('web_fetch_candidates', pipeline)                 

                    if(err){
                        setError(err)
                    } else {
                        setCandidates(data)
                    }

                    setLoading(false)
                    //fetchJobs(pipeline)                 
                } 
                
                if(type === 'favorites') {
                    //get a reference of the employers' collection
    
                    setCandidates([])

                    console.log('ehash ', user?.customData?.hash)

                    const { data : {saved_candidates}, err } = await user?.callFunction("web_fetch_favorite_caregivers", user?.id)  
                    console.log('saved_candidates ', saved_candidates)
                    if(err){
                        setError(err)
                    } else {
                        setCandidates(saved_candidates)
                    }                   
                    
                    setLoading(false)    
                }
            }
        }

        if(user?.customData?.complete){

           getCandidates() 
        } 
        
    }, [ type, user ])
    
    const jobPostSchema = Yup.object().shape({    
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'."),//.required('required'), //.min(1, "at least 1")
        availability:  Yup.array().min(1, "Select at least 1 schedule you are available to work."),//.required('required'),
    })  

    // console.log('client in the application form ', client, 'user ', user)
    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid  stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                        {
                            user?.customData?.complete ? 
                            <>                            
                                <Grid.Row>
                                    <SemanticHeader as='h3'>Search candidates</SemanticHeader>
                                    <Segment> 
                                        <Formik
                                            initialValues ={{
                                                licenses: [],
                                                availability: []                                 
                                            }}

                                            validationSchema = { jobPostSchema }

                                            onSubmit={ async (values, { resetForm }) => {                                 
                                            
                                                //return
                                                //set search to true
                                                setSearch(true)                                         

                                                /**
                                                 * create an aggregation pipeline that sorts jobs:
                                                 * - from one closest to the furthest from the candidate's geoaddress
                                                 * - uses 
                                                 */
                                                const pipeline = [

                                                    { 
                                                        "$geoNear": {
                                                            "near": {
                                                                "type": "Point",
                                                                "coordinates": [ userData?.geocode_address?.coordinates?.lng, userData?.geocode_address?.coordinates?.lat ],
                                                            },
                                                            "spherical": true,
                                                            "query": { "licenses": { $in:values.licenses} , "availability":{ $in: values.availability} },
                                                            "includeLocs": "dist.location",
                                                            "distanceField": "dist.calculated",
                                                            "maxDistance": 70000,
                                                            "distanceMultiplier": 1/1610                        
                                                        }
                                                    },         
                                                    {
                                                        '$sort': {
                                                            'created': -1
                                                        }
                                                    }                                                   
                                                    
                                                ]   

                                                setCandidates([])
                                                
                                                const { data, err } = await user?.callFunction('web_fetch_candidates', pipeline)

                                                
                                                const tagManagerArgs = {
                                                    dataLayer: {
                                                        event: `provider_search_caregivers`,
                                                        availability: values.availability.toString(),      
                                                        licenses: values.licenses.toString(),
                                                        searchResults: data.length,                               
                                                        userId: user?.id                                                        
                                                    }           
                                                }
                                        
                                                TagManager.dataLayer(tagManagerArgs)

                                                if(err){
                                                    setError(err)
                                                } else {
                 
                                                    //fetchJobs(pipeline)
                                                    setCandidates(data)
                                                    setError(null)
                                                }                                            

                                                setLoading(false)

                                                resetForm();
                                            }}                        
                                        > 
                                        {
                                            ({ errors, touched, values,  handleBlur }) => (
                                            
                                                <Form>                                                  
                                                    <label><b>What license(s) are required for your opening?</b></label>
                                                    <SemanticForm.Group widths="equal" inline required>    
                                                                                        
                                                        <Checkbox
                                                            value="CNA"
                                                            name="CNA"
                                                            label="CNA or NAC" 
                                                            className="provider_license_search_criteria" 
                                                            onChange={() => {
                                                                    if(values.licenses.includes("CNA")){
                                                                        const index = values.licenses.indexOf("CNA");
                                                                        if (index > -1) {
                                                                            values.licenses.splice(index, 1);
                                                                        }
                                                                    } else values.licenses.push("CNA")
                                                                }                                                   
                                                            }                                                 
                                                        />
                                                        <Checkbox
                                                            value="HCA"
                                                            name="HCA"
                                                            label="HCA" 
                                                            className="provider_license_search_criteria"        
                                                            onChange={() => {
                                                                    if(values.licenses.includes("HCA")){
                                                                        const index = values.licenses.indexOf("HCA");
                                                                        if (index > -1) {
                                                                            values.licenses.splice(index, 1);
                                                                        }
                                                                    } else values.licenses.push("HCA")
                                                                }                                                   
                                                            }                                                                        
                                                        />
                                                        <Checkbox
                                                            value="NAR"
                                                            name="NAR"
                                                            label="NAR"  
                                                            className="provider_license_search_criteria" 
                                                            onChange={() => {
                                                                    if(values.licenses.includes("NAR")){
                                                                        const index = values.licenses.indexOf("NAR");
                                                                        if (index > -1) {
                                                                            values.licenses.splice(index, 1);
                                                                        }
                                                                    } else values.licenses.push("NAR")
                                                                }                                                   
                                                            }           
                                                        />
                                                        <Checkbox                                               
                                                            value="None"
                                                            name="None"
                                                            label="None"             
                                                            className="provider_license_search_criteria"                                 
                                                            onChange={() => {
                                                                    if(values.licenses.includes("None")){
                                                                        const index = values.licenses.indexOf("None");
                                                                        if (index > -1) {
                                                                            values.licenses.splice(index, 1);
                                                                        }
                                                                    } else values.licenses.push("None")
                                                                }                                                   
                                                            }                  
                                                        />
                                                    </SemanticForm.Group>                                       

                                                    {/* {errors.certifications && <Message>{errors.certifications}</Message>}   */}
                                                    <label><b>What job opening shifts do you need a caregiver for?</b></label><br/>
                                                    <SemanticForm.Group widths="equal">                                            
                                                        <Checkbox
                                                            value="Full time"
                                                            name="full_time"
                                                            label="Full time"
                                                            className="provider_availability_search_criteria" 
                                                            onChange={() => {
                                                                    if(values.availability.includes("Full time")){
                                                                        const index = values.availability.indexOf("Full time");
                                                                        if (index > -1) {
                                                                            values.availability.splice(index, 1);
                                                                        }
                                                                    } else values.availability.push("Full time")
                                                                }                                                   
                                                            }                                                                           
                                                        />
                                                        <Checkbox
                                                            value="Part time"
                                                            name="part_time"
                                                            label="Part time"
                                                            className="provider_availability_search_criteria" 
                                                            onChange={() => {
                                                                    if(values.availability.includes("Part time")){
                                                                        const index = values.availability.indexOf("Part time");
                                                                        if (index > -1) {
                                                                            values.availability.splice(index, 1);
                                                                        }
                                                                    } else values.availability.push("Part time")
                                                                }                                                   
                                                            }                                                                              
                                                        />
                                                        <Checkbox
                                                            value="Weekends"
                                                            name="weekends"
                                                            label="Weekends" 
                                                            className="provider_availability_search_criteria" 
                                                            onChange={() => {
                                                                    if(values.availability.includes("Weekends")){
                                                                        const index = values.availability.indexOf("Weekends");
                                                                        if (index > -1) {
                                                                            values.availability.splice(index, 1);
                                                                        }
                                                                    } else values.availability.push("Weekends")
                                                                }                                                   
                                                            }
                                                                    
                                                        />
                                                        <Checkbox
                                                            value="On Call"
                                                            name="on_call"
                                                            label="On Call" 
                                                            className="provider_availability_search_criteria"                                           
                                                            onChange={() => {
                                                                if(values.availability.includes("On Call")){
                                                                    const index = values.availability.indexOf("On Call");
                                                                    if (index > -1) {
                                                                        values.availability.splice(index, 1);
                                                                    }
                                                                } else values.availability.push("On Call")
                                                                }                                                   
                                                            }                                                                     
                                                        />
                                                        <Checkbox
                                                            value="Live In"
                                                            name="live_in"
                                                            label="Live In" 
                                                            className="provider_availability_search_criteria"                                           
                                                            onChange={() => {
                                                                    if(values.availability.includes("Live In")){
                                                                        const index = values.availability.indexOf("Live In");
                                                                        if (index > -1) {
                                                                            values.availability.splice(index, 1);
                                                                        }
                                                                    } else values.availability.push("Live In")
                                                                }                                                   
                                                            }                                                                      
                                                        />
                                                    </SemanticForm.Group>                                             
                                
                                                    <SubmitButton 
                                                        fluid      
                                                        id='provider_search_caregivers'                              
                                                        // disabled={registerFormValid || loading }                                
                                                        // loading={!loading}
                                                        //onClick={handleSubmit}
                                                        primary
                                                        type="submit"
                                                    >
                                                        Search candidates
                                                    </SubmitButton>
                                                </Form> 
                                            )
                                        }                            
                                        </Formik>                           
                                    </Segment>  
                                </Grid.Row>
                                <Grid.Row>
                                    <JobCandidates candidates={candidates} loading={loading} error={error} search={search}/>
                                </Grid.Row>  
                            </>
                            :
                            <>
                                <Message info floating>
                                    <Message.Header> Add information about your organization </Message.Header>
                                    <p>To get the most out of Kinshealth, add your information in order to:</p>
                                    <ul>  
                                        <li>search, save, and contact caregivers</li> 
                                        <li>make it easy to post your job opening</li>                              
                                        <li>match with caregivers nearest you</li>
                                        <li>let caregivers know how to contact you</li>
                                        <li>educate caregivers about your organization</li>                                    
                                    </ul>
                                    <div className='add_provider_settings_info'>
                                        <Button 
                                            as={Link} 
                                            to='/provider/settings/form'
                                            color='red' 
                                            id='add_provider_settings_info'
                                        >
                                        <Icon name="plus square"></Icon>
                                            Add provider information
                                        </Button>
                                    </div>                                    
                                </Message>
                            </>
                        }                                        
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default CandidateSearchPanelUI
