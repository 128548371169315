
import {
    Container,
    Divider,
    Grid,
    Message
  } from "semantic-ui-react";

function ResetPasswordConfirmationUI () {  

    return (
        <Container>
            <Grid>
            <Divider hidden section />           
                <Grid.Row>
                <Container textAlign="center">
                <Grid.Column style={{ maxWidth: 800, marginTop: 20, marginBottom: 20, marginLeft: 150 }}> 
                    <Message success size='massive'> Please visit your email to complete resetting your password.</Message>
                </Grid.Column>                                          
                </Container>           
                </Grid.Row>       
            </Grid>
        </Container>
    )
}

export default ResetPasswordConfirmationUI 