import PostingsUI from '../../../layouts/Job/read/ViewPostingsUI'
import React from 'react'

const ProviderJobPostingsContainer = ({ mongoContext : { client, user, userData } }) => {  
    const [ loading, setLoading ] = React.useState(true)
    const [ error, setError ] = React.useState(null)
    const [ postings, setPostings ] = React.useState([])
    const [ drafts, setDrafts ] = React.useState([])
    
    React.useEffect(() => {
        const getProviderPostings = async () => {

            //refresh user custom data and therefore the user
            await user?.refreshCustomData()
           
            //create a pipeline to query jobs posted only by the logged in user
            const pipeline = [
                {
                    $match : {'hash': user?.customData?.hash } 
                },
                {
                    $sort : {'created': -1}
                }
            ]
                
            const { data, err } = await user?.callFunction("web_fetch_jobs", pipeline)

            if(err){
                setError(err)
            } else {

                //sort drafts and postings
                //postings are job opening announcements viewable to the public and the provider has either paid $ 17.99 or $ 39.99 to create the posting
                //drafts are job openings announcements not viewable to the public and the provider has yet to pay the job posting fee

                //filter postings
                const filteredPostings = data.filter(posting => !posting.draft)

                setPostings(filteredPostings)

                //filter drafts
                const draftPostings = data.filter(posting => posting.draft)
                setDrafts(draftPostings)  
            }
            
            //set loading to false 
            setLoading(false) 
                  
        }

        //invoke get postings
        getProviderPostings()
    }, [ client, user ])

    return (<PostingsUI loading={loading} error={error} postings={postings} drafts={drafts} userData={userData} user={user}/>)}

export default ProviderJobPostingsContainer