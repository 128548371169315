import React from 'react';
import JobListings from '../read/PublicPostingsUI';
import {
    Form,
    SubmitButton,  
    Checkbox    
} from 'formik-semantic-ui-react';

import {
    Form as SemanticForm,
    Message,
    Container,
    Grid,  
    Segment,
    Header as SemanticHeader,
} from "semantic-ui-react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import TagManager from 'react-gtm-module';

const JobSearchPanelUI = ({  mongoContext: { client, user } }) => {   
    //set initial states for search, openings, error, loading
    const [ search, setSearch ] = React.useState(false)
    const [ openings, setOpenings ] = React.useState([])
    const [ error, setError ] = React.useState(null)
    const [ loading, setLoading ] = React.useState(true)

    //create jobs collection
    const collection = client?.db('kinshealth').collection('jobs');

    React.useEffect(() => {

        const fetchJobs = async () => {

            setLoading(true)
        
            //set the pipeline for public jobs sorted by the date a job was created
            if(!search){
                const pipeline = [
                
                    {
                        "$project" : { "applicants": 0 }
                    },
                    {
                        '$sort': {
                            'created': -1
                        }
                    }               
                ] 

               // setOpenings([])
                //get jobs to display for the
                collection.aggregate(pipeline)
                    .then(publicJobs => {
                        setOpenings(publicJobs)
                        setError(null)
                        setLoading(false)
                    }).catch(err => {
                        setError(err)
                        setLoading(false)
                    })
            }
        }
        
        fetchJobs()
       
       // error, loading, openings user, client, error, loading 
    }, [])
    
    const jobSearchSchema= Yup.object().shape({    
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'."),//.required('required'), //.min(1, "at least 1")
        schedule:  Yup.array().min(1, "Select at least 1 schedule you are available to work."),//.required('required'), 
    })  

    // console.log('client in the application form ', client, 'user ', user)
    return (     
        <div>
            <Container>
                <Grid centered stackable columns={1}>  
                    <Grid.Row style={{ maxWidth: 1000, marginTop: 30, marginLeft: 25, marginBottom: 75 }}>                       
                        <Grid.Column width={12}>
                            <Grid.Row>
                                <SemanticHeader as='h3'>Search jobs</SemanticHeader>
                                <Segment> 
                                    <Formik
                                        initialValues ={{
                                            schedule: [],  
                                            licenses: []                                                                         
                                        }}

                                        validationSchema = {jobSearchSchema}

                                        onSubmit={ async (values, { resetForm, setSubmitting }) => {  
                                            
                                            console.log('are we getting here?....')
                                            //return
                                            //set search to true
                                            setSearch(true)                                         

                                            /**
                                             * create an aggregation pipeline that sorts jobs:
                                             * - from one closest to the furthest from the candidate's geoaddress
                                             * - uses 
                                             */
                                            const searchPipeline = [
                                                {
                                                    $match : {
                                                        $and:[
                                                            { "schedule": { $in: values.schedule} },
                                                            { "licenses": { $in: values.licenses} },
                                                            { "draft": false }                                                        
                                                        ]
                                                    }
                                                },
                                                {
                                                    $project : { "applicants": 0, "geocode_address": 0, "userID":0 }
                                                },
                                                {
                                                    $sort: {
                                                        'created': -1
                                                    }
                                                } 
                                            ]

                                            setOpenings([])

                                            collection.aggregate(searchPipeline)
                                                      .then(jobSearchResults => {

                                                        
                                                            setOpenings(jobSearchResults) 

                                                             //collect and send metric about the length of caregiver search results
                                                            const tagManagerArgs = {
                                                                dataLayer: {
                                                                    event: `user_search_public_jobs`,
                                                                    licenses: values.licenses.toString(),
                                                                    schedule: values.schedule.toString(), 
                                                                    searchResults: jobSearchResults.length                                                          
                                                                }           
                                                            }
                                                    
                                                            TagManager.dataLayer(tagManagerArgs)


                                                            setSearch(false) 
                                                            setError(null)
                                                      }).catch(err => {
                                                            setError(err)
                                                            setSearch(false)
                                                      }) 
                                                                                    
                                            resetForm()
                                        }}                        
                                    > 
                                    {
                                        ({ errors, values}) => (
                                        
                                            <Form>   
                                                
                                                { errors.full_name ? (<Message>{ errors.full_name }</Message>) : null }      
                                                <label><b>Which license(s) do you have?</b></label>
                                                <SemanticForm.Group widths="equal" inline required>                                    
                                                    <Checkbox
                                                        value="CNA"
                                                        name="CNA"
                                                        label="CNA or NAC" 
                                                        onChange={() => {
                                                                if(values.licenses.includes("CNA")){
                                                                    const index = values.licenses.indexOf("CNA");
                                                                    if (index > -1) {
                                                                        values.licenses.splice(index, 1);
                                                                    }
                                                                } else values.licenses.push("CNA")
                                                            }                                                   
                                                        }                                                 
                                                    />
                                                    <Checkbox
                                                        value="HCA"
                                                        name="HCA"
                                                        label="HCA"        
                                                        onChange={() => {
                                                                if(values.licenses.includes("HCA")){
                                                                    const index = values.licenses.indexOf("HCA");
                                                                    if (index > -1) {
                                                                        values.licenses.splice(index, 1);
                                                                    }
                                                                } else values.licenses.push("HCA")
                                                            }                                                   
                                                        }                                                                        
                                                    />
                                                    <Checkbox
                                                        value="NAR"
                                                        name="NAR"
                                                        label="NAR"  
                                                        onChange={() => {
                                                                if(values.licenses.includes("NAR")){
                                                                    const index = values.licenses.indexOf("NAR");
                                                                    if (index > -1) {
                                                                        values.licenses.splice(index, 1);
                                                                    }
                                                                } else values.licenses.push("NAR")
                                                            }                                                   
                                                        }           
                                                    />
                                                    <Checkbox                                               
                                                        value="None"
                                                        name="None"
                                                        label="None"                                             
                                                        onChange={() => {
                                                                if(values.licenses.includes("None")){
                                                                    const index = values.licenses.indexOf("None");
                                                                    if (index > -1) {
                                                                        values.licenses.splice(index, 1);
                                                                    }
                                                                } else values.licenses.push("None")
                                                            }                                                   
                                                        }                  
                                                    />
                                                </SemanticForm.Group>       

                                                <label><b>What shift are you looking to work?</b></label><br/>
                                                <SemanticForm.Group widths="equal">                                            
                                                    <Checkbox
                                                        value="Full time"
                                                        name="full_time"
                                                        label="Full time"
                                                        onChange={() => {
                                                                if(values.schedule.includes("Full time")){
                                                                    const index = values.schedule.indexOf("Full time");
                                                                    if (index > -1) {
                                                                        values.schedule.splice(index, 1);
                                                                    }
                                                                } else values.schedule.push("Full time")
                                                            }                                                   
                                                        }                  
                                                    />
                                                    <Checkbox
                                                        value="Part time"
                                                        name="part_time"
                                                        label="Part time"
                                                        onChange={() => {
                                                                if(values.schedule.includes("Part time")){
                                                                    const index = values.schedule.indexOf("Part time");
                                                                    if (index > -1) {
                                                                        values.schedule.splice(index, 1);
                                                                    }
                                                                } else values.schedule.push("Part time")
                                                            }                                                   
                                                        }
                                                                              
                                                    />
                                                    <Checkbox
                                                        value="Weekends"
                                                        name="weekends"
                                                        label="Weekends" 
                                                        onChange={() => {
                                                                if(values.schedule.includes("Weekends")){
                                                                    const index = values.schedule.indexOf("Weekends");
                                                                    if (index > -1) {
                                                                        values.schedule.splice(index, 1);
                                                                    }
                                                                } else values.schedule.push("Weekends")
                                                            }                                                   
                                                        }
                                                                  
                                                    />
                                                    <Checkbox
                                                        value="On Call"
                                                        name="on_call"
                                                        label="On Call"                                             
                                                        onChange={() => {
                                                                if(values.schedule.includes("On Call")){
                                                                    const index = values.schedule.indexOf("On Call");
                                                                    if (index > -1) {
                                                                        values.schedule.splice(index, 1);
                                                                    }
                                                                } else values.schedule.push("On Call")
                                                            }                                                   
                                                        }          
                                                    />
                                                    <Checkbox
                                                        value="Live In"
                                                        name="live_in"
                                                        label="Live In"                                             
                                                        onChange={() => {
                                                                if(values.schedule.includes("Live In")){
                                                                    const index = values.schedule.indexOf("Live In");
                                                                    if (index > -1) {
                                                                        values.schedule.splice(index, 1);
                                                                    }
                                                                } else values.schedule.push("Live In")
                                                            }                                                   
                                                        }
                                                                      
                                                    />
                                                </SemanticForm.Group> 

                                                <SubmitButton 
                                                    fluid                    
                                                    id="user_search_for_jobs"                
                                                    primary
                                                >
                                                    Find jobs
                                                </SubmitButton>
                                            </Form> 
                                        )
                                    }                            
                                    </Formik>                           
                                </Segment>  
                            </Grid.Row>
                            <Grid.Row >
                                <JobListings openings={openings} loading={loading} error={error} search={search}/>
                            </Grid.Row>                                           
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default JobSearchPanelUI
