import { useEffect, useState } from 'react'
import ViewJobOpeningUI from '../../../layouts/Application/read/ViewJobOpeningUI'
import ViewPublicJobOpeningUI from '../../../layouts/Application/read/ViewPublicJobOpeningUI'
import { useParams, useHistory } from 'react-router-dom'
import { BSON } from 'realm-web'
import TagManager from 'react-gtm-module';

const ViewJobOpeningContainer = ({ mongoContext : {app, client, setUserData, user, userData }}) => {    

    //declare initial state for the component
    const [ job, setJob ] = useState({})
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ] = useState(null)
    const [ similarJobs, setSimilarJobs ] = useState([])
    //get the job id from the parameter
    const { jobId } = useParams()

    const history = useHistory()

    //get a reference of the job collection
    const collection = client?.db('kinshealth').collection('jobs') 

    //get a reference of the users collection
    const users = client?.db('kinshealth').collection('users') 

    //run use effect to get using the id on parameter when component mounts
    useEffect(() => {
        //define a function to fetch with above given id
        const getJobById = async () => {
            try{

                window.scrollTo(0, 0)

                const pipeline = [
                    {
                        '$match': {
                            _id : BSON.ObjectID(jobId)
                        }
                    }, {
                        '$lookup': {
                            'from': 'users', 
                            'localField': 'hash', 
                            'foreignField': 'hash', 
                            'as': 'results'
                        }
                    }, {
                        '$unwind': {
                            'path': '$results'
                        }
                    }, {
                        '$project': {
                            'applicants': 1,
                            numberOfApplicants: { $cond: { if: { $isArray: "$applicants" }, then: { $size: "$applicants" }, else: 0} },
                            'hash': 1,
                            'contacts': 1, 
                            contactAttempts: { $cond: { if: { $isArray: "$contacts" }, then: { $size: "$contacts" }, else: 0} },
                            'description': 1,                    
                            'licenses': 1, 
                            'mobility': 1, 
                            'title': 1, 
                            'geocode_address': 1,
                            'certifications': 1, 
                            'userID': 1, 
                            'compensation': 1, 
                            'created': 1, 
                            'city': "$results.city",
                            'zipcode': "$results.zipcode",
                            'address':"$results.address",
                            'schedule': 1, 
                            "trainer": "$results.trainer",
                            'provider': "$results.name", 
                            'alert_preferences': '$results.settings.alert_preferences'                           
                        }
                    }
                ]
                //
                
                const dbJob = await collection?.aggregate(pipeline)

                if(dbJob.length > 0){
                    //set job data
                    setJob(dbJob[0])    

                    //get 3 jobs similar to the above
                    const similarJobResults = await collection.aggregate([
                        {
                            "$search": {
                              "index": "jobsIndex",
                              "moreLikeThis": {
                                "like": [
                                  {
                                    "licenses": dbJob[0].licenses,
                                    "schedule": dbJob[0].schedule,
                                    "geocode_address": dbJob[0].geocode_address                                    
                                  }
                                ]
                              }
                            }
                        },
                        {
                            $match: {draft: false}
                        },
                        {
                            $project : {
                                title: 1,
                                draft: 1,
                                "contacts.city": 1,
                                "contacts.address": 1,
                                "contacts.zipcode":1
                            }
                        },                        
                        {
                            $limit: 3
                        }
                    ])       

                    //set similar results
                    setSimilarJobs(similarJobResults)
                    
                    console.log('similar jobs results --> ', similarJobResults)
                } 
                //set loading to false
                setLoading(false)
                 
            } catch (err){
                console.log('err ', err)
                setLoading(false)
                setError(err)
            }                      
        }
       // console.log('job in get job', job)
       //call function
        getJobById()       
             
    }, [jobId])   

    
  /**
   * This function notifies the provider that a caregiver attempted to contact them
   * @param - none
   * @returns - none
   */
  
  const notifyProvider = async (payload) => {
    try{

        //check
        await collection?.updateOne({ _id: BSON.ObjectID(jobId)}, 
                    { 
                        $push: 
                        { 
                            contacts : {
                                $each : [{ 
                                    date: new Date(),
                                    caregiver_id: app.currentUser.id,
                                    caregiver_name : payload.name,
                                    method: payload.method
                                }],
                                $position: 0
                            }
                        }
                    }
                )
    }catch(err){
      console.log('err notifying caregiver ', err)
    }
  }

    /**
     * This function lets a caregiver submit an application to a posting if the caregiver has a job
     * This function updates a job document's applicant's array 
     * Else it routes the caregiver to create a cv
     * @param - none
     * @returns - none
     */

     const applyJob = async () => {
        //check if the user is anonymous, ie, not logged in
        //if user is not logged in, send user to sign up page
        if(user?.identities[0].providerType === 'anon-user') {
            alert('Please register or sign in to apply for the job.')
            return history.push(`/signup`)
        }

        //check if the user is a provider and if so, send user to provider home page
        if(user?.customData?.role === 'provider') return history.push(`/provider/candidates/all`)    
         
        //if results, indicate to the caregiver the jobs they have applied for and to employer
        if(userData?.complete){          
            
            const payload = {
                name: `${userData?.fname} - ${userData?.lname}`,
                availability: userData?.availability,
                licenses: userData?.licenses,
                jobId, 
                provider: job?.provider,
                title: job.title,
            }

            //
            const {data, message} = await user?.callFunction('web_apply_for_job', payload)  
            
            const tagManagerArgs = {
                dataLayer: {
                    event: `caregiver_submit_resume`,
                    added: new Date(),              
                    title: job.title,                             
                    userId: user?.id     
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)


            //get caregiver's cv  
           // const { data } = await user?.callFunction('web_applications_read', user?.id)         
            //set it in the state
            setUserData(data)

            alert(message)

            history.push('/vitae/jobs/all')

            if(job?.alert_preferences?.includes('SMS/Text message')){
                //construct sms data 
                const sms_data = {
                    body: `${userData?.fname} - ${userData.lname} has submitted an application for your ${job.title} opening.  Please log into Kinscare to see ${userData?.name} application and contact information.`,
                    to: job?.contacts?.tel
                }
                
                await user.callFunction('twilio_sms_user', sms_data)
            }
        } else {
            alert('Before submitting an application, you must create a resume.')
            history.push(`/vitae/create`)
        }
    }  
    
    /**
     * This function initiates call from candidate to employer
     * @param {String} - employer's tel number
     * @returns - no return
     */

    const callProvider = async () => {

        alert(`We are initiating a call to ${userData.name}.  Stay close to your phone.`)
        const recipientDetails = {
            "to": job.contacts.tel,
            "name": `${userData.fname} ${userData.lname}`,
            "recipient": `${job.provider}`,
            "subject": `your ${job.title} opening on Kinscare.`
        }

        await user.callFunction('twilio_attempt_web_call', recipientDetails )

        const tagManagerArgs = {
            dataLayer: {
                event: `user_iniatiates_phone_call`,             
                role: user?.customData?.role,                             
                userId: user?.id     
            }           
        }

        TagManager.dataLayer(tagManagerArgs)

        const contactDetails = {
            method: 'Call',
            name: userData.name
        }

        notifyProvider(contactDetails)
    }

    /**
   * This function lets a provider send a caregiver an email to express interest in hiring them
   * @params - none
   * @return - none
   */

    const emailProvider = async () => {

        alert(`Your email to ${userData.name} has been sent.  Sign in later to get their response.`)

        //create email recipient details
        const recipientData = {
            to: job.contacts.email,
            //from: userData.settings.email,
            sender: userData.name,
            recepient: job.provider,
            title: job.title
        }

        await user.callFunction('mandrill_email_provider', recipientData)

        const tagManagerArgs = {
            dataLayer: {
                event: `user_sends_email`,             
                role: user?.customData?.role,                             
                userId: user?.id     
            }           
        }

        TagManager.dataLayer(tagManagerArgs)

        const contactDetails = {
            method: 'Email',
            name: userData.name
        }

        notifyProvider(contactDetails)
    }

     /**
   * This function lets a provider contact a caregiver using text
   * @param - none
   * @returns - none
   */

    const textProvider = async () => {   
        
        alert(`Your text message to ${userData.name} has been sent.  Sign in later to get their response.`)
        //ensure that the user wants to be contacted   
        const sms_data = {
            body: `I am ${userData.name} and I would love to talk to you about this job opening: ${job.tite}.  I have left my contact information on your Kinscare.org account.  Log in to contact me at your convenience.`,
            to: job.contacts.tel      
        }
        
        await user.callFunction('twilio_sms_user', sms_data)

        const tagManagerArgs = {
            dataLayer: {
                event: `user_sends_text`,             
                role : user?.customData?.role,                             
                userId: user?.id     
            }           
        }

        TagManager.dataLayer(tagManagerArgs)

        const contactDetails = {
            method: 'SMS',
            name: userData.name
        }

        notifyProvider(contactDetails)
        
    }  

    /**
     * This function adds a job as a favorite of the caregiver
     * @param - none
     * @returns - none
     */

    const saveJobAsFavorite = async () => {        

        //payload object that containts job id, job title and type of remove
        const payload = { jobId, title: job.title, licenses: job.licenses, schedule: job.schedule, type: "add"}
        //remove job from caregiver's application from favorite's array
        await user?.callFunction('web_add_or_remove_job_as_favorite', payload)   
        //send data to google tag manager
        const tagManagerArgs = {
            dataLayer: {
                event: `star_as_favorite`,      
                role: user?.customData?.role,   
                starred: 'job',                                 
                userId: user?.id     
            }           
        }

        TagManager.dataLayer(tagManagerArgs)
        //set it in the state
        setUserData(await users.findOne({ userID: user?.id}))      
    }

    /**
     * This function removes a job as a favorite of the caregiver
     * @param - none
     * @returns - none
     */

    const removeJobAsFavorite = async () => {
        //payload object that containts job id, job title and type of remove
        const payload = { jobId, title: job.title, licenses: job.licenses, schedule: job.schedule, type: "remove"}
        //remove job from caregiver's application from favorite's array
        await user?.callFunction('web_add_or_remove_job_as_favorite', payload)   
        //send data to google tag manager
        const tagManagerArgs = {
            dataLayer: {
                event: `caregiver_unstar_job`,             
                //title: job.title,                             
                userId: user?.id     
            }           
        }

        TagManager.dataLayer(tagManagerArgs)
        //set it in the state
        setUserData(await users.findOne({ userID: user?.id}))        
    }

    return( 
        <div>
            { (user?.identities[0]?.providerType !== 'anon-user' && 
                user?.customData?.role === 'caregiver') ?
            
                (<ViewJobOpeningUI 
                    app={app} 
                    applyJob={applyJob} 
                    callProvider={callProvider} 
                    error={error} 
                    job={job}
                    emailProvider={emailProvider}
                    textProvider={textProvider}
                    removeJobAsFavorite={removeJobAsFavorite}
                    saveJobAsFavorite={saveJobAsFavorite}                     
                    userData={userData}
                    user={user}
                    similarJobs = {similarJobs} 
                /> ) :
                (<ViewPublicJobOpeningUI 
                    app={app}                
                    applyJob={applyJob}                    
                    error={error} 
                    job={job}
                    loading={loading}         
                    similarJobs = {similarJobs}        
                /> ) 
            }
        </div>
    )
}

export default ViewJobOpeningContainer 