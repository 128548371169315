import { useEffect } from "react"
import * as Realm from 'realm-web'
import { useHistory } from "react-router"
import { isAnon } from "../../../utils/isAnon"
import {
    Container,
    Divider,
    Grid,
    Message
} from "semantic-ui-react";
import TagManager from 'react-gtm-module';

function LogOut ({mongoContext: {app, user, setUserData, setClient, setUser}}) {
    const history = useHistory()   

    useEffect(() => {
        async function logout () {      
            
            if (isAnon(user)) {
                history.push('/')
            } else {

                const tagManagerArgs = {
                    dataLayer: {
                        event: `sign_out`,
                        added: new Date(),                  
                        role: `${user?.customData?.role}`,
                        userId: user?.id     
                    }           
                }

                TagManager.dataLayer(tagManagerArgs)
          
                localStorage.clear()

                await app?.currentUser?.logOut()
                //login anon user
                setUser(await app?.logIn(Realm.Credentials.anonymous()))
                //set new client
                setClient(app?.currentUser?.mongoClient('mongodb-atlas'))
                //set application to empty object
                setUserData({})  

            }
                     
        }

        logout()
    }, [ app, setUserData, setClient, setUser ])

    return (       
        <Container>
            <Grid>
            <Divider hidden section />           
                <Grid.Row>
                <Container textAlign="center">
                <Grid.Column style={{ maxWidth: 800, marginTop: 20, marginBottom: 20, marginLeft: 150 }}> 
                    <Message success size='massive'>LOGGING YOU OUT.</Message>
                </Grid.Column>                                          
                </Container>           
                </Grid.Row>       
            </Grid>
        </Container>    
    )
}

export default LogOut