import React from 'react'
import {
    Card,
    Container,
    Grid,  
    Divider,
    Segment
} from "semantic-ui-react";
import { Link } from 'react-router-dom'
import EmployerPanel from '../../../components/EmployerPanel';

const ViewResourcesUI = ({ userData, user }) => {   

    return (     
        <div>            
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid centered stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>               
                        <>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header>Training resources</Card.Header>   
                                    <Card.Description>We are collecting contact information of community instructors as a resource</Card.Description>
                                </Card.Content>
                            </Card>        

                            <Divider hidden />                           
                            
                            <Segment raised color='violet'>
                                Kevin Wanjohi <br/> 
                                Course offerings: Mobile CPR, FA, AED<br/>
                                Call/text: (302) 887-0430
                            </Segment>
                            <Segment raised color='violet'>
                                Excel CNA Training <br/>
                                Course offering: CNA, HCA, DSHS specialty courses, CPR/First Aid/AED, Nurse Delegations <br/>
                                Call/text: (206) 271-1946 <br/>
                                Website:  <Link to={{pathname:'http://www.excelcna.com/catalog'}} target="_blank">http://www.excelcna.com/catalog</Link>  <br/>
                            </Segment> 
                        </>                        
                        </Grid.Column>
                    </Grid.Row>                   
                </Grid> 
            </Container>
        </div>
    )
}

export default ViewResourcesUI
