import React from 'react'
import {
    Button,  
    Card,
    Container,
    Grid,    
    Header,
    List,    
    Message,
    Placeholder,
    Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom"

const ViewJobOpeningUI = ({ app, applyJob, error, job, loading, similarJobs }) => {  
  
    return (
        <div> 
            <style>
                {`#p-wrap {
                white-space: pre-line;
                }`}
            </style>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid >  
                    <Grid.Row centered>                                             
                        <Grid.Column width={12}>
                        {
                            error && !loading && (
                                <Message                    
                                    header='Something is not right'
                                    content='We ran into a problem fetching the job you requested it.  Log out and back in again and try again :(.'
                                />
                
                            )
                        }
                        {
                            loading && (
                                <Placeholder>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            )
                        }    
                        {
                            !loading && !error && (
                            <Grid.Row>
                                <Card color='red' raised={true} >
                                <Card.Content>
                                    <Card.Header>{job.provider}</Card.Header>
                                    <Card.Description>
                                        {job.city} | {job.zipcode} 
                                    </Card.Description>
                                    <Card.Description>
                                        {job.address} 
                                    </Card.Description>
                                </Card.Content>
                                </Card>                                        
                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>JOB TITLE: {job.title}</Card.Header>
                                    </Card.Content>    

                                    <Card.Content>
                                        <Card.Header>Job schedule</Card.Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { job?.schedule?.map((schedule, index)=> (
                                                    <List.Item key={index}>{schedule}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                    </Card.Content>                                

                                    <Card.Content>
                                        <Card.Header>Required license(s)</Card.Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { job.licenses.map((license, index)=> (
                                                    <List.Item key={index}>{license}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                        { job.trainer === 'yes' && <Card.Description>
                                            <span style={{color:"brown", fontSize: "18px"}}><b>In house training and/or training support is available.</b></span>
                                        </Card.Description>}
                                    </Card.Content>
                                    <Card.Content>
                                        <Card.Header>Job location</Card.Header>
                                    
                                        <Card.Description> {job.city}, {job.zipcode} </Card.Description>
                                    </Card.Content>    
                                
                                    <Card.Content>
                                        <Card.Header>Required certifications</Card.Header>
                                        <Card.Description><p id='p-wrap'>{job.certifications}</p></Card.Description>  
                                    </Card.Content>  
                                    

                                    <Card.Content>
                                        <Card.Header color="blue">Job description</Card.Header>
                                        <Card.Description><p id='p-wrap'>{job.description}</p></Card.Description>  
                                    </Card.Content>
                                    
                                {
                                    job.mobility==='car_needed' && 
                                    (<Card.Content>
                                        <Card.Header>Car requirement</Card.Header>  
                                        <Card.Description>
                                            Requires driving
                                        </Card.Description>                                  
                                    </Card.Content>) 
                                }  
                                {
                                    job.mobility==='no_car_needed' && 
                                    (<Card.Content>
                                        <Card.Header>Car requirement</Card.Header> 
                                        <Card.Description>
                                            Doesn't require driving
                                        </Card.Description>                                      
                                    </Card.Content>) 
                                }                                         
                                    
                                    <Card.Content>                                                                          
                                
                                    { !job?.applicants?.some(i => i.userID ===  app.currentUser.id) && (
                                        <Button                                
                                            content='Apply'
                                            primary
                                            id="user_apply_for_public_job"
                                            floated="right"
                                            size='big'
                                            onClick={ applyJob }
                                        />
                                    )}
                                    </Card.Content>
                                </Card> 
                            
                            </Grid.Row>                           
                            )
                        }

                        <br/>

                        <Segment>
                    <Header as='h3'>
                        Similar jobs
                    </Header> 
                        
                        {
                            similarJobs.length > 0 && 
                                <List horizontal divided relaxed>
                                {
                                    similarJobs.map((similarJob, index) => (
                                        <List.Item key={index} >                       
                                            <List.Content >
                                            <Link to={`/job/view/${similarJob?._id}`} ><List.Header as="a">{similarJob.title}</List.Header></Link>
                                                <List.Description >{similarJob.contacts.city} {similarJob.contacts.zipcode} </List.Description>
                                            </List.Content>
                                        </List.Item>                                       
                                    ))
                                } 
                               </List>
                        }

                        </Segment>

                        </Grid.Column>        
                                      
                    </Grid.Row >  
                    
                    
                               
                </Grid>
            </Container>            
        </div>
    )
}

export default ViewJobOpeningUI
