import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import AccountSubscription from '../../../layouts/Job/payment/Account';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const Account = ({ mongoContext: {user, userData}}) => {
  const [subscription, setSubscription] = useState([]);

  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      //refresh user data
      await user.refreshCustomData()

      if(user?.customData?.subscriptionId){
        //get users subscriptions and user data
        const { subscriptions, paymentMethods } = await user?.callFunction("stripe_get_provider_payment_methods", 
        { 
          customer_id: user?.customData?.customer_id,
          subscriptionId: user?.customData?.subscriptionId
        })

        setSubscription(subscriptions)
      } else setSubscription({})
      
    }
      fetchData();
    }, [])

    if (!subscription) {
      return '';
    }

    /**
     * This function either upgrades a provider plan from basic to premium or pauses a provider's plan
     * @param {String} code - code can be either upgrade or pause
     */
   const updatePlan = async(code) => {
    //refresh user customData
    await user?.refreshCustomData()
    //call the function to either update or pause subscription
    const { message, updatedSubscription, url } = await user?.callFunction('stripe_subscribe_provider', {
        code,
        plan: user?.customData?.plan,
        customer_id: user?.customData?.customer_id
    })
    
    alert(message)
    await user?.refreshCustomData()

    
    //tag manager for 
    const tagManagerArgs = {
      dataLayer: {
          event: code === 'upgrade' ? `provider_upgrade_to_premium_plan` : `provider_pause_subscription`,       
          added: new Date(),
          plan: user?.customData?.plan,
          userId: user?.id,                                        
      }           
    }

    TagManager.dataLayer(tagManagerArgs)

    setSubscription(updatedSubscription)    
    //route the provider back to the account
    history.push(url)
  }

  return <AccountSubscription subscription={ subscription } userData={userData} user={user} updatePlan={updatePlan}/>
    
}

export default withRouter(Account)