import React from 'react'
import CaregiverPanel from '../../../components/CaregiverPanel'
import {
    Card,
    Button,  
    Container,
    Grid,  
    List,
    Icon, 
    Divider
} from "semantic-ui-react";
import { Link } from 'react-router-dom'

//const ViewApplication = ({ application : { application, user_email, user_tel, onClick, loading, error } }) => {    
const ViewResume = ( { mongoContext: { userData, user } } ) => {      
     //console.log('application in view resume ui ', application, 'and user ', user)
   
    return (    
        
        <div> 
            <style>
            {`#p-wrap {
            white-space: pre-line;
            }`}
            </style>          

            { userData && 
            (<Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid centered stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <CaregiverPanel user={user} />
                        </Grid.Column>
                        
                        <Grid.Column width={12}>                       
                            
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header>Name: {userData?.candidate_name}</Card.Header>
                                </Card.Content>

                                <Card.Content>
                                    <Card.Header>Contact info</Card.Header>
                                    <Card.Description>
                                       <b>Tel: </b> { userData?.settings?.tel } <br/>
                                        <b>Email:</b> { userData?.settings?.email }
                                    </Card.Description>
                                </Card.Content>

                                <Card.Content>
                                    <Card.Header>Licenses</Card.Header> 
                                    <Card.Description>
                                        <List divided horizontal relaxed>
                                            { userData?.licenses.map((license, index)=> (
                                                <List.Item key={index}>{license}</List.Item>
                                            ))}
                                        </List>                                        
                                    </Card.Description>
                                </Card.Content>
                               
                                <Card.Content>
                                    <Card.Header>Certifications</Card.Header>
                                    <Card.Description ><p id="p-wrap">{userData?.certifications}</p></Card.Description>  
                                </Card.Content>                           
                                    
                                <Card.Content>
                                    <Card.Header>Resides</Card.Header>
                                    <Card.Description> {userData?.city}, {userData?.zipcode} </Card.Description>
                                </Card.Content>       
                               
                                <Card.Content>
                                    <Card.Header>Availability</Card.Header> 
                                    <Card.Description>
                                        <List divided horizontal relaxed>
                                            { userData?.availability.map((schedule, index)=> (
                                                <List.Item key={index}>{schedule}</List.Item>
                                            ))}
                                        </List>                                        
                                    </Card.Description>
                                </Card.Content>
                                {
                                    userData?.mobility==='has_car' && 
                                    (<Card.Content>
                                        <Card.Header>Mobile </Card.Header>  
                                        <Card.Description>
                                            Has a car
                                        </Card.Description>                                  
                                    </Card.Content>) 
                                }  
                                {
                                    userData?.mobility==='no_car' && 
                                    (<Card.Content>
                                        <Card.Header>Not mobile</Card.Header> 
                                        <Card.Description>
                                            Has no car
                                        </Card.Description>                                      
                                    </Card.Content>) 
                                } 

                            </Card>
                        
                            <Divider hidden />

                            <Button as={Link} to="/vitae/update" primary floated="right" size='big' icon>
                                <Icon name="edit"></Icon>
                                Update
                            </Button>

                        </Grid.Column>
                    </Grid.Row>                   
                </Grid> 
            </Container>)}
        </div>
    )
}

export default ViewResume
