import React from 'react'
import EmployerPanel from '../../../components/EmployerPanel'
import { Redirect,  withRouter } from 'react-router-dom';
import {     
    Button, 
    Container,   
    Grid,
    Icon,
    Header,
    Message,
    Table
} from "semantic-ui-react";
import TagManager from 'react-gtm-module';

const SubscriptionPlansUI = ({ mongoContext: { user } }) => {  
    //initiate state   
    const [ subscriptionData, setSubscriptionData ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);
    //create provider's monthly subscription    
    const createSubscription = async (plan) => {
        await user?.refreshCustomData()

        setLoading(true)
        const { clientSecret, subscriptionId } = await user.callFunction('stripe_subscribe_provider', 
                                                    {
                                                        customer_id: user?.customData?.customer_id, 
                                                        plan,
                                                        code: 'initial'
                                                    })
        //tag manager for selecting plan
        const tagManagerArgs = {
            dataLayer: {
                event:`provider_add_${plan}_to_cart`, 
                added: new Date(),
                userId: user?.id                                          
            }           
        }
  
        TagManager.dataLayer(tagManagerArgs)
        
        localStorage.setItem("subscriptionId", subscriptionId)
        localStorage.setItem("plan", plan)
       // localStorage.setItem("clientSecret", clientSecret)
        // localStorage.setItem('clientSecret', JSON.stringify(clientSecret))
        setSubscriptionData({ subscriptionId, clientSecret });

        setLoading(false)
    }


    if(subscriptionData) {
        return <Redirect to={{
          pathname: '/provider/plans/subscribe',
          state: subscriptionData
        }} />
    }

    return (     
        <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
            <Grid  stackable columns={2}>  
                
                <Grid.Row>
                    <Grid.Column width={4}>
                        <EmployerPanel user={user}/>
                    </Grid.Column>
                    <Grid.Column width={12}>
                    {
                        user?.customData?.trial ? 
                        <p>
                        To persuade high school and college students, stay at home parents with spare time, former caregivers and service workers in hotel and housekeeping, fast food and restaurant industries, etc., to become professional caregivers part time or full time, we need to advertise, educate and provide them with community resources to aid their transition.  That is why we are asking you to partner with us in these efforts and subscribe to our "Premium provider" plan.             
                        </p>                        
                        :  
                        
                        <Message warning>
                        <Message.Header class="subscribe_now">You 15 days trial period is over.  Please consider subscribing to the Premium or the Basic Provider plan to continue recruiting caregivers. </Message.Header>
                        </Message>
                    }
                   
                    <Header as='h4'>
                    Your low monthly subscription fee will let us focus exclusively on finding caregiver(s) near you.  No one knows better than you and your patients the frustration of being short on a caregiver.
                    </Header>
                    <Table color="blue" size="large">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell/>
                                <Table.HeaderCell>Basic Provider Recruiting Plan</Table.HeaderCell>
                                <Table.HeaderCell>Premium Provider Recruiting Plan</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Monthly fee </Table.Cell>
                                <Table.Cell>$ 19.99 </Table.Cell> 
                                <Table.Cell>$ 69.99 </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>View and contact caregivers who have applied for your opening </Table.Cell>
                                <Table.Cell><Icon color='blue' name='checkmark'></Icon></Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell> Search aides/caregivers by licenses and shift availability </Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon>  </Table.Cell> 
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Job post fee </Table.Cell>
                                <Table.Cell>$ 39.99</Table.Cell>
                                <Table.Cell>$ 17.99</Table.Cell>
                            </Table.Row>                            
                            
                            <Table.Row>
                                <Table.Cell>Your job post sends email notification to caregivers </Table.Cell>
                                <Table.Cell><Icon color='red' name="times"></Icon>  </Table.Cell> 
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>                            
                            <Table.Row>
                                <Table.Cell>Get recommendation of potential caregivers </Table.Cell>
                                <Table.Cell><Icon color='red' name='times'></Icon></Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Contact caregivers via email, SMS/text, and phone call</Table.Cell>
                                <Table.Cell><Icon color='red' name='times'></Icon></Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell/>
                                    <Table.HeaderCell>
                                        <Button 
                                            onClick={() => createSubscription('basic')} 
                                            id="provider_subscribe_to_basic_plan"
                                            loading={loading}
                                        >
                                            Subscribe
                                        </Button>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Button 
                                            onClick={() => createSubscription('premium')} 
                                            color='red'
                                            id="provider_subscribe_to_premium_plan"
                                            loading={loading}
                                        >
                                            Subscribe
                                        </Button>
                                    </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>                                                                    
                    </Grid.Column>
                </Grid.Row>                  
            </Grid>
        </Container>
    )
}

export default withRouter(SubscriptionPlansUI)