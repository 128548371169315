import React from 'react'
import {     
    Button, 
    Container,   
    Grid,
    Icon,
    Header,
    Table
} from "semantic-ui-react";
import { Link } from "react-router-dom";
const FooterSubscriptionPlansUI = () => {         

    return (     
        <Container style={{ maxWidth: 650, margin: 50 }}>
            <Grid centered stackable>  
                
                <Grid.Row>
                    
                    <Grid.Column width={12}>
                    <p>
                    To persuade high school and college students, stay at home parents with spare time, former caregivers and service workers in hotel and housekeeping, fast food and restaurant industries, etc., to become professional caregivers, we need to advertise, educate and provide them with community resources to aid their transition.  That is why we are asking you to partner with us in these efforts and subscribe to our "Premium provider" plan.  
                      
                    </p>
                    <Header as='h4'>
                    Your low monthly subscription price will let us focus exclusively on finding caregiver(s) near you.  No one knows better than you and your patients the frustration of being short on caregivers.
                    </Header>

                    <p> Try Kinscare for 15 days for FREE to find caregivers and nurse aides and decide whether it makes a good fit.</p>
                    <Table color="blue" size="large">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell/>
                                <Table.HeaderCell>Basic Provider Recruiting Plan</Table.HeaderCell>
                                <Table.HeaderCell>Premium Provider Recruiting Plan</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Monthly fee </Table.Cell>
                                <Table.Cell>$ 19.99 </Table.Cell> 
                                <Table.Cell>$ 69.99 </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>View and contact caregivers who have applied for your opening </Table.Cell>
                                <Table.Cell><Icon color='blue' name='checkmark'></Icon></Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell> Search aides/caregivers by licenses and shift availability </Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon>  </Table.Cell> 
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Job post fee </Table.Cell>
                                <Table.Cell>$ 39.99</Table.Cell>
                                <Table.Cell>$ 17.99</Table.Cell>
                            </Table.Row>                            
                            
                            <Table.Row>
                                <Table.Cell>Your job post sends email notification to caregivers </Table.Cell>
                                <Table.Cell><Icon color='red' name="times"></Icon>  </Table.Cell> 
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>                            
                            <Table.Row>
                                <Table.Cell>Get recommendation of potential caregivers </Table.Cell>
                                <Table.Cell><Icon color='red' name='times'></Icon></Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Contact caregivers via email, SMS/text, and phone call</Table.Cell>
                                <Table.Cell><Icon color='red' name='times'></Icon></Table.Cell>
                                <Table.Cell><Icon color='blue' name="checkmark"></Icon></Table.Cell>
                            </Table.Row>
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                           
                             <Table.HeaderCell/> 
                                     <Table.HeaderCell>
                                        <Button as={Link} to="/signup" color='red'>
                                        <Icon name="sign-in"></Icon>
                                            Start your trial
                                        </Button>
                                    </Table.HeaderCell> 
                                <Table.HeaderCell>
                                        <Button as={Link} to="/signup" 
                                            color='red'
                                            id="provider_subscribe_to_premium_plan"
                                        >
                                        <Icon name="sign-in"></Icon>
                                            Start your trial
                                        </Button>
                                    </Table.HeaderCell> 
                            </Table.Row>
                        </Table.Footer>
                    </Table>                                                                    
                    </Grid.Column>
                </Grid.Row>                  
            </Grid>
        </Container>
    )
}

export default FooterSubscriptionPlansUI