import React from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const Home = () => {
    return (
      <Container style={{ width: 1000, marginLeft: 60 }}>
        <Grid >
          <Divider hidden section />
            <Divider hidden section />
            <Grid.Row columns={1} stackable fluid>
              <Container >
                <Header as="h1" size="huge">
                  Welcome to Kinscare
                </Header>
                <p>                
                  Kinscare is a new digital registry of caregivers and care providers.  Our goal is to match caregivers and providers as efficiently as possible by allowing providers and caregivers to search, sort and contact each other using popular methods such as SMS text, Whatsapp, phone calls.  We recruit caregivers already trained and working in the healthcare industry and workers from customer facing industries such as food and restaurant, hospitality and hotel, and customer support.    
                </p>
              </Container>
              <br/><br/>
              <Container textAlign="center" style={{ width: 500, marginTop: 20}}>
              <Button color="green" size="massive" as={Link} id='home_sign_up' to="/signup">
                  Get started today
                </Button>
              </Container>
            </Grid.Row>
            
            <Grid.Row columns={2}>
              
              <Grid.Column fluid stackable>
                <Container textAlign='center'>
                  <Header size="huge" as="h1">
                    Providers
                  </Header>
                </Container>
                <br/>
                <p>
                  Kinscare is easy to use and is focused on <b>matching you with nurse aides, companions and caregivers</b>. Kinscare lets you search for caregivers looking for live-in, on call, weekends, full or part time jobs and contact them.  You can also post a job opening and interested caregivers can apply for it.  To start your search for caregivers, click on the sign up button below.
                </p>
                <Container textAlign='center'>
                  <Button size="small" primary  as={Link} id='provider_home_sign_up' to="/signup">
                    Sign up now&raquo;
                  </Button>
                </Container>
              </Grid.Column>
              <Grid.Column fluid>
              <Container textAlign='center'>
                  <Header size="huge" as="h1">
                    Caregivers
                  </Header>
                </Container>
                <br></br>
                <p>
                Kinscare is easy to use and is focused on <b>matching you with providers such as hospitals, clinics, home care agencies, SNFs, ALHs and adult family homes</b>. Kinscare lets you search for live-in, on call, weekends, full or part time jobs and apply for them.  Kinscare also lets you initiate contact with local employers.  To start your job search, click on the sign up button below.  
                </p>
                <Container textAlign='center'>
                  <Button size="small" primary  as={Link} id='caregiver_home_sign_up' to="/signup">
                    Sign up now&raquo;
                  </Button>
                </Container>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden section />
        </Grid>
      </Container>
    )
}

export default Home
