/**
 * AUTHENTICATED ROUTES FOR PROVIDER 
 * Providers employ caregivers
 */
//containers for provider's job SCRUD
import ProviderCreateJobPostFormContainer from '../containers/Job/create/posting'
import ProviderUpdateJobPostFormContainer from '../containers/Job/update/posting'
import ProviderRepostJobFormContainer from '../containers/Job/update/reposting'
import ProviderViewJobPostContainer from '../containers/Job/read/posting'
import ProviderViewJobsContainer from '../containers/Job/read/postings'
import ProviderPreviewJobContainer from '../containers/Job/read/preview'
//containers for settings
import ProviderUpdateSettingsContainer from '../containers/Job/update/settings'
import ProviderReadSettingsContainer from '../containers/Job/read/settings'
//containers for searching and viewing caregivers
import ProviderCandidateSearchContainer from '../containers/Job/search'
import ProviderReadCaregiverResumerContainer from '../containers/Job/read/candidate'
//view community resources
import ProviderViewResourcesContainer from '../containers/Job/read/resources.js'
//for viewings, subscribing and cancellin plan and viewing account
import ProviderPaymentContainer from '../containers/Job/payment'
//import ProviderPostPaymentContainer from '../containers/Job/payment/post'
import ProviderAccountContainer from '../containers/Job/payment/account'
import ProviderSubscriptionPlansContainer from '../containers/Job/read/plans'
/**
 * AUTHENTICATED ROUTES FOR CAREGIVER - JOB SEEKER
 * Caregivers are hired by providers
 */
//Authenticated 
//views for authenticated caregiver
import CaregiverUpdateApplicationContainer from '../containers/Application/update/application'
import CaregiverCreateApplicationContainer from '../containers/Application/create/application'
import CaregiverViewJobOpening from '../containers/Application/read/job'
import CaregiverViewResume from '../containers/Application/read/application'
import CaregiverSearchListings from '../containers/Application/search/results'
import CaregiverViewResourcesContainer from '../layouts/Application/read/ViewResourcesUI.js'
import PanelUI from '../layouts/Application/PanelUI'
/**
 * ALL THE ROUTES BELOW DO NOT REQUIRE USER AUTHENTICATION
 * 
 */

import Home from '../components/Home'
import Terms from '../components/Terms'
import Privacy from '../components/Privacy'
import About from '../components/About'
import JobsContainer from '../containers/Application/search'
import PublicJobsContainer from '../containers/Application/read/job'
import SignUpContainer from '../containers/User/SignUp/signUp'
import FbSignUpContainer from '../containers/User/SignUp/fbSignUp'
import SignInContainer from '../containers/User/SignIn/signIn'
import SignOutContainer from '../containers/User/LogOut'
import SelectRoleContainer from '../containers/User/SignUp/selectRole'
import OathRedirectContainer from '../containers/User/oathRedirect'
import FooterSubscriptionPlansUI from '../layouts/Job/read/ViewPublicSubscriberPlans'
import PublicUserAlertsUI from '../layouts/Job/read/ViewTextSMS'
import FooterFindJobsUI from '../layouts/Application/read/ViewFindJobs'
import FooterFindCaregiversUI from '../layouts/Job/read/ViewFindCaregivers'
/**
 * Twilio routes 
 * User initiates service by texting to our Twilio #
 * The twilioDetailsContainer route asks caregiver for alert preferences, license and availability
 * The twilioDetailsContainer route asks provider their type of settings, alert preferences, and if they offer in house training
 */
import TwilioDetailsContainer from '../containers/User/twilioDetails'
import TwilioSignUpContainer from '../containers/User/twilioSignUp'
import ResetPasswordFormContainer from '../containers/User/Reset/passwordForm'
import ResetEmailContainer from '../containers/User/Reset/emailForm'
import ResetPasswordConfirmationContainer from '../containers/User/Reset/confirmation'


const routes = [
   
    /**
    * ROUTES FOR THE PROVIDER 
    */ 

    //gets view of paying for job post
    {
        path: '/provider/payment/posting/:jobId',
        component: ProviderPaymentContainer,
        title: 'Pay for job posting',
        needsAuth: true        
    },
    //gets view of job post preview page
    {
        path: '/provider/payment/preview/:jobId',
        component: ProviderPreviewJobContainer,
        title: 'Preview job post',
        needsAuth: true
    },

    //gets a view of provider's account details - card stored for use and service
    {
        path: '/provider/plans/account',
        component: ProviderAccountContainer,
        title: 'View account status',
        needsAuth: true
    }, 
    //gets a view of provider's subscription plans
    {
        path: '/provider/plans/view',
        component: ProviderSubscriptionPlansContainer,
        title: 'View subscription plans',
        needsAuth: true
    },
    //gets a component stripe element to add provider's cards
    {
        path: '/provider/plans/subscribe',
        component: ProviderPaymentContainer,
        title: 'Add payment',
        needsAuth: true
    },
     //gets the form for provider to add settings data
     {
        path: '/provider/settings/form',
        component: ProviderUpdateSettingsContainer,
        title: 'Update employer settings',
        needsAuth: true
    },
    //gets a view of settings for provider to view settings data
    {
        path: '/provider/settings/view',
        component: ProviderReadSettingsContainer,
        title: 'View job opening',
        needsAuth: true,
    },

     //gets a provider's resources page
    {
        path: '/provider/read/resources',
        component: ProviderViewResourcesContainer,
        title: 'View resources',        
        needsAuth: true
    },
    //gets the view for provider to view caregiver's resume 
    {
        path: `/provider/view/candidate/:candidateId`,
        component: ProviderReadCaregiverResumerContainer,
        title: 'Review caregiver',
        needsAuth: true
    },
    //gets the list of candidates for the provider to view
    {
        path: '/provider/candidates/:type',
        component: ProviderCandidateSearchContainer,
        title: 'Find candidates',
        needsAuth: true
    },
    //gets the form for provider to create a job post
    {
        path: '/provider/job/create',
        component: ProviderCreateJobPostFormContainer,
        title: 'Post job opening',
        needsAuth: true
    },
    //gets a prefilled form for provider to update a job post
    {
        path: '/provider/job/form/update/:jobId',
        component: ProviderUpdateJobPostFormContainer,
        title: 'Update job opening',
        needsAuth: true
    },
    //gets a prefilled form for provider to update a job post
    {
        path: '/provider/job/form/repost/:jobId',
        component: ProviderRepostJobFormContainer,
        title: 'Repost job opening',
        needsAuth: true
    },
    //gets a list of all jobs for provider to view
    {
        path: '/provider/job/read/all',
        component: ProviderViewJobsContainer,
        title: 'View job openings',
        needsAuth: true
    },
    //gets a view of a single job post for provider to view 
    {
        path: '/provider/job/view/:jobId',
        title: 'View job opening',
        component: ProviderViewJobPostContainer,
        needsAuth: true,
    },
    /**
    * ROUTES FOR THE CAREGIVER 
    */ 
    //gets a prefilled form of resume for caregiver to update
    {
        path: '/vitae/update',
        component: CaregiverUpdateApplicationContainer,
        title: 'Update application',        
        needsAuth: true
    },
    //gets a form for a caregiver to create a resume
    {
        path: '/vitae/create',
        component: CaregiverCreateApplicationContainer,
        title: 'Create application',        
        needsAuth: true
    },
    //routes 
    {
        path: '/user/current',
        component: PanelUI,
        title: 'User profile',        
        needsAuth: true
    },
    //gets the list of jobs depending - favorite, all - for a caregiver to view
    {
        path: '/vitae/jobs/:type',
        component: CaregiverSearchListings ,
        title: 'Job openings',        
        needsAuth: true
    },
    //gets a job post for a caregiver to view
    {
        path: '/vitae/view/job/:jobId',
        component: CaregiverViewJobOpening,
        title: 'Find jobs',        
        needsAuth: true
    },
    //gets a caregiver's own resume for a caregiver's to view
    {
        path: '/vitae/details',
        component: CaregiverViewResume,
        title: 'View resume',        
        needsAuth: true
    },
    //gets a caregiver's resources page
    {
        path: '/vitae/read/resources',
        component: CaregiverViewResourcesContainer,
        title: 'View resources',        
        needsAuth: true
    },
    /**
    * ROUTES FOR UNAUTHENTICATED VIEWS
    */ 

    {
        path: "/auth/:newUserId",
        component: TwilioSignUpContainer,
        title: "Sign Up",
        needsAuth: false
    },
    {
        path: "/search/:newUserId",
        component: TwilioDetailsContainer,
        title: "Add settings",
        needsAuth: false
    },
    {
        path: "/select",
        component: SelectRoleContainer,
        title: "Select role",
        needsAuth: true
    },
    {
        path: "/oauthredirect",
        component: OathRedirectContainer,
        title: "Select role",
        needsAuth: false
    },
    {
        path: "/signup",
        component: SignUpContainer,
        title: "Create account",
        needsAuth: false
    },
    {
        path: `/reset/confirmation`,
        component: ResetPasswordConfirmationContainer,
        title: "Reset password confirmation",
        needsAuth: false
    },
    {
        path: `/reset/email`,
        component: ResetEmailContainer,
        title: "Reset email",
        needsAuth: false
    },
    {
        path:`/reset/password`,
        strict: false,
        component: ResetPasswordFormContainer,
        title: "Reset password",
        needsAuth: false
    },
    {
        path: "/signin",
        component: SignInContainer,
        title: "Sign in",
        needsAuth: false
    },
    {
        path: "/signout",
        component: SignOutContainer,
        title: "Sign out",
        needsAuth: true
    },
    {
        path: `/subscribe`,
        component: FooterSubscriptionPlansUI,
        title: "Subscribe",
        needsAuth: false
    },
    {
        path: `/alerts`,
        component: PublicUserAlertsUI,
        title: "Alerts",
        needsAuth: false
    },
    {
        path: "/job/view/:jobId",
        component: PublicJobsContainer,
        title: "View job",
        needsAuth: false
    },
    {
        path: "/openings",
        component: JobsContainer,
        title: "General job openings",
        needsAuth: false
    },

    {
        path: "/authenticate/:role",
        component: FbSignUpContainer,
        title: "Create account",
        needsAuth: false
    },
   
     //get public view for providers message to sign up
    {
        path: '/recruit-caregivers',
        component: FooterFindCaregiversUI,
        title: 'Recruit caregivers',
        needsAuth: false     
    },
    //get public view for caregivers message to sign up
    {
        path: '/find-job',
        component: FooterFindJobsUI,
        title: 'Find a job',
        needsAuth: false     
    },
    {
        path: "/about",
        component: About,
        title: "About us",
        needsAuth: false
    },
    {
        path: "/privacy",
        component: Privacy,
        title: "User privacy",
        needsAuth: false
    },
    {
        path: "/terms",
        component: Terms,
        title: "User terms agreement",
        needsAuth: false
    },
    {
        path: "/",
        component: Home,
        title: "Home",
        needsAuth: false
    }
]

export default routes