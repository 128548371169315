import { useEffect, useState } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import 'semantic-ui-css/semantic.min.css'
import routes from './routes'
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom"
import MongoContext from './MongoContext'
import * as Realm from 'realm-web'
import { isAnon } from './utils/isAnon'
import TagManager from 'react-gtm-module'

function App() {
 
  const [ client, setClient ] = useState(null)
  const [ user, setUser ] = useState(null)
  const [ userData, setUserData ] = useState({})
  const [ app, setApp ] = useState(new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID }))

  useEffect(() => {
    async function init () {
      //if a user doesn't exist, create one
      if (!user) {
        console.log(`app current user in App.js `, app.currentUser)
        setUser(app?.currentUser ? app?.currentUser : await app?.logIn(Realm.Credentials.anonymous()))
      }  
      //if a client does not exist, create one
      if (!client) {
        setClient(app?.currentUser?.mongoClient('mongodb-atlas'))
      }      
      //if a user and client exist
      if(client && user?.customData?.role && !isAnon(user) ){   
        //get the data user's data  
        console.log(`user id ${user?.id}`)
        //const { data } = await user?.callFunction('web_get_user_data', user?.id)     
        //set it in the state/mongo context
        setUserData(await client?.db('kinshealth').collection('users').findOne({ userID: user?.id }))       
      }
    }

    init();
  }, [app, client, user])

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-W6D5FBN'
    }
  
    TagManager.initialize(tagManagerArgs)
  },[])
 
  function renderComponent (Component, additionalProps = {}) {
    return <MongoContext.Consumer>{(mongoContext) => <Component mongoContext={ mongoContext } {...additionalProps} />}</MongoContext.Consumer>
  }

  const RenderRoute = (route) => {

    const history = useHistory();
  
    document.title = route.title || "Kinscare";
    if (route.needsAuth && isAnon(user)) {
      history.push("/signin");
    }    

    return (      
      <Route
        path={route.path}
        exact
        render={(props) => renderComponent(route.component, props) }
      />      
    )
  };

  return (    
    <Router>
      <Header user={user} app={app} setUser={setUser} setClient={setClient} userData={userData}/>       
      <MongoContext.Provider value={{ app, client, user, userData, setApp, setUserData, setClient, setUser}}>   
      
          <Switch> 
            {routes.map((route, index) => (
              <RenderRoute {...route} key={index} />
            ))}                          
          </Switch>         
        <Footer />       
      </MongoContext.Provider>
    </Router>
  )
}

export default App;
