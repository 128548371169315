import React from 'react'
import EmployerPanel from '../../../components/EmployerPanel'
import {
    Container, Grid, List, Message, Divider, Header, Placeholder, Segment
} from "semantic-ui-react"
import { Link } from "react-router-dom"
//import CaregiverPanel from '../../../components/CaregiverPanel'

const JobPostings = ({ drafts, postings, error, loading, user }) => {
    
    return (      
        <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>                 
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <EmployerPanel user={user}/>
                    </Grid.Column>      
                    <Grid.Column width={12}>
                    { loading && (
                        <>
                            {" "}
                            <Placeholder>
                                <Placeholder.Header >
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </>
                    )}

                    { !loading && !error && postings.length === 0 && (
                        <Message content="You've no job postings to show.  Click 'Post job opening' on the left tab to post your job opening." />
                    )}

                    {error && (
                        <Message content="Oops, something went amiss!" />
                    )}

                    { !loading && !error && postings.length > 0 && (
                       <>
                            <Header as='h3'>Your job postings</Header>
                            <Segment>                    
                                <List>                          
                                    {
                                        postings.length > 0 && postings.map((posting, index) => (
                                        <>
                                        <List.Item key={index}>
                                            <List.Content>
                                                <Link to={`/provider/job/view/${posting._id}`} ><Header as="h4" color="blue">{ posting.title}</Header></Link>
                                                {posting?.applicants?.length > 0 && <List.Description>Number of applicants: { posting.applicants.length }</List.Description>}  
                                            </List.Content>                                            
                                        </List.Item>
                                         <Divider/>  
                                        </>
                                        ))
                                    }                            
                                </List>
                            </Segment>
                        </>
                    )}

                    { !loading && !error && drafts.length > 0  && (
                       <>

                            <Header as='h3'>Job posting drafts - not seen by caregivers</Header>
                            
                            <Segment>                    
                                <List>                          
                                    {
                                        drafts.length > 0 && drafts.map((draft, index) => (
                                        
                                        <List.Item key={index}>
                                            <List.Content>
                                                <Link to={`/provider/job/view/${draft._id}`} ><Header as="h4" color="blue">{ draft.title}</Header></Link>
                                                    
                                                    <Divider/> 
                                            </List.Content>
                                        </List.Item>
                                        ))
                                    }                            
                                </List>
                            </Segment>
                       </> 
                    )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>            
         </Container>         
    )
}

export default JobPostings
