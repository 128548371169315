import React from 'react'
import ResetPasswordFormUI from '../../../layouts/User/Reset/ResetPasswordsFormUI'
import useForm from './useForm'


const ResetPasswordFormContainer = ({ mongoContext }) => {
  console.log('we are getting in reset password form container')
  return (<ResetPasswordFormUI form={ useForm() } mongoContext={mongoContext} />)
}

export default ResetPasswordFormContainer