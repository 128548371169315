import { useState } from 'react'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    const [ form, setForm ] = useState({
        email: '',
        tel: '',
        password: '',
        password2: '',
        role: '',
        terms_policy: false
    })      
    
    //grab user's input
    const onChange = (e, { name, value }) => {

        console.log('name ', name, 'value ', value)
        console.log('registered form on change ', registerFormValid)

        if(name === 'terms_policy') value  = !form.terms_policy      
       
        console.log('after name ', name, 'after value ', value)

        setForm( state => ({
            ...state,
            
            [name]: value
        }))
    };
    //make sure confirm password matches password
    const passwordCheck = () => {
        return !(form.password === form.password2)      
    }   
    
    const tosCheck = () => {
        return !(form.terms_policy)      
    }   

    //make sure that user checks terms of service

    //if password, password2 and email are filled out,
    //it returns true and form can be submitted
    const registerFormValid = 
        !(form.password2 === form.password) ||  
        !form.password?.length  ||
        !form.password2?.length ||
        !form.tel?.length ||
        !form.email?.length ||
        !form.terms_policy;

        console.log('register form valid ', registerFormValid)


    return { form, registerFormValid, onChange, tosCheck, passwordCheck }
}