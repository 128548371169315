import React from 'react';
import {
    Card,
    Button,  
    List,
    Message,
    Icon,
    Container,
    Grid,
    Header,
    Segment,
    Placeholder
} from "semantic-ui-react";
import { Link } from "react-router-dom"
import EmployerPanel from '../../../components/EmployerPanel';

const ViewCandidateResumeUI = ({ callCaregiver, 
    candidate, 
    emailCaregiver, 
    error, 
    favorite, 
    loading, 
    removeCandidateAsFavorite, 
    saveCandidateAsFavorite, 
    similarCandidates,
    textCaregiver, 
    userData, 
    user }) => {    
   
    return (     
        <div> 
            <style>
                {`#p-wrap {
                    white-space: pre-line;
                }`}
            </style>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid centered stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>                

                        { loading && !error && (
                                <Placeholder>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            )
                        }        

                        { error && !loading && (
                                <Message>
                                    <Message.Header>Oops!  Something went amock!</Message.Header>
                                        <p>
                                            We couldn't fetch you the caregiver's resume.  Log out and then back in and try again :(.
                                        </p>
                                </Message>
                            )
                        }   
                        { candidate && !loading &&(
                            <>
                                <Card fluid>
                                    
                                    <div id='resume_header'></div>
                                    <Card.Content>
                                        <Card.Header>{candidate?.fname} {candidate.lname} </Card.Header>
                                    </Card.Content>    
                                   
                                                               

                                    <Card.Content>
                                        <Card.Header>Licenses</Card.Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { candidate?.licenses?.map((license, index)=> (
                                                    <List.Item key={index}>{license}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                    </Card.Content>
                                
                                    <Card.Content>
                                        <Card.Header>Certifications</Card.Header>
                                        <Card.Description><p id='p-wrap'>{candidate?.certifications}</p></Card.Description>  
                                    </Card.Content>                           
                                        
                                    <Card.Content>
                                        <Card.Header>Resides</Card.Header>
                                        <Card.Description> {candidate?.city}, {candidate?.zipcode} </Card.Description>
                                    </Card.Content>       
                                
                                    <Card.Content>
                                        <Card.Header>Availability</Card.Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { candidate?.availability?.map((schedule, index)=> (
                                                    <List.Item key={index}>{schedule}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                    </Card.Content>
                                    {
                                        candidate?.mobility==='has_car' && 
                                        (<Card.Content>
                                            <Card.Header>Mobility </Card.Header>  
                                            <Card.Description>
                                                Has a car
                                            </Card.Description>                                  
                                        </Card.Content>) 
                                    }  
                                    {
                                        candidate?.mobility==='no_car' && 
                                        (<Card.Content>
                                            <Card.Header>Not mobile</Card.Header> 
                                            <Card.Description>
                                                Has no car
                                            </Card.Description>                                      
                                        </Card.Content>) 
                                    }                        
                            
                                    { 

                                    //candidate must be saved first before they can be contacted
                               
                                        (userData?.saved_candidates?.some(element => element.userID === candidate.userID)) ? 
                                   
                                            (<Card.Content>
                                                <Grid columns='equal'>
                                                    { candidate?.settings?.alert_preferences?.some(preference => preference === 'Phone call') && (<Grid.Column>
                                                        <Button 
                                                            circular 
                                                            color='teal' 
                                                            icon='phone' 
                                                            size='medium' 
                                                            onClick={callCaregiver}
                                                            id="provider_call_caregiver"
                                                        />
                                                        <span>Click to call  </span>
                                                    </Grid.Column>) } 
                                                    
                                                    { candidate?.settings?.alert_preferences?.some(preference => preference === 'SMS/Text message') && (<Grid.Column>
                                                        <Button 
                                                            circular 
                                                            color='yellow' 
                                                            icon='comment outline' 
                                                            size='medium' 
                                                            onClick={textCaregiver} 
                                                            id="provider_text_caregiver"
                                                        />
                                                        <span>Text/SMS  </span>
                                                    </Grid.Column>) }
                                                    { candidate?.settings?.alert_preferences?.some(preference => preference === 'Email') && (<Grid.Column>
                                                        <Button 
                                                            circular 
                                                            color='google plus' 
                                                            icon='mail' 
                                                            size='medium'
                                                            id="provider_email_caregiver"
                                                            onClick={emailCaregiver}
                                                        />
                                                        Email
                                                    </Grid.Column>) }
                                                </Grid>
                                            </Card.Content>
                                            )
                                            :
                                            (
                                            <Card.Content>                                    
                                                <Message info floating>
                                                    <Message.Content> 
                                                        To contact {candidate?.name}, please save her/him first.
                                                    </Message.Content>                                    
                                                </Message>
                                            </Card.Content>
                                            )                           
                                    }

                                </Card> 
                                <Segment>
                                     {!favorite &&  
                                    <Button 
                                        id="provider_save_caregiver_as_favorite" 
                                        onClick={ saveCandidateAsFavorite } 
                                        color='red' 
                                        basic 
                                        icon
                                    >
                                    <Icon name="favorite"></Icon> 
                                        Save caregiver
                                    </Button>
                                    } 

                                   {favorite && 
                                   <Button 
                                        id="provider_unsave_caregiver_as_favorite" 
                                        onClick={ removeCandidateAsFavorite } 
                                        basic 
                                        icon
                                    >
                                    <Icon name="minus square"></Icon> 
                                        Unsave caregiver
                                    </Button>} 
                             
                                </Segment>
                            </>
                        )}        

                        <br/>                        
                        
                        {favorite && similarCandidates.length > 0 && 
                            <Segment>
                            <Header as='h3'>
                                Similar candidates
                            </Header> 
                                
                                <List horizontal divided relaxed>
                                {
                                    similarCandidates.map((similarCandidate, index) => (
                                        <List.Item key={index} >                       
                                            <List.Content >
                                            <Link to={`/provider/view/candidate/${similarCandidate.userID}`} ><List.Header as="a">{similarCandidate.fname} {similarCandidate.lname}</List.Header></Link>
                                                <List.Description >{similarCandidate.city} {similarCandidate.zipcode} </List.Description>
                                            </List.Content>
                                        </List.Item>                                       
                                    ))
                                } 
                                </List> 
                            </Segment> 
                        }                                  
    
                        </Grid.Column>
                    </Grid.Row>                   
                </Grid> 
            </Container>
        </div>
    )
}

export default ViewCandidateResumeUI
