import React from 'react'
import CaregiverPanel from '../../../components/CaregiverPanel'
import * as Realm from 'realm-web'
import {
    Button,  
    Card,
    Container,
    Grid,  
    Header, 
    Icon, 
    List,   
    Message,
    Placeholder,
    Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom"

const ViewJobOpeningUI = ({ app, user, userData, applyJob, callProvider, emailProvider, textProvider, error, job, loading, removeJobAsFavorite, saveJobAsFavorite, similarJobs }) => {  
   
    return (
        <div>    
            <style>
                {`#p-wrap {
                    white-space: pre-line;
                }`}
            </style>     
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid centered stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <CaregiverPanel user={user}/>
                        </Grid.Column>                     
                        <Grid.Column width={12}>
                        {
                            error && !loading && (
                                <Message                    
                                    header='Something is not right'
                                    content='We ran into a problem fetching the job you requested it.  Log out and back in again and try again :(.'
                                />               
                            )
                        }
                        {
                            loading && (
                                <Placeholder>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            )
                        }    
                        {
                            !loading && !error && (
                            <Grid.Row>
                                <Card color='red' raised={true} >
                                <Card.Content>
                                    <Card.Header>{ job?.provider }</Card.Header>
                                    <Card.Description> {job?.contacts?.address} </Card.Description>                                               
                                    <Card.Description> {job?.contacts?.city}, {job?.contacts?.zipcode} </Card.Description>
                                </Card.Content>
                                </Card>                                        
                                <Card fluid>
                                    <Card.Content>
                                        <Card.Header>JOB TITLE: {job?.title}</Card.Header>
                                    </Card.Content>                                    

                                    <Card.Content>
                                        <Card.Header>Required license(s)</Card.Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { job?.licenses?.map((license, index)=> (
                                                    <List.Item key={index}>{license}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                        { job?.trainer === 'yes' && <Card.Description>
                                            <span style={{color:"brown", fontSize: "18px"}}><b>In house training and/or training sponsorship is available.</b></span>
                                        </Card.Description>}
                                    </Card.Content>

                                    <Card.Content>
                                        <Card.Header color="blue">Job description</Card.Header>
                                        <Card.Description><p id='p-wrap'>{job?.description}</p></Card.Description>  
                                    </Card.Content> 
                                
                                    <Card.Content>
                                        <Card.Header>Required certifications</Card.Header>
                                        <Card.Description><p id='p-wrap'>{job?.certifications}</p></Card.Description>  
                                    </Card.Content>                           
                                         
                                
                                    <Card.Content>
                                        <Card.Header>Job schedule</Card.Header> 
                                        <Card.Description>
                                            <List divided horizontal relaxed>
                                                { job?.schedule?.map((schedule, index)=> (
                                                    <List.Item key={index}>{schedule}</List.Item>
                                                ))}
                                            </List>                                        
                                        </Card.Description>
                                    </Card.Content>
                                {
                                    job?.mobility==='car_needed' && 
                                    (<Card.Content>
                                        <Card.Header>Car requirement</Card.Header>  
                                        <Card.Description>
                                            Requires driving
                                        </Card.Description>                                  
                                    </Card.Content>) 
                                }  
                                {
                                    job?.mobility==='no_car_needed' && 
                                    (<Card.Content>
                                        <Card.Header>Car requirement</Card.Header> 
                                        <Card.Description>
                                            Doesn't require driving
                                        </Card.Description>                                      
                                    </Card.Content>) 
                                }  
                                {
                                    job?.alert_preferences?.length > 0 && (
                                        <Card.Content>                                
                                            <Grid columns={job?.alert_preferences?.length} relaxed='very' stackable>
                                                { job?.alert_preferences?.some(preference => preference === 'Phone call') && (<Grid.Column verticalAlign='middle'>                                         
                                                    <Button 
                                                        onClick={ callProvider } 
                                                        color='violet' 
                                                        icon
                                                        id="caregiver_call_provider"
                                                    >
                                                    <Icon name="phone"></Icon>
                                                        Place Call
                                                    </Button>
                                                </Grid.Column>)}

                                                { job?.alert_preferences?.some(preference => preference === 'Email') && (<Grid.Column verticalAlign='middle'>                                                  
                                                    <Button 
                                                        onClick={ emailProvider }
                                                        color='violet' 
                                                        id="caregiver_email_provider"  
                                                        basic icon>
                                                    <Icon name="envelope"></Icon>
                                                        Email
                                                    </Button>
                                                </Grid.Column>)}

                                                { job?.alert_preferences?.some(preference => preference === 'SMS/Text message') && (<Grid.Column verticalAlign='middle'>                                                  
                                                    <Button 
                                                        onClick={textProvider}
                                                        color='violet' 
                                                        id="caregiver_text_provider"  
                                                        icon>
                                                    <Icon name="comment"></Icon>
                                                        SMS/Text
                                                    </Button>
                                                </Grid.Column>)}                                      
                                            </Grid>                                 
                                        </Card.Content>
                                    ) 
                                }                                       
                                    <Card.Content>
                                    {!userData?.favorite_jobs?.some(favorite => favorite.jobId === Realm.BSON.ObjectID(job._id).toString() ) && 
                                    <Button 
                                        onClick={ saveJobAsFavorite } 
                                        color='red' 
                                        id="caregiver_save_job_as_favorite"  
                                        basic icon>
                                    <Icon name="favorite"></Icon> 
                                        Save Job
                                    </Button>}

                                    {userData?.favorite_jobs?.some(favorite => favorite.jobId === Realm.BSON.ObjectID(job._id).toString() ) &&
                                     <Button
                                      onClick={ removeJobAsFavorite } 
                                      id="caregiver_remove_job_as_favorite"  
                                       basic icon>
                                    <Icon name="minus square"></Icon> 
                                        Remove Job
                                    </Button>}     
                                                                         
                                
                                    { !job?.applicants?.some(i => i.userID ===  app.currentUser.id) && (
                                        <Button                                
                                            content='Apply'
                                            primary
                                            floated="right"
                                            size='big'
                                            id="caregiver_apply for job"  
                                            onClick={ applyJob }
                                        />
                                    )}
                                    </Card.Content>
                                </Card> 

                                <Card fluid>
                                    { job?.applicants?.some(i => i.userID ===  app.currentUser.id) && (
                                        <Message 
                                            info                               
                                            header='Your application for this job opening was successful.'                                           
                                        />
                                    )} 
                                
                                </Card> 

                            </Grid.Row>                           
                            )
                        }
                        <Segment>
                            <Header as='h3'>
                                Similar Job Openings
                            </Header> 
                        
                            {
                                similarJobs.length > 0 && 
                                    <List horizontal divided relaxed>
                                    {
                                        similarJobs.map((similarJob, index) => (
                                            <List.Item key={index} >                       
                                                <List.Content>
                                                <Link to={`/vitae/view/job/${similarJob?._id}`} ><List.Header as="a">{similarJob.title}</List.Header></Link>
                                                    <List.Description >{similarJob.contacts.city} {similarJob.contacts.zipcode} </List.Description>
                                                </List.Content>
                                            </List.Item>                                       
                                        ))
                                    } 
                                    </List>
                            }
                        </Segment>
                        </Grid.Column>                        
                    </Grid.Row>                   
                </Grid>
            </Container>            
        </div>
    )
}

export default ViewJobOpeningUI
