import { useState } from 'react'

//import { login }

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [ form, setForm ] = useState({
        email: '',
        password: ''
    })  
    
    
    const onChange = (e, { name, value }) => {
        
        //setForm({ ...form, [name]: value });
        setForm( state =>({
            ...state,
            [name]: value
        }))
    };  

    //if password, password2 and email are filled out,
    //it returns true and form can be submitted
    const registerFormValid =      
        !form.password?.length ||    
        !form.email?.length;


    return { form, registerFormValid, onChange}
}