import CandidateResumeUI from '../../../layouts/Job/read/ViewCandidateResumeUI'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module';

const SingleCandidateResumeContainer = ({ mongoContext : { client, setUserData, user, userData } }) => {  

   //fetch the candidate id
  const { candidateId } = useParams()

  //declare initial state of the view/component
  const [ candidate, setCandidate ] = useState({})
  const [ favorite, setFavorite ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(null)
  const [ similarCandidates, setSimilarCandidates ] = useState([])

  //get a reference of the caregiver cvs' collection //caregivers_cv
  const collection = client?.db('kinshealth').collection('users')

  useEffect(() => {

    window.scrollTo(0, 0)
        
    //describe function to get candidate cv
    const getCandidateForProvider = async () => {    
      try{ 

        const pipeline = (user?.customData?.trial || user?.customData?.plan === 'premium') ?
        [
          { 
            $match: { userID: candidateId  }          
          },
          {
            $project: { auth: 0 }
          }
          
        ] : [
          {
            $match: { userID: candidateId },
          },
          {
            $project: { auth: 0, "settings.tel": 0, "settings.email": 0, "settings.cell": 0 }
          }      
        ]

        const candidateCursor = await collection.aggregate(pipeline)

        console.log('candidate cursor ---> ', candidateCursor[0])

        const similarCandidatesPipeline = [
        //   { 
        //     "$geoNear": {
        //         "near": {
        //             "type": "Point",
        //             "coordinates": [ candidateCursor[0].geocode_address?.coordinates?.lng, candidateCursor[0].geocode_address?.coordinates?.lat ],
        //         },
        //         "spherical": true,
        //         "includeLocs": "dist.location",
        //         "distanceField": "dist.calculated",
        //         "maxDistance": 70000,
        //         "distanceMultiplier": 1/1610                        
        //     }
        // },    
     
          {
            $search: {
              "index": "caregiversIndex",
              "moreLikeThis": {
                "like": [
                  {
                   // geocode_address: candidateCursor[0].geocode_address,
                    availability: candidateCursor[0].availability,                  
                    licenses: candidateCursor[0].licenses
                  }
                ]
              }
            }
          },
          {
            $match: {
              $and: [
                { complete: true, role: 'caregiver' },
                { has: { $ne: candidateCursor[0].hash } }
              ]
            } 
          },
          {
            $project : {
              city: 1,
              fname: 1,
              lname: 1,
              userID: 1,
              zipcode:1
            }
          },
          {
              $limit: 3
          }
        ]

        //get 3 candidates similar to the above
        const similarCandidateResults = await collection.aggregate(similarCandidatesPipeline) 

        setSimilarCandidates(similarCandidateResults) 

        //get + save the candidate 
        setCandidate(candidateCursor[0]) 

        if(userData?.saved_candidates?.some(element => element.userID === candidateId )){
          setFavorite(true)
        }
        
        //set loading as false
        setLoading(false)
      } catch(err){

        setLoading(false)
        setError(err)
      }    
    }
    //get candidate
    getCandidateForProvider()

  }, [ candidateId, loading, error ])

  /**
   * This function notifies the caregiver that an employer has attempted to contact them
   * @param - payload - {Object} method - the type of channel provider uses to contact caregiver
   * @param - payload - {Object} name - the name of the provider
   * @returns - none
   */
  
  const notifyCaregiver = async (payload) => {
    try{
      
      await collection?.updateOne({ userID: candidateId }, 
                { 
                  $push: 
                    { 
                      contacts : 
                        { 
                          $each : [
                            {
                              date: new Date(),
                              employer_id: user?.id,
                              employer_hash: user?.customData?.hash,
                              provider_name: payload.name,
                              method: payload.method
                            }
                          ], 
                          $position: 0                        
                        }
                    }
                }
              )
    }catch(err){
      console.log('err notifying caregiver ', err)
    }
  }

  /**
   * This function lets a provider contact a caregiver using text
   * @param - none
   * @returns - none
   */

  const textCaregiver = async () => {
 
    //if the provider is NOT a premium provider, alert the provider they need to be premium provider to call a caregiver
    if(user?.customData?.plan === 'premium' || user?.customData?.trial){

      //get the provider's name
      const name  = userData.name                                  
      //ensure that the user wants to be contacted   
      const sms_data = {
          body: `We are ${name} and would love to talk to you about our job opening.  Please log in at www.kinscare.org/signin to see how to contact us.`,
          to: candidate.settings.tel
      }
      //sms users         
      await user.callFunction('twilio_sms_user', sms_data)
       //alert provider that we are sending the caregiver
      alert(`A text message has been sent to ${candidate.fname} ${candidate?.lname}.  Sign in a bit later to see their response.`)
      const contactDetails = {
        method: 'SMS/Text',
        name
      }

      await user.callFunction('twilio_sms_user', sms_data)

      const tagManagerArgs = {
          dataLayer: {
              event: `user_sends_text`,             
              role : user?.customData?.role,                             
              userId: user?.id     
          }           
      }

      TagManager.dataLayer(tagManagerArgs)

      notifyCaregiver(contactDetails)
      
    } else {
      return alert("You must be a premium provider to call a caregiver.")
    }   
  }  

  /**
   * This function initiates call from provider with premium subscription to caregiver
   * @param {String} - none
   * @returns - no return
   */

  const callCaregiver = async () => {

    //if the provider is NOT a premium provider , alert the provider they need to be premium provider to call a caregiver  
    if(user?.customData?.plan === 'premium' || user?.customData?.trial ){

       //alert provider that we are sending the caregiver
      alert(`A phone call to ${candidate.fname} ${candidate?.lname} is being initiated.  Stay close to your phone.`)
     
      const recipientDetails = {       
        to: candidate.settings.tel,
        name: `${userData.name}`, 
        recipient: `${candidate.fname} ${candidate?.lname}`,
        subject: `your resume on Kinscare`
      }

      await user.callFunction('twilio_attempt_web_call', recipientDetails )

      const contactDetails = {
        method: 'Phone call',
        name: userData.name
      }

      const tagManagerArgs = {
        dataLayer: {
            event: `user_iniatiates_phone_call`,             
            role: user?.customData?.role,
            userId: user?.id     
        }           
      }

      TagManager.dataLayer(tagManagerArgs)

      notifyCaregiver(contactDetails)
      
    } else {
      return alert("You must be a premium provider to call a caregiver.")
    }       
  }
  
  /**
   * This function lets a provider send a caregiver an email to express interest in hiring them
   * @params - none
   * @return - none
   */

  const emailCaregiver = async () => {
    //alert provider that we are sending the caregiver
    alert(`An email has been sent to ${candidate.fname} ${candidate?.lname}.  Sign in a bit later to see their response.`)
    //create email recipient details
    const recipientData = {
      to: candidate.settings.email,
      //from: user.settings.email,
      sender: userData.name,
      recepient: `${candidate.fname} ${candidate.lname}` ,
    }

    await user.callFunction('mandrill_email_caregiver', recipientData)

    const tagManagerArgs = {
      dataLayer: {
          event: `user_sends_email`,             
          role: user?.customData?.role,
          userId: user?.id  
      }           
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  /**
     * This function adds a job as a favorite in caregiver's application cv document
     * @param - none
     * @returns - none
     */

  const saveCandidateAsFavorite = async () => {    
    
    const payload = {
      hash: candidate.hash,
      userID: candidate.userID,
      fname: `${candidate.fname}`, 
      lname: `${candidate.lname}`, //? candidate.full_name : candidate.name,
      licenses: candidate.licenses,
      availability: candidate.availability,
      type:'add'
    }

    //get employer and check if employer has an id equals to candidateid in saved candidates array
    //if true, no nee
    if(userData?.saved_candidates?.some(element => element.userID === payload.userID)){
      setFavorite(true)
      return
    } else {    
      //add candidate to employer's document favorite array
      await user.callFunction("web_add_or_remove_caregiver_as_favorite", payload)
      //retrieve the employer with updated data
      setUserData(await collection.findOne({ userID: user?.id}))

      const tagManagerArgs = {
        dataLayer: {
            event: `star_as_favorite`,      
            role: user?.customData?.role,   
            starred: 'resume',
            userId: user?.id  
        }           
      }

      TagManager.dataLayer(tagManagerArgs)
      //set caregiver as a favorite
      setFavorite(true)
    }    
  }

/**
 * This function removes a job as a favorite in caregiver's application cv document
 * @param - none
 * @returns - none
 */

  const removeCandidateAsFavorite = async () => {

    const payload = {      
      userID: candidate.userID,
      hash: candidate.hash,
      fname: `${candidate.fname}`,
      lname: `${candidate.lname}`, //? candidate.full_name : candidate.name,
      licenses: candidate.licenses,
      availability: candidate.availability,
      type: 'remove'
    }

    console.log('payload -> ', payload)
   
    //get employer and check if employer has an id equals to candidateid in saved candidates array
    if(userData?.saved_candidates?.some(element => element.userID === payload.userID)){
      setFavorite(false)

      //add job to caregiver's application submitted array
      await user?.callFunction("web_add_or_remove_caregiver_as_favorite", payload)

     
      //retrieve the employer with updated data
      setUserData(await collection.findOne({ userID : user?.id }))
      
    } else {      
      //remove caregiver as a favorite
      setFavorite(false)
      return
    }    
  }

  return (
    <CandidateResumeUI    
      callCaregiver={callCaregiver}
      candidate={candidate} 
      emailCaregiver={emailCaregiver}
      error={error} 
      loading={loading} 
      favorite={favorite}     
      removeCandidateAsFavorite={removeCandidateAsFavorite}
      saveCandidateAsFavorite={saveCandidateAsFavorite}
      similarCandidates={similarCandidates}
      textCaregiver={textCaregiver}
      userData={userData}
      user={user}
    />
  )}

export default SingleCandidateResumeContainer