
import { useHistory } from 'react-router-dom'
import {
    Form,
    SubmitButton,  
    Radio, 
    Checkbox,
    Input,
    TextArea 
} from 'formik-semantic-ui-react'
import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm,
    Header as SemanticHeader,
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import EmployerPanel from '../../../components/EmployerPanel'
import React from 'react'
import TagManager from 'react-gtm-module';

const UpdateJobPostingForm = ({ job, user}) => {      
    //get history for routing   
    const history = useHistory()
    const [ loading, setLoading ] = React.useState(false)

    const jobPostSchema = Yup.object().shape({          
        //_id: Yup.string().required(),
        certifications: Yup.string(),
        compensation: Yup.string().required(),
        //contacts: Yup.object().shape({
            address: Yup.string().required("Please enter the address of where your job is located."),
            city: Yup.string().required("Please enter the city your job is located."),
            email: Yup.string().required("Please enter the email address caregivers will use to contact you."),
            tel: Yup.string().required("Please enter the telephone number caregivers will use to contact you."),
            zipcode: Yup.string().required("Please enter the zipcode of your job is located."),
        //}),
       // created: Yup.date().required("Required"),
        description: Yup.string().required(),
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'.").required(),
        hash: Yup.string(),
        mobility: Yup.string().required(),
        schedule: Yup.array().min(1, "Select at least 1 schedule you are available to work.").required(),
        title: Yup.string().required("Please enter job title"),
        userID: Yup.string().required()
    })  

    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid  stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user} />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <div id='update_job_post'>
                               <SemanticHeader as='h3'>Update job opening</SemanticHeader> 
                            </div>
                            
                            <Segment> 
                            <Formik

                                initialValues ={{     
                                    _id: job?._id,                               
                                    certifications: job?.certifications,                                                              
                                    compensation: job?.compensation,                       
                                    address: job?.contacts?.address,
                                    city: job?.contacts?.city,       
                                    email: job?.contacts?.email,
                                    description: job?.description,
                                    hash: user?.customData?.hash, 
                                    draft: job?.draft,
                                    licenses: job?.licenses,
                                    mobility: job?.mobility,
                                    schedule: job?.schedule,
                                    tel: job?.contacts?.tel,                             
                                    title: job?.title,
                                    userID: user?.id,
                                    zipcode: job?.contacts?.zipcode,    

                                }}

                                enableReinitialize

                                validationSchema = { jobPostSchema }

                                onSubmit={ async (values, { setSubmitting }) => {     

                                    setLoading(true)                               
                                 
                                    //create user settings object 
                                    const job_post = { 
                                        _id: job._id,//.toString(),                                      
                                        certifications: values.certifications.trim(),                                        
                                        compensation: values.compensation.trim(),  
                                        contacts: {                                            
                                            address: values.address.trim(),
                                            city: values.city.trim(),
                                            email: values.email.trim(),
                                            tel: values.tel.trim(),
                                            zipcode: values.zipcode.trim(),
                                        },    
                                        created: job.created,                     
                                        description: values.description.trim(),
                                        hash: user?.customData?.hash,
                                        licenses: values.licenses,
                                        mobility: values.mobility,
                                        schedule: values.schedule,
                                        title: values.title.trim(),
                                        userID: user?.id
                                    }    
                                                                       
                                    const results = await user.callFunction("web_add_provider_job_post", job_post)

                                    //tag manager for selecting plan
                                    const tagManagerArgs = {
                                        dataLayer: {
                                            event: `provider_update_job`,                                              
                                            added: new Date(),   
                                            userId: user?.id,                                       
                                        }           
                                    }

                                    TagManager.dataLayer(tagManagerArgs)
                                    setLoading(false)
                                    console.log('stupid results ', results)
                                    //set the application in the mongo context
                                   // setApplication(await user.callFunction("web_job_add", job_post))
                                    history.push(results.url)
                                    
                                }}                        
                            > 
                            {
                                ({ errors, touched, values, setFieldValue }) => (                                   
                                    <Form >   
                                        <Input
                                            type="text"
                                            name="title"
                                            label="Job title"
                                            required
                                            //placeholder="Enter title of job posting" 
                                            size="large"   
                                            defaultValue={job?.title}                                        
                                        />   
                                        { touched.title && errors.title ? (<Message negative size='small'>{errors.title}</Message>) : null }       
                                        <label><b>What licenses are required?</b></label><br/>
                                        <SemanticForm.Group widths="equal" inline required>                                            
                                            <Checkbox                                               
                                                value="CNA"
                                                name="cna"
                                                label="CNA or NAC" 
                                                onChange={() => {
                                                        if(values?.licenses?.includes("CNA")){
                                                            const index = values?.licenses?.indexOf("CNA");
                                                            console.log('index ', index)
                                                            console.log('licenses ', values?.licenses )
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("CNA")
                                                    }                                                   
                                                }          
                                                checked={ job?.licenses?.includes("CNA")}                                                        
                                            />
                                            <Checkbox                                               
                                                value="HCA"
                                                name="hca"
                                                label="HCA"        
                                                onChange={() => {
                                                        if(values?.licenses?.includes("HCA")){
                                                            const index = values?.licenses?.indexOf("HCA");
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("HCA")
                                                    }                                                   
                                                }     
                                                checked={ job?.licenses?.includes("HCA")}                        
                                            />
                                            <Checkbox                                                
                                                value="NAR"
                                                name="nar"
                                                label="NAR"   
                                                onChange={() => {
                                                        if(values?.licenses?.includes("NAR")){
                                                            const index = values?.licenses?.indexOf("NAR");
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("NAR")
                                                    }                                                   
                                                }
                                                checked={ job?.licenses?.includes("NAR")}           
                                            />
                                            <Checkbox                                               
                                                value="None"
                                                name="none"
                                                label="None" 
                                                onChange={() => {
                                                        if(values?.licenses?.includes("None")){
                                                            const index = values?.licenses?.indexOf("None");
                                                            if (index > -1) {
                                                                values?.licenses?.splice(index, 1);
                                                            }
                                                        } else values?.licenses?.push("None")
                                                    }                                                   
                                                }
                                                checked={ job?.licenses?.includes("None")}                  
                                            />
                                        </SemanticForm.Group>  
                                        
                                        { touched.licenses && errors.licenses ? (<Message negative size='small'>{ errors.licenses }</Message>) : null }                                         
                                        <label><b>Please enter required certifications such as CPR/First Aid, Food Handler's etc.</b> </label>
                                        <TextArea                                              
                                            rows={3}                      
                                            name="certifications"
                                            size="large"
                                            placeholder="Enter other certifications, e.g., CPR/First Aid, Food Handler's etc."   
                                            defaultValue={ job?.certifications }                                                                                                               
                                        /> 
                                        {/* { touched.certifications &&  errors.certifications && <Message>{errors.certifications}</Message>}    */}

                                        <label><b>Please enter job description.</b> </label>
                                        <TextArea                                              
                                            rows={5}                      
                                            name="description"
                                            size="large"
                                            placeholder="Add job description i.e., tasks, a more specific schedule, required experience etc."    
                                                                                                                                                      
                                        /> 
                                      { touched.description &&  errors.description && <Message negative size='small'>{errors.description}</Message>}    
                                        <label><b>Select job schedule:</b></label><br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Checkbox
                                                value="Full time"
                                                name="full_time"
                                                label="Full time"
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Full time")){
                                                            const index = values?.schedule?.indexOf("Full time");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Full time")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Full time")}                       
                                            />
                                            <Checkbox
                                                value="Part time"
                                                name="part_time"
                                                label="Part time"
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Part time")){
                                                            const index = values?.schedule?.indexOf("Part time");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Part time")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Part time")}                       
                                            />
                                            <Checkbox
                                                value="Weekends"
                                                name="weekends"
                                                label="Weekends" 
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Weekends")){
                                                            const index = values?.schedule?.indexOf("Weekends");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Weekends")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Weekends")}
                                                          
                                            />
                                            <Checkbox
                                                value="On Call"
                                                name="on_call"
                                                label="On Call"                                             
                                                onChange={() => {
                                                        if(values?.schedule?.includes("On Call")){
                                                            const index = values?.schedule?.indexOf("On Call");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("On Call")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("On Call")}                   
                                            />
                                            <Checkbox
                                                value="Live In"
                                                name="live_in"
                                                label="Live In"                                             
                                                onChange={() => {
                                                        if(values?.schedule?.includes("Live In")){
                                                            const index = values?.schedule?.indexOf("Live In");
                                                            if (index > -1) {
                                                                values?.schedule?.splice(index, 1);
                                                            }
                                                        } else values?.schedule?.unshift("Live In")
                                                    }                                                   
                                                }
                                                checked={ job?.schedule?.includes("Live In")}                   
                                            />
                                        </SemanticForm.Group>             
                                        { touched.schedule  &&  errors.schedule && <Message negative size='small'>{errors.schedule}</Message>  } 

                                        <label ><b>Enter your contact details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Email of the hiring contact."                                    
                                            />   
                                            <Input
                                                type="tel"
                                                name="tel"
                                                label="Phone number"
                                                placeholder="Phone # of the hiring contact"                                               
                                                minLength="5"
                                            />                    
                                        </SemanticForm.Group>
                                        { touched.email && errors.email ? (<Message negative size='small'>{ errors.email }</Message>) : null } 
                                        { touched.tel && errors.tel ? (<Message negative size='small'>{ errors.tel }</Message>) : null } 

                                        <label ><b>Enter address of location:</b></label> <br/>                                         
                                        <Input
                                            type="text"
                                            name="address"
                                            label="Street address"
                                            placeholder="Enter your street address"                                    
                                        />   
                                         { touched.address && errors.address ? (<Message negative size='small'>{ errors.address }</Message>) : null } 
                                        <label ><b>To match your opening with caregivers close to you, enter the following details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">                                            
                                            <Input
                                                type="text"
                                                name="city"
                                                label="City"
                                                placeholder="The city where job is located"       
                                                                          
                                            />   
                                            <Input
                                                type="text"
                                                name="zipcode"
                                                label="Zipcode"
                                                placeholder="The zipcode of where job is located"                                               
                                                minLength="5" 
                                            />                    
                                        </SemanticForm.Group>
                                        { touched.zipcode && errors.zipcode ? <Message negative size='small'>{errors.zipcode}</Message> : null } 
                                        { touched.city && errors.city ? <Message negative size='small'>{errors.city}</Message> : null }  
                                        <label><b>Does the job require caregiver to drive?</b></label>         
                                        <SemanticForm.Group inline widths="equal">                                            
                                            <Radio  
                                                label="YES"                     
                                                name="mobility"                            
                                                value="car_needed"                                        
                                                type='radio'    
                                                onChange ={ () =>  setFieldValue('mobility', 'car_needed') }                                         
                                            /> 
                                        
                                            <Radio                
                                                name="mobility"
                                                value="no_car_needed"
                                                label="NO"
                                                type='radio'  
                                                onChange ={ () => setFieldValue('mobility', 'no_car_needed') }                                                                           
                                            />                                                           
                                        </SemanticForm.Group> 
                                        { touched.mobility && errors.mobility ? <Message negative size='small'>{errors.mobility}</Message> : null } 

                                        <label><b>Compensation</b></label>
                                        <Input
                                            type="text"
                                            name="compensation"
                                            placeholder="Enter $ amount compensation, DoE, Negotiable etc"                                           
                                        />
                                       { touched.compensation && errors.compensation ? <Message negative size='small'>{errors.compensation}</Message> : null }         
                                        <SubmitButton 
                                            fluid                   
                                            id="provider_submit_job_update_form"                                               
                                            loading={loading}
                                            primary
                                            type="submit"
                                        >
                                            Update job
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default UpdateJobPostingForm
