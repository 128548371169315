import React from 'react';
import { useHistory } from 'react-router-dom';

const Panel = ({ mongoContext : { user }}) => {

    const history = useHistory() 

    React.useEffect(() => {

        //route according to whether caregiver is creating or updating a resume
        const checkCaregiverResume = async(user) => {

            //refresh user custom data
            await user?.refreshCustomData()

            if(user?.customData?.role === 'caregiver'){
                history.push('/vitae/jobs/all')
                // if(user?.customData?.complete){           
                //     history.push('/vitae/update')
                // } else history.push('/vitae/create')
            }
        
            if(user?.customData?.role === 'provider'){
                history.push('/provider/candidates/all')
                // if(user?.customData?.complete) {
                //     history.push('/provider/settings/form')   
                // } else {
                //     history.push('/provider/candidates/all')
                // }  
            }
        }
        //route depending on whether
        checkCaregiverResume(user)
    }, [user])  

    return (    
        <>HI</>               
    )
}

export default Panel
