import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { useParams, Redirect, useHistory, withRouter } from 'react-router-dom';
import { Button, Divider, Form, Grid, Header, Icon, Message, Modal } from "semantic-ui-react";
import { BSON } from 'realm-web';
import TagManager from 'react-gtm-module';

const CARD_ELEMENT_OPTIONS = {
    //theme:"stripe", 
    hidePostalCode: true 
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); 

const Wrapper = (props) => (
    
    <Elements stripe={stripePromise}>        
        <PaymentMethodsUI {...props} />
    </Elements>
);

const PaymentMethodsUI = ({ location, mongoContext: { client, userData, user }}) => {    
   
    // Get the lookup key for the price from the previous page redirect.
    const [clientSecret] = useState(location.state.clientSecret);
    const [cardClientSecret, setClientSecret] = useState('');
    const [payments, setPaymentMethods] =useState([])
    const [ subscriptionId ] = useState(location.state.subscriptionId);    
    const [name, setName] = useState("");
    const [messages, _setMessages] = useState('');
    const [paymentIntent, setPaymentIntent] = useState();
    const [loading, setLoading]=useState(false)
    const [open, setOpen] = React.useState(false)
    //get the user collection
    const collection = client?.db("kinshealth").collection("users")

    //get the contacts collection
    const contacts = client?.db("kinshealth").collection("contacts")
    //get the jobs collection
    const jobs = client?.db("kinshealth").collection("jobs")

    const { jobId } = useParams()

    const history = useHistory()

    const fetchSecret = async() => {
        
        //get 
        const data = await user?.callFunction('stripe_pay_provider_posting', { receipt_email: userData.settings.hr_email })

        setClientSecret(data?.clientSecret)
           
    }

    React.useEffect(() => {
        
        const fetchPaymentMethods = async() => {

            //refresh user's custom data
            await user.refreshCustomData()

            //get the payment methods
            const { paymentMethods: results } = await user?.callFunction('stripe_get_provider_payment_methods', { customer_id: user?.customData?.customer_id } )

           //set the payment methods
            setPaymentMethods(results?.data)
        }

         //fetch provider's payment methods
        fetchPaymentMethods()
        //if the job id, fetch secret
        if(jobId) fetchSecret()
       
    }, [user])

    // helper for displaying status messages.
    const setMessage = (message) => {
        _setMessages(`${messages}\n\n${message}`);
    } 

    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();      

    if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
        return '';
    }    

    /**
     * This function charges previously used payment instruments
     * @param {String} id - payment id of the paymentmehtod 
     * @return {Object} component - redirects user to account page
     */

    const reusePaymentMethod = async (id) => {      

        //if there is a successful payment intent and a jobId, update job as draft = false
        await jobs.updateOne({ _id: BSON.ObjectID(jobId)}, { $set: { draft: false }})
        //refresh user's custom data
        await user.refreshCustomData()
        //this function reuses an existing payment method
        const { err, chargeClientSecret } = await user?.callFunction('stripe_reuse_payment_method', { id })

        if(err) {   
            // show error and collect new card details.
            setMessage(err.message);
            setLoading(false)
            return;
        }

        if(chargeClientSecret){

            const tagManagerArgs = {
                dataLayer: {
                    event: `provider_post_job`,           
                    plan: user?.customData?.plan,
                    amount: user?.customData?.plan === 'basic' ? 39.99 : 17.99, 
                    paymentMethod: 'existing',
                    //jobPostId: jobId,
                    added: new Date(),
                    userId: user?.id                                          
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)

            setLoading(false)
            //return <Redirect to={{pathname: '/provider/plans/account'}}   />
            history.push(`/provider/job/read/all`)
        }
        
    }
    
    // When the subscribe-form is submitted we do a few things:
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async e => {

        e.preventDefault()
        //set loading to true to prevent clicking subscribe button multiple times
        setLoading(true)

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        //const cardElement = elements.getElement("card");     
        const stripeClientSecret = jobId === undefined ? clientSecret : cardClientSecret   

        // Use card Element to tokenize payment details
        let { error, paymentIntent } = await stripe.confirmCardPayment(stripeClientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name : `${userData.name}`
                }
            }
        });       

        if(error) {   
            // show error and collect new card details.
            setMessage(error.message);
            setLoading(false)
            return;
        }

        const tagManagerArgs = {
            dataLayer: {
                event: `provider_use_new_payment_method`,
                added: new Date(),
                userId: user?.id                          
            }           
        }

        TagManager.dataLayer(tagManagerArgs)

        //if there is a successful payment intent and no jobId, update provider as a subscriber
        //if there is a successful payment intent and a jobId, update job as not a draft, i.e., draft = false

        if(jobId){
            await jobs.updateOne({ _id: BSON.ObjectID(jobId)}, { $set: { draft: false, notify: true }})

            const tagManagerArgs = {
                dataLayer: {
                    event: `provider_post_job`, 
                    added: new Date(),  
                    amount: user?.customData?.plan === 'basic' ? 39.99 : 17.99,
                    //jobPostId: jobId,
                    paymentMethod: 'new',          
                    plan: user?.customData?.plan,
                    userId: user?.id                                                 
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)

        } else {

            const plan = localStorage.getItem('plan') 
            //update user and contacts 
            //const userUpdate =  collection.updateOne({ userID: userData.userID }, { $set: { subscriber: true, trial: false, subscriptionId: localStorage.getItem("subscriptionId")}}) 
            await contacts.updateOne({ userID: userData.userID }, 
                { 
                    $set: { 
                        subscriber: true, 
                        trial: false, 
                        subscriptionId: localStorage.getItem("subscriptionId"),
                        plan//: localStorage.getItem('plan')
                    }                
                })
            
                
            const tagManagerArgs = {
                dataLayer: {
                    event: `provider_pay_for_${plan}_plan`, 
                    added: new Date(),                   
                    amount: plan === 'basic' ? 69.99 : 19.99,                     
                    plan,
                    userId: user?.id,                                        
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)

            localStorage.clear()
        }

        setPaymentIntent(paymentIntent);
        setLoading(false)
    }

    if(paymentIntent && paymentIntent.status === 'succeeded') {
       return <Redirect to={{pathname: '/provider/plans/account'}} />
    }

   return (    
        <Grid centered>  
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 500, marginTop: 30, marginBottom: 150 }}>               
                    { payments?.length > 0 ?
                        <>
                            { payments?.map(payment => (   
                                <>                     
                                <Button   
                                    key={payment.id}                    
                                    onClick = {() => { reusePaymentMethod(payment.id)} }
                                    color="teal"
                                    type="submit"
                                >
                                    <Icon name="credit card"></Icon>
                                    Use card ending with {payment?.card?.last4} 
                                </Button>
                                <Divider hidden/>
                                </>
                            ))} 

                            <Divider horizontal>Or</Divider>

                            <Modal
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                open={open}
                                trigger={<Button basic>
                                    <Icon name="credit card"></Icon>
                                        Add payment method</Button>
                                }
                                size="small"
                            >
                            <Modal.Header>Add a card</Modal.Header>
                            <Modal.Content >            
                                <Form onSubmit={ handleSubmit }>
                                    <label><b>Name on the card </b></label> <br/>                               
                                    <Form.Input
                                        type="text"
                                        name="name"
                                        onChange={(e) => setName(e.target.value)}
                                        value = {userData?.name} 
                                        placeholder="Enter card holder's name."                                            
                                        required
                                    />
                                    <Divider />
                                    <CardElement options={CARD_ELEMENT_OPTIONS}/>
                                    <Divider />
                                    <Button
                                        fluid
                                        loading={loading}
                                        primary
                                        type="submit"
                                    >
                                    Add payment method
                                    </Button>
                                </Form>     
                            { messages && <Message negative>
                                <Message.Header>{messages}</Message.Header>    
                            </Message> }  
                
                            </Modal.Content>
                                
                            </Modal> 
                        </> 
                        :                        
                        <>              

                        <Header as='h3'>Add payment method</Header>
                        
                        <Form onSubmit={handleSubmit}>
                            <label><b>Name on the card </b></label><br/>                               
                            <Form.Input
                                type="text"
                                name="name"
                                onChange={(e) => setName(e.target.value)}
                                value={userData.name} 
                                placeholder="Enter card holder's name."                                            
                                required
                            />
                            <Divider />
                            <CardElement options={CARD_ELEMENT_OPTIONS}/>
                            <Divider />
                            <Button
                                fluid
                                loading={loading}
                                primary
                                type="submit"
                            >
                            Pay now
                            </Button>
                        </Form>     
                        { messages && <Message negative>
                            <Message.Header>{ messages }</Message.Header>    
                        </Message> }  
                        </>                            
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>    
    ) 
}

export default withRouter(Wrapper);
