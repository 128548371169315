import { useHistory } from 'react-router-dom'
import React from 'react'
import {
    Form,
    SubmitButton,  
    Radio, 
    Checkbox,
    Input,
    TextArea 
} from 'formik-semantic-ui-react'

import {     
    Segment,
    Grid,     
    Container,
    Message,
    Form as SemanticForm,
    Header as SemanticHeader,
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import CaregiverPanel from '../../../components/CaregiverPanel'
import TagManager from 'react-gtm-module';

const Application = ({ mongoContext: { user, userData, setUserData }  }) => {     

    const [loading, setLoading] = React.useState(false)
    //get history constant
    const history = useHistory()   

    //schema for application
    const applicationSchema = Yup.object().shape({
        fname: Yup.string().required('Please enter your first name.'),//.max(2, 'Full name can only be 6 characters long.'),
        lname: Yup.string().required('Please enter your last name.'),
        created: Yup.string(),
        city: Yup.string().required(),
        zipcode: Yup.string().min(5, 'Zipcode must be 5 characters long!').required('required'),
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'.").required('required'), //.min(1, "at least 1")
        availability:  Yup.array().min(1, "Select at least 1 schedule you are available to work.").required('required'),
        alert_preferences: Yup.array().min(1, "Select at least one way to be contacted.").required('required'),
        mobility: Yup.string().required(),
        certifications: Yup.string()//.required()
    })  
    console.log('userData ', userData)
    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <CaregiverPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                        <SemanticHeader as='h3'>Update application</SemanticHeader>
                            <Segment> 
                            <Formik
                                //initialValues = { userData }

                                initialValues ={{        
                                    alert_preferences: userData?.settings?.alert_preferences,
                                    availability: userData?.availability ,
                                    fname: userData?.fname,  
                                    lname: userData?.lname, 
                                    certifications: userData?.certifications,
                                    city: userData?.city,
                                    contact_email: userData?.settings?.email,
                                    contact_phone: userData?.settings?.tel,
                                    licenses: userData?.licenses,
                                    mobility: userData?.mobility,
                                    zipcode: userData?.zipcode
                                }}

                                validationSchema = { applicationSchema }

                                onSubmit={ async (values, { setSubmitting }) => {   
                                    setLoading(true)                                                                   
                                    console.log('VALUES', values, 'userData ', userData)
                                    // create user settings object 
                                    const user_cv = {
                                        userID: user?.id,  
                                        hash: user?.customData?.hash,                                                                      
                                        availability: values.availability,
                                        fname: values.fname,
                                        lname: values.lname,
                                        certifications: values.certifications,
                                        city: values.city,       
                                        complete: true,  
                                        created: userData?.created,                              
                                        licenses: values.licenses,
                                        mobility: values.mobility,
                                        settings: {
                                            alert_preferences: values.alert_preferences,
                                            email: values.contact_email,
                                            role: 'caregiver',
                                            tel: values.contact_phone                                            
                                        }, 
                                        zipcode: values.zipcode                                        
                                    }                                    
                                    
                                    setSubmitting(true)
                                    
                                    const results = await user.callFunction("web_add_caregiver_application", user_cv)

                                    const tagManagerArgs = {
                                        dataLayer: {
                                            event: `caregiver_update_resume`,
                                            added: new Date(),
                                            userId: user?.id    
                                        }           
                                    }
                            
                                    TagManager.dataLayer(tagManagerArgs)

                                    setLoading(false)
                                    //check if the submission was successful
                                    //if not, alert the user the application had an error
                                    if(!results.valid){
                                        alert(results.message)
                                        history.push(results.url)
                                    }
                                    //set application in the context
                                    setUserData(results.data)
                                    //refresh the user custom data
                                    await user.refreshCustomData()
                                    alert(results.message)                                    
                                    // setMessage(results.message)
                                    history.push(results.url)
                                    setSubmitting(false)
                                }}                        
                            > 
                            {
                                ({ errors, touched, values, setFieldValue }) => (
                                 
                                    <Form>   
                                        <label><b>Enter your first and last name</b></label> 
                                        <SemanticForm.Group widths="equal">
                                        <Input
                                            type="text"
                                            name="fname"
                                            label="First name"
                                            placeholder="Enter your first name"                       
                                        />
                                        <Input
                                            type="text"
                                            name="lname"
                                            label="Last name"
                                            placeholder="Enter your last name"                             
                                        />  
                                        </SemanticForm.Group>
                                        { touched.fname && errors.fname ? (<Message negative>{ errors.fname }</Message>) : null }   
                                        { touched.lname && errors.lname ? (<Message negative>{ errors.lname }</Message>) : null }      

                                        <SemanticForm.Group inline required>
                                            <label>Select your certificate(s)/license(s):</label><br/>
                                            <Checkbox
                                                value="CNA"
                                                name="CNA"
                                                label="CNA or NAC" 
                                                onChange={() => {
                                                        if(values.licenses.includes("CNA")){
                                                            const index = values.licenses.indexOf("CNA");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("CNA")
                                                    }                                                   
                                                }  
                                                checked={userData?.licenses?.includes("CNA")}                                            
                                                required                     
                                            />
                                            <Checkbox
                                                value="HCA"
                                                name="HCA"
                                                label="HCA"        
                                                onChange={() => {
                                                        if(values.licenses.includes("HCA")){
                                                            const index = values.licenses.indexOf("HCA");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("HCA")
                                                    }                                                   
                                                }
                                                checked={userData?.licenses?.includes("HCA")}                                                 
                                                required                         
                                            />
                                            <Checkbox
                                                value="NAR"
                                                name="NAR"
                                                label="NAR" 
                                                checked={userData?.licenses?.includes("NAR")}                                             
                                                required    
                                                onChange={() => {
                                                        if(values.licenses.includes("NAR")){
                                                            const index = values.licenses.indexOf("NAR");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("NAR")
                                                    }                                                   
                                                }           
                                            />

                                            <Checkbox
                                                value="None"
                                                name="None"
                                                label="None"                                             
                                                required
                                                checked={userData?.licenses?.includes("None")} 
                                                onChange={() => {
                                                        if(values.licenses.includes("None")){
                                                            const index = values.licenses.indexOf("None");
                                                            if (index > -1) {
                                                                values.licenses.splice(index, 1);
                                                            }
                                                        } else values.licenses.push("None")
                                                    }                                                   
                                                }                  
                                            />
                                        </SemanticForm.Group> 
                                        { touched.licenses && errors.licenses ? (<Message>{ errors.licenses}</Message>) : null } 
                                        <label>Please enter your certifications such as CPR/First Aid, Food Handler's etc. </label>
                                        <TextArea                                              
                                            rows={3}                      
                                            name="certifications"
                                            size="large"
                                            placeholder="Enter other certifications, e.g., CPR/First Aid, Food Handler's etc."
                                            label="List your certifications"                                                                    
                                                                  
                                        /> 
                                        {touched.certifications && errors.certifications && <Message>{errors.certifications}</Message>}  
                                        <SemanticForm.Group   >
                                            <label>Select your availability:</label><br/>
                                            <Checkbox
                                                value="Full time"
                                                name="full_time"
                                                label="Full time"
                                                onChange={() => {
                                                        if(values.availability.includes("Full time")){
                                                            const index = values.availability.indexOf("Full time");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Full time")
                                                    }                                                   
                                                }
                                                checked={userData?.availability?.includes("Full time")} 
                                                               
                                            />
                                            <Checkbox
                                                value="Part time"
                                                name="part_time"
                                                label="Part time"
                                                onChange={() => {
                                                        if(values.availability.includes("Part time")){
                                                            const index = values.availability.indexOf("Part time");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Part time")
                                                    }                                                   
                                                }
                                                checked={userData?.availability?.includes("Part time")} 
                                                             
                                            />
                                            <Checkbox
                                                value="Weekends"
                                                name="weekends"
                                                label="Weekends" 
                                                onChange={() => {
                                                        if(values.availability.includes("Weekends")){
                                                            const index = values.availability.indexOf("Weekends");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Weekends")
                                                    }                                                   
                                                }
                                                checked={userData?.availability?.includes("Weekends")} 
          
                                            />
                                            <Checkbox
                                                value="On Call"
                                                name="on_call"
                                                label="On Call"                                             
                                                onChange={() => {
                                                        if(values.availability.includes("On Call")){
                                                            const index = values.availability.indexOf("On Call");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("On Call")
                                                    }                                                   
                                                }
                                                checked={userData?.availability?.includes("On Call")}               
                                            />

                                            <Checkbox
                                                value="Live In"
                                                name="live_in"
                                                label="Live In"                                             
                                                onChange={() => {
                                                        if(values.availability.includes("Live In")){
                                                            const index = values.availability.indexOf("Live In");
                                                            if (index > -1) {
                                                                values.availability.splice(index, 1);
                                                            }
                                                        } else values.availability.push("Live In")
                                                    }                                                   
                                                }
                                                checked={userData?.availability?.includes("Live In")} 
                                                       
                                            />
                                        </SemanticForm.Group>
                                        {touched.availability && errors.availability && <Message>{errors.availability}</Message>}  
                                        <label ><b>Please enter your contact details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Input
                                                type="email"
                                                name="contact_email"
                                                label="Enter your contact email"
                                                placeholder="Enter your city"                                          
                                            />   
                                            <Input
                                                type="tel"
                                                name="contact_phone"
                                                label="Enter your contact phone"
                                                placeholder="Enter your contact phone"                                               
                                                minLength="10"                                               
                                            />                    
                                        </SemanticForm.Group>
                                        { touched.contact_email && errors.contact_email ? (<Message negative>{ errors.contact_email }</Message>) : null }    
                                        { touched.contact_phone && errors.contact_phone ? (<Message negative>{ errors.contact_phone }</Message>) : null }              
                                        
                                        <label ><b>To match you with jobs close to you, enter the following details:</b></label> <br/>
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Input
                                                type="text"
                                                name="city"
                                                label="Enter your city"
                                                placeholder="Enter your city"  
                                                defaultValue={userData.city}                                  
                                      
                                            />   
                                            <Input
                                                type="text"
                                                name="zipcode"
                                                label="Enter your zipcode"
                                                placeholder="Enter your zipcode"     
                                                defaultValue={userData.zipcode}                                             
                                                minLength="5"
                                            />                    
                                        </SemanticForm.Group>
                                        { touched.city && errors.city ? <Message negative>{errors.city}</Message> : null } 
                                        { touched.zipcode && errors.zipcode ? <Message negative>{errors.zipcode}</Message> : null } 
                                        <label><b>How do you want employers to contact you about their job openings [Must choose one]:</b></label> <br/>
                                       
                                        <SemanticForm.Group widths="equal">
                                            
                                            <Checkbox                             
                                                name="Phone call"                                                
                                                label="Phone call"
                                                value="Phone call"
                                                checked={userData?.settings?.alert_preferences.includes("Phone call")} 
                                                onChange={() => {
                                                    if(values.alert_preferences.includes("Phone call")){
                                                        const index = values.alert_preferences.indexOf("Phone call");
                                                        if (index > -1) {
                                                            values.alert_preferences.splice(index, 1);
                                                        }
                                                    } else values.alert_preferences.push("Phone call")
                                                    }                                                   
                                                }                                                 
                                            />   
                                            <Checkbox                               
                                                name="SMS/Text message"
                                                value="SMS/Text message"
                                                label="SMS/Text message"
                                                checked={userData?.settings?.alert_preferences.includes("SMS/Text message")} 
                                                onChange={() => {
                                                        if(values.alert_preferences.includes("SMS/Text message")){
                                                            const index = values.alert_preferences.indexOf("SMS/Text message");
                                                            if (index > -1) {
                                                                values.alert_preferences.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("SMS/Text message")
                                                    }                                                   
                                                }                                                 
                                            />     
                                            <Checkbox                                
                                                name="Email"
                                                value="Email"
                                                label="Email"
                                                checked={userData?.settings?.alert_preferences.includes("Email")} 
                                        
                                                onChange={() => {
                                                        if(values.alert_preferences.includes("Email")){
                                                            const index = values.alert_preferences.indexOf("Email");
                                                            if (index > -1) {
                                                                values.alert_preferences.splice(index, 1);
                                                            }
                                                        } else values.alert_preferences.push("Email")
                                                    }                                                   
                                                } 
                                            />                                                      
                                        </SemanticForm.Group>
                                        { touched.alert_preferences && errors.alert_preferences ? <Message negative>Must select at least one alert preference.</Message> : null }    
                                      

                                        <SemanticForm.Group inline>
                                            <label>Do you have a car?</label>                                             
                                            <Radio  
                                                label="YES"                     
                                                name="mobility"                            
                                                value="has_car"    
                                                onChange={() => setFieldValue('mobility', 'no_car')                                                 
                                            }                                                                         
                                            /> 
                                        
                                            <Radio                
                                                name="mobility"
                                                value="no_car"
                                                label="NO"
                                                onChange={() => setFieldValue('mobility', 'has_car') }                           
                                            />                                  
                                                            
                                        </SemanticForm.Group> 
                                        { touched.mobility && errors.mobility ? <Message negative>Must select at least one alert preference.</Message> : null }   
                                        <SubmitButton 
                                            fluid                       
                                            id="caregiver_update_resume"               
                                            // disabled={registerFormValid || loading }                                
                                            loading={loading}
                                            //onClick={handleSubmit}
                                            primary
                                            type="submit"
                                        >
                                            Update CV/resume
                                        </SubmitButton>
                                    </Form> 
                                )
                            }                            
                            </Formik>                           
                            </Segment>                 
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default Application
