import React from 'react'

const MongoContext = React.createContext({
    app: null,
    client: null,
    user: null,
    userData: {},//,null,
    //

    setApp: () => {},
    setClient: () => {},
    setUser: () => {},
    setUserData: () => {}
})

export default MongoContext