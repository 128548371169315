import ViewPostingPreviewUI from '../../../layouts/Job/read/ViewPostingPreviewUI'
import { useParams } from 'react-router-dom'
import React from 'react'
import { BSON } from 'realm-web'

const SingleJobPostingContainer = ({mongoContext : { user, client, userData }}) => {  
  //declare initial state of the component
  const [ job, setJob ] = React.useState({}) 
  const [ loading, setLoading ] = React.useState(true)
  const [ error, setError ] = React.useState(null) 
  //get job id params from the route path
  const  { jobId } = useParams()
  //fetch job using user id and job id everytime component loads
  //declare async get job function inside use effect
    
  React.useEffect(() => {


    const jobs = client?.db('kinshealth').collection('jobs')
   // setLoading(true)
    const getJob = async() => {
      try{
        //set the job in job state variable            
        setJob(await jobs?.findOne({ _id: BSON.ObjectId(jobId) }))
        //refresh the user custom data 

        window.scrollTo(0, 0)

        await user.refreshCustomData()
        //
        setLoading(false)

      }  catch (err) {
        setLoading(false)
        setError(err)
      }          
    }    

    getJob()

    //return () => setLoading(false)
     
  }, [ error, loading, jobId ])

  return ( < ViewPostingPreviewUI job={job} user={user} loading={loading} error={error} userData={userData} /> )}

export default SingleJobPostingContainer