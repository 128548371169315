import React from 'react'
import SignUpUI from '../../../layouts/User/FbSignUpUI'
import useForm from './useForm'


const SignUpContainer = ({ mongoContext }) => {

  return (<SignUpUI form={ useForm() } mongoContext={mongoContext} />)
}

export default SignUpContainer