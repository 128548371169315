import React from 'react'
import TwilioSignUpUI from '../../layouts/Twilio/SignUpUI'

const TwilioSignUpContainer = ({ mongoContext  }) => {
  
  return (<TwilioSignUpUI mongoContext={ mongoContext } />)
    
}

export default TwilioSignUpContainer
