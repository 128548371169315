import React from 'react'
import {
    Button,
    Container,
    Grid,
    Header
  } from "semantic-ui-react";
import { Link } from "react-router-dom"

const About = () => {
  return (
    <div>
        <Container style={{ width: 600, marginTop: 30, marginBottom: 75 }}>
            <Grid centered>
                <Grid.Row >
                    <Grid.Column>
                    <Header as="h3">About us</Header>        
                        <p>
                            <b>Kinscare is inspired from countless observations at a close range of caregivers searching for jobs and providers searching for caregivers.</b>  What makes Kinscare different is our focus of creating a match between caregivers, aides, companions and providers.  Our goal is to make our service easy to use, cheaper and faster in creating potential matches than all the alternaltives.  
                        </p>
                        <p>    
                            <b>It is worth repeating that Kinscare's focus is matching caregivers, aides and companions with providers.</b>  To achieve immediacy and ease of use, Kinscare relies on SMS text, phone call, and the ol' email to alert you about a possible match.  We adopted SMS text messaging and phonecall in Kinscare because they are channels familiar to everyone and deliver notifications promptly.  
                        </p> 
                        <p>
                           <b>Are you a caregiver looking for a job?  Are you a provider looking for a caregiver?  Sign up now by clicking the button below to start your search for FREE.</b>

                        <Container textAlign='center'>
                            {/* <Button color="green" size="massive" as={Link}  to="/signup">Sign up now</Button> */}
                            <Grid.Row>
                                <Grid.Column style={{ maxWidth: 500, marginTop: 20, marginBottom: 10 }}>
                                    <Button                
                                        primary
                                        id="sign-up"
                                        content='Find caregivers'
                                        icon='sign-in'
                                        labelPosition='right'
                                        as={Link}  
                                        to="/signup"
                                    />
                                
                                    <Button                    
                                        primary
                                        id="sign-up"
                                        content='Search for jobs'
                                        icon='sign-in'                                 
                                        labelPosition='right'
                                        as={Link}  
                                        to="/signup"                                
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Container>       
                        </p>
                    </Grid.Column>
                </Grid.Row>
                
            </Grid>
        </Container>
    </div>
    
  )
}

export default About