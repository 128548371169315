import { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { isAnon } from '../../utils/isAnon'
import { 
  Button, 
  Checkbox,
  Divider,
  Form, 
  Message,
  Grid,
  Header as SemanticHeader,
  Radio,
  Segment,
} from "semantic-ui-react"
import * as Realm from 'realm-web'
import TagManager from 'react-gtm-module';

const SignUpUI = ( { mongoContext: { app, client, setUser, setUserData, user }, form : { onChange, registerFormValid, tosCheck, form, passwordCheck }} ) => {    

    //initiate error state
    const [ error, setError ] = useState({        
        message: "",
        error_state: false
    })
    const [ loading, setLoading ] =useState(false)

    const history = useHistory()  

    useEffect(() => {        

        const routeUser = async(user) => {
            //refresh user
            await user?.refreshCustomData()

            //check if the user is authenticated anonymously or not            
            if(!isAnon(user)){
                switch(user?.customData?.role){
    
                    case 'caregiver':                    
                        
                            if(user?.customData?.complete) { 
                                //if the caregiver has a cv/resume in DB, direct caregiver to view jobs
                                history.push('/vitae/jobs/all') 
                            } else {
                                //else direct the caregiver to add cv
                                history.push('/vitae/current')
                            } 
                        break;
                    case 'provider':
    
                        if(user?.customData?.trial){
                            //direct user to provider to candidate listings if the provider is in trial phase
                            history.push('/provider/candidates/all')
                        } else {
                            //check if the provider is a subscriber
                            if(user?.customData?.subscriber ){
                                //if provider is a subscriber, direct provider to caregivers' listings
                                //otherwise have the provider view subscription plans
                                history.push('/provider/candidates/all')
                            } else history.push('/provider/plans/view')
                        }               
    
                        break;
                    
                    case undefined :
                
                        //if user is authenticated and without a role
                        //ask user to select role
                        if(!user?.customData?.role ) history.push("/select")
    
                        break;
                         
                } 
            } 
            
            // else {
            //     history.push('/signup')
            // }
        }
  
        routeUser(user)      
    }, [app, client, user ])

    /**
     * This function adds user to the 
     * @param { Object | email } payload - email used by user to sign up
     * @param { Object | tel } payload - tel of the user during email/password authentication
     * @param { Object | userId } payload - user id after log in credentials
     * @param { Object | role } payload - role of the user
     * @return { None }
     */

    const createUserDuringRegistration = async (payload) => {

        setLoading(true)
     
        //call with function
        const { data } = await user.callFunction("web_add_social_user_custom_data", payload)     
       //refresh current user 
        await app.currentUser.refreshCustomData()      
        
        if(payload.auth_mode === "local-userpass" ){

            const tagManagerArgs = {
                dataLayer: {
                    event: `sign_up`,
                    added: new Date(),
                    authMode: payload.auth_mode,                   
                    role: `${payload.role}`,
                    type: 'Web',
                    userId: payload.userID      
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)

        }       

        //subscribe user to mailchimp
        const mcData = (payload.auth_mode === "local-userpass" ) ?  {
            type: 'sign up',
            email: payload.email.trim(),
            tel: payload.tel.trim(),
            userId: payload.userID,              
            channel: "Web",
            role: payload.role,
            auth_mode: payload.auth_mode
        } : {
            type: 'sign up',
            email: payload.email,                
            userId: payload.userID,
            fname: payload.fname,
            lname: payload.lname,
            channel: "Web",
            auth_mode: payload.auth_mode            
        }

        await user?.callFunction('mailchimp_subscribe_user', mcData) 
         
        setLoading(false)

        //update user's data
        setUserData(data)            
         //set user
        setUser( app.currentUser )
            
    }

    const fbSignUp = async () => {
        
        // The redirect URI should be on the same domain as this app and
        // specified in the auth provider configuration.
        const redirectUri = process.env.NODE_ENV==="production" ? "https://www.kinscare.org/oauthredirect" : "localhost:3000/oauthredirect";

        const credentials = Realm.Credentials.facebook(redirectUri)

        //get the user
        await app.logIn(credentials)        
    
        try{            
           
            //create payload
            const payload = {
                email : app.currentUser.profile.email, 
                userID: app.currentUser.id, 
                fname : app.currentUser.profile.firstName, 
                lname: app.currentUser.profile.lastName,
                auth_mode : "oauth2-facebook",
                facebookId:  user._profile.identities[0]['id']
            } 
            
            createUserDuringRegistration(payload)            
   
        } catch (err){
            //capitalize the first letter of the error message before sending to user         
            setError({
                message: err.error.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign in instead.',
                error_state: true
            })          
        }          
    }

    const googleSignUp = async () => {
        
        // The redirect URI should be on the same domain as this app and
        // specified in the auth provider configuration.
        const redirectUri = process.env.NODE_ENV==="production" ? "https://www.kinscare.org/oauthredirect" : "localhost:3000/oauthredirect";

        const credentials = Realm.Credentials.google(redirectUri)

        //get the user
        await app.logIn(credentials)        
    
        try{            
           
            //create payload
            const payload = {
                email : app.currentUser.profile.email, 
                userID: app.currentUser.id, 
                fname : app.currentUser.profile.firstName, 
                lname: app.currentUser.profile.lastName,
                auth_mode : "oauth2-google",
                googleId:  user._profile.identities[0]['id']
            } 
            
            createUserDuringRegistration(payload)            
   
        } catch (err){
            //capitalize the first letter of the error message before sending to user         
            setError({
                message: err.error.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign in instead.',
                error_state: true
            })          
        }          
    }

    const onSubmit = async (e) => {    

        e.preventDefault()   
        
        try{     
            //convert email to lower case   
            const email = form.email.toLowerCase()
            //register user
            await app.emailPasswordAuth.registerUser(email, form.password) 
            //get user credentials
            const credentials = Realm.Credentials.emailPassword(email, form.password)
            //login user
            await app.logIn(credentials)

            //create user settings
            const payload = {
                tel: form.tel, 
                role: form.role, 
                userID: app.currentUser.id, 
                email,
                auth_mode: "local-userpass"              
            }            

            createUserDuringRegistration(payload)  
        } catch (err){

            //capitalize the first letter of the error message before sending to user         
            setError({
                message: err?.error?.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign in instead.',
                error_state: true
            })
        }            
    }  
   
  return (
    <div> 
        <Grid centered>
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 600, marginTop: 20 }}>                       
                    <SemanticHeader as='h2'>Register Here</SemanticHeader>

                    <Segment>
                        <Form onSubmit={onSubmit} id='web_user_sign_up'>
                            {/* <Form.Field>
                                <SemanticHeader as='h3'>Are you looking for a: </SemanticHeader>
                            </Form.Field> */}
                            <Form.Field>
                               
                                <Radio
                                    label = 'I AM A CAREGIVER LOOKING FOR A JOB'
                                    name = 'role'
                                    checked = {form.role==='caregiver'}
                                    value = "caregiver"
                                    onChange = { onChange }
                                    type='radio'
                                    required
                                />
                                 
                            </Form.Field>
                            <Form.Field>
                               
                                <Radio
                                    label='I AM A PROVIDER SEARCHING FOR CAREGIVER(S)/NACs'
                                    name='role'
                                    checked={form.role==='provider'}
                                    value= "provider"
                                    type='radio'
                                    onChange={ onChange }
                                    required
                                />
                            </Form.Field>        
                            <Form.Field>
                                <Form.Input
                                    value={ form.tel || "" }
                                    onChange={onChange}
                                    name="tel"
                                    type="tel"
                                    placeholder="Enter your mobile phone number"
                                    label="Mobile number"                              
                                    required
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    value={ form.email || "" }
                                    name="email"
                                    type="email"
                                    placeholder="Enter your email address"
                                    label="Email"
                                    onChange={ onChange }                               
                                    required
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    value={ form.password || "" }
                                    onChange={onChange}
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    label="Password"
                                    minLength="6"                                
                                    required
                                />
                            </Form.Field>
                            { passwordCheck() && (<Message negative>
                                <Message.Header>Your password and confirm password must match </Message.Header>                            
                            </Message>) }

                            <Form.Field>
                                <Form.Input
                                    value={form.password2 || ""}                                   
                                    type="password"
                                    name="password2"
                                    placeholder="Confirm password"
                                    label="Confirm Password"
                                    onChange={ onChange }
                                    minLength="6"                               
                                    required
                                />
                            </Form.Field>
                            <Form.Field>                                
                                <Checkbox 
                                    name='terms_policy'
                                    value={form.terms_policy}
                                    onChange={ onChange }
                                    label={<label>I accept the <Link to='/terms'>Terms of Service</Link></label>}
                                    required
                                />
                            </Form.Field>
                            { tosCheck() && (<Message negative>
                                <Message.Header>Please agree to our term of service </Message.Header>                            
                            </Message>) }

                            <Button
                                disabled={registerFormValid }
                                fluid
                                loading={loading}
                                id="user_local_sign_up"
                                primary
                                type="submit"
                            >
                            Sign up
                            </Button>
                        </Form>                
                    </Segment>
                    { error.error_state && (<Message centered negative>
                            <Message.Header> {error.message} </Message.Header>                            
                    </Message>) }
                    <Segment>
                        <span className="bold">  Already have an account? <Link to="/signin" >Login</Link> </span>.
                    </Segment>
                
                    <Divider horizontal>Or</Divider>
                            
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 500, marginBottom: 50 }}>
                    <Button                
                        color='blue'
                        content='Continue with Facebook'
                        icon='facebook f'
                        labelPosition='right'
                        id="user_facebook_sign_up"
                        onClick={ fbSignUp  }
                    />
                   
                    <Button                    
                        color='red'
                        content='Continue with Google'
                        icon='google plus g'
                        labelPosition='right'
                        id="user_google_sign_up"
                        onClick={ googleSignUp }
                    />
                </Grid.Column>
            </Grid.Row>
            
        </Grid>   
          
    </div>
  )
}

export default SignUpUI

//google