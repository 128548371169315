import { useState } from 'react'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    const [ form, setForm ] = useState({      
        password: '',
        password2: ''
    })      
    
    //grab user's input
    const onChange = (e, { name, value }) => {
       
        setForm( state => ({
            ...state,
            [name]: value
        }))
    };
    //make sure confirm password matches password
    const passwordCheck = () => {
        return !(form.password === form.password2)      
    }     

    //if password, password2 and email are filled out,
    //it returns true and form can be submitted
    const registerFormValid = 
        !(form.password2 === form.password) ||  
        !form.password?.length  ||
        !form.password2?.length


    return { form, registerFormValid, onChange, passwordCheck }
}