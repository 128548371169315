import React from 'react'
import { Menu, Image, Button, Icon, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { isAnon } from '../utils/isAnon';

const Header = ({ user, userData }) => {   
   // console.log('user ', user)
    console.log('is anonymous ', !isAnon(user), 'environment ', process.env.NODE_ENV )
    return (
        <Container>
        <Menu secondary pointing stackable>
        <Image  />

        <Menu.Item as={Link} to="/" style={{ fontSize: 24 }}>
          Kinscare
        </Menu.Item> 
          { !isAnon(user) && 
            (<Menu.Item position="right" style={{ fontSize: 18 }}>
              <Button as={Link} to="/select" id="header_home_icon" primary icon>
                <Icon name="home"></Icon>
                Home
              </Button>
            </Menu.Item>)
          }          

          { 
            Object.keys(userData)?.length===0 && isAnon(user) &&
            (
              <>
                <Menu.Item position="right" as={Link} to="/openings" style={{ fontSize: 18 }}>
                  jobs
                </Menu.Item>
                
              </>
            )          
          }

          { 
            Object.keys(userData)?.length > 0 && (userData?.complete) && (user?.identities[0]?.providerType !== 'anon-user') &&
            
            (
              <>
                <Menu.Item position="right" as={Link} to="/openings"  style={{ fontSize: 18 }}>
                  jobs
                </Menu.Item>
                
              </>
            )          
          }    

          <Menu.Item as={Link} to="/about" id="header_about" style={{ fontSize: 18 }}>
                  about
          </Menu.Item>      
         
          { isAnon(user) && 
            (<Menu.Item >
              <Button as={Link} to="/signin" id='header_sign_in' primary basic icon>
                <Icon name="sign-in"></Icon>
                Sign in
              </Button>
            </Menu.Item>)
          }

          { isAnon(user) &&
            (<Menu.Item >
              <Button as={Link} to="/signup" id='header_sign_up' primary basic icon>
                <Icon name="signup"></Icon>
                Sign up
              </Button>
            </Menu.Item>)
          }
       
          { !(user?.identities[0]?.providerType === 'anon-user') && 
            (<Menu.Item>           
              <Button as={Link} to="/signout" id='header_sign_out' color="red" basic icon>
                <Icon name="sign-out"></Icon>
                Logout
              </Button>
            </Menu.Item>)
          }
        
        </Menu>
      </Container>
    )
}

export default Header
