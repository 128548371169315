import React from 'react'
import {
    Grid, List, Message, Divider, Item, Header, Placeholder
} from "semantic-ui-react"
import { Link, useParams } from "react-router-dom"
//import CaregiverPanel from '../../../components/CaregiverPanel'

const CandidateListings = ({ candidates, error, loading, search}) => {
   
    //get type
    const { type } = useParams()

    return (      
        // <Container text style={{ marginTop: 30, marginBottom: 75 }}>            
            <Grid stackable style={{ marginTop: 10, marginBottom: 50 }}>
                <Grid.Row>                  
                    <Grid.Column width={16}>
                    { loading && 
                        <Placeholder>
                            <Placeholder.Header >
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    }

                    {!loading && !error && candidates?.length === 0 && (
                        <Message content="No candidates to show at the moment." />
                    )}

                    {error && (
                        <Message content="Oops, something went amiss!" />
                    )}

                    <List>
                       
                        { type === 'all' && (
                            <>
                            <Header as='h3'>Caregivers seeking jobs</Header> 

                            </>
                        
                        )}
                        { type === 'favorites' && <Header as='h3'>Saved caregivers</Header> }
                        { search && <Header as='h3'>Search results</Header> }
                        { candidates?.length > 0 && candidates?.map((candidate, index) => (


                            <Item key={index}>
                                <Item.Content verticalAlign='left'>
                                <Divider />  
                                    <Item.Header><Link to={`/provider/view/candidate/${candidate.userID}`} ><Header as="h4" color="blue">{ candidate?.fname} {candidate?.lname}</Header></Link></Item.Header>
                                    <Item.Description >
                                        <span><strong><i>License(s): ~~</i> </strong> </span>
                                        <List divided horizontal relaxed>    
                                            { candidate?.licenses?.map((license, index) => (
                                                <List.Item key={index}>{license}</List.Item>
                                            ))}
                                        </List>           
                                    </Item.Description>
                                    <Item.Description>
                                     <span><strong><i>Availability: ~~</i> </strong> </span>
                                        <List divided horizontal relaxed>
                                            { candidate?.availability?.map((availability, index) => (
                                                <List.Item key={index}> {availability}</List.Item>
                                            ))}                                        
                                        </List> 
                                    </Item.Description>
                                    <Item.Extra>

                                    <Item.Description>Resides {candidate.distance} within miles</Item.Description>
                                    </Item.Extra>
                                </Item.Content>
                            </Item>
                        ))
                        }
                        
                    </List>

                    </Grid.Column>
                </Grid.Row>
            </Grid>            
        //{/* </Container>         */}
    )
}

export default CandidateListings
