import React from 'react'
import CaregiverPanel from '../../../components/CaregiverPanel'
import JobListings from '../read/JobListingsUI'
import {
    Form,
    SubmitButton,  
    Checkbox    
} from 'formik-semantic-ui-react'
import { Link } from "react-router-dom"
import {
    Button,
    Form as SemanticForm,
    Message,
    Container,
    Icon,
    Grid,  
    Segment,
    Header as SemanticHeader,
} from "semantic-ui-react"
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import TagManager from 'react-gtm-module';

const JobSearchPanelUI = ({  mongoContext: { user, userData } }) => {     
   
    //set initial states for search, openings, error, loading
    const [ search, setSearch ] = React.useState(false)
    const [ openings, setOpenings ] = React.useState([])
    const [ error, setError ] = React.useState(null)
    const [ loading, setLoading ] = React.useState(true)
    //const [ pipeline, setPipeline ] = React.useState(null)

    //get type
    const { type } = useParams()

    React.useEffect(() => {

        /**
         * This function fetches + sorts jobs according to proximity to the geocode of the caregiver's zipcode
         * It also let a caregiver fetch jobs s/he marked as favorite in the past
         */

        const fetchJobs = async () => {
            
            try{
                //set the pipeline for favorite jobs
                if(!search){

                    if(type === 'all'){

                        const pipeline = userData?.geocode_address?.coordinates ? [
                            { 
                                "$geoNear": {
                                    "near": { 
                                        "type": "Point",
                                        "coordinates":[userData?.geocode_address?.coordinates?.lng, userData?.geocode_address?.coordinates?.lat]
                                    },
                                    "query": { draft: false },
                                    "spherical": true,
                                    "distanceField": "distance",
                                    "distanceMultiplier": 1/1609                             
                                }
                            }, 
                            {
                                "$project" : { 
                                    //applicants: 0,
                                    licenses: 1,
                                    schedule: 1,
                                    userID: 1,
                                    created: 1,
                                    title: 1,
                                    distance: { $ceil: "$distance" }
                                }
                            }, {
                                "$project" : { 
                                    created: 1,
                                    distance: 1,
                                    title: 1,
                                    licenses: 1,
                                    schedule: 1
                                }
                            },
                            {
                                '$sort': {
                                    distance: 1, 'created': -1
                                }
                            }               
                        ] : [
                            {
                                "$project" : { 
                                    created: 1,
                                    distance: 1,
                                    title: 1,
                                    licenses: 1,
                                    schedule: 1
                                }
                            },
                            {
                                '$sort': {
                                    distance: 1, 'created': -1
                                }
                            } 
                        ]

                        const { err, data } = await user?.callFunction('web_fetch_jobs', pipeline)

                        if(err){

                            setError(err)

                        } else  setOpenings( data )
                        
                        setLoading(false)
                    } 

                    if(type === 'favorites'){
                       
                        if(!userData?.favorite_jobs || userData?.favorite_jobs?.length === 0){                     
                            setOpenings([])
                            setError(null)
                            setLoading(false)
                        } else {
                            setOpenings(userData?.favorite_jobs)                            
                            setError(null)
                            setLoading(false)     
                        }                            
                    }              
                }              
                
            }catch(err){
                setError(err)
                console.log(`err `, err)
            }
        }

        if(user?.customData?.complete) fetchJobs()
    }, [ type, user ])
    
    const jobSearchSchema = Yup.object().shape({    
        licenses: Yup.array().min(1, "Select at least 1 license.  If you don't have one, select 'None'."),//.required('required'), //.min(1, "at least 1")
        schedule:  Yup.array().min(1, "Select at least 1 schedule you are available to work."),//.required('required'),
        //type_of_setting:  Yup.array().min(1, "Select at least 1 type_of_setting you are available to work.")//.required('required'),     
    })  

    // console.log('client in the userData form ', client, 'user ', user)
    return (     
        <div>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
                <Grid  stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <CaregiverPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                        {
                            user?.customData?.complete ? 
                            <>
                                <Grid.Row>
                                    <SemanticHeader as='h3'>Search jobs</SemanticHeader>
                                    <Segment> 
                                        <Formik
                                            initialValues = {{
                                                licenses: [],
                                                schedule: []                               
                                            }}

                                            validationSchema = {jobSearchSchema}

                                            onSubmit={ async (values, { resetForm, setSubmitting }) => {       
                                                
                                                
                                                //return
                                                //set search to true
                                                setSearch(true)              
                                                setSubmitting(true)                           

                                                /**
                                                 *  the searchPipeline aggregation pipeline that sorts jobs according to caregiver supplied parameters
                                                 */
                                                const searchPipeline = [
                                                    {
                                                        $match : {
                                                            $and:[
                                                                { "draft": false },
                                                                { "schedule":{ $in: values.schedule}  },
                                                                { "licenses": { $in: values.licenses} }
                                                            ]
                                                        }
                                                    },
                                                    {
                                                        $project : { applicants: 0 }
                                                    },
                                                    {
                                                        $sort: {
                                                            'created': -1
                                                        }
                                                    } 
                                                ]
                                                                                            
                                                setOpenings([])
                                                
                                                const {err, data} = await user?.callFunction('web_fetch_jobs', searchPipeline )
                                                
                                                if(err){
                                                    setError(err)
                                                } else {


                                                    setOpenings(data)

                                                    //collect and send metric about the length of caregiver search results
                                                    const tagManagerArgs = {
                                                        dataLayer: {
                                                            event: `caregiver_search_jobs`,  
                                                            licenses: values.licenses.toString(),                                                        
                                                            schedule: values.schedule.toString(),                                                             
                                                            searchResults: data.length,
                                                            userId: user?.id                                                        
                                                        }           
                                                    }
                                            
                                                    TagManager.dataLayer(tagManagerArgs)
                                                    setError(null)
                                                }
                                                
                                                setLoading(false)
                                               
                                                resetForm()
                                                setSubmitting(false)
                                            }}                        
                                        > 
                                        {
                                            ({ errors, touched, values,  handleBlur }) => (
                                            
                                                <Form>   
                                                    
                                                    { errors.full_name ? (<Message>{ errors.full_name}</Message>) : null }      
                                                    <label><b>What license(s) do you have?</b></label>
                                                    <SemanticForm.Group widths="equal" inline required>    
                                                                                            
                                                        <Checkbox
                                                            value="CNA"
                                                            name="CNA"
                                                            label="CNA or NAC" 
                                                            onChange={() => {
                                                                    if(values.licenses.includes("CNA")){
                                                                        const index = values.licenses.indexOf("CNA");
                                                                        if (index > -1) {
                                                                            values.licenses.splice(index, 1);
                                                                        }
                                                                    } else values.licenses.push("CNA")
                                                                }                                                   
                                                            }                                                 
                                                        />
                                                        <Checkbox
                                                            value="HCA"
                                                            name="HCA"
                                                            label="HCA"        
                                                            onChange={() => {
                                                                    if(values.licenses.includes("HCA")){
                                                                        const index = values.licenses.indexOf("HCA");
                                                                        if (index > -1) {
                                                                            values.licenses.splice(index, 1);
                                                                        }
                                                                    } else values.licenses.push("HCA")
                                                                }                                                   
                                                            }                                                                        
                                                        />
                                                        <Checkbox
                                                            value="NAR"
                                                            name="NAR"
                                                            label="NAR"  
                                                            onChange={() => {
                                                                    if(values.licenses.includes("NAR")){
                                                                        const index = values.licenses.indexOf("NAR");
                                                                        if (index > -1) {
                                                                            values.licenses.splice(index, 1);
                                                                        }
                                                                    } else values.licenses.push("NAR")
                                                                }                                                   
                                                            }           
                                                        />
                                                        <Checkbox                                               
                                                            value="None"
                                                            name="None"
                                                            label="None"                                             
                                                            onChange={() => {
                                                                    if(values.schedule.includes("None")){
                                                                        const index = values.schedule.indexOf("None");
                                                                        if (index > -1) {
                                                                            values.schedule.splice(index, 1);
                                                                        }
                                                                    } else values.schedule.push("None")
                                                                }                                                   
                                                            }                  
                                                        />
                                                    </SemanticForm.Group>                                       

                                                    {/* {errors.certifications && <Message>{errors.certifications}</Message>}   */}
                                                    <label><b>What job opening shifts do you want?</b></label><br/>
                                                    <SemanticForm.Group widths="equal">                                            
                                                        <Checkbox
                                                            value="Full time"
                                                            name="full_time"
                                                            label="Full time"
                                                            onChange={() => {
                                                                    if(values.schedule.includes("Full time")){
                                                                        const index = values.schedule.indexOf("Full time");
                                                                        if (index > -1) {
                                                                            values.schedule.splice(index, 1);
                                                                        }
                                                                    } else values.schedule.push("Full time")
                                                                }                                                   
                                                            }                                                                           
                                                        />
                                                        <Checkbox
                                                            value="Part time"
                                                            name="part_time"
                                                            label="Part time"
                                                            onChange={() => {
                                                                    if(values.schedule.includes("Part time")){
                                                                        const index = values.schedule.indexOf("Part time");
                                                                        if (index > -1) {
                                                                            values.schedule.splice(index, 1);
                                                                        }
                                                                    } else values.schedule.push("Part time")
                                                                }                                                   
                                                            }
                                                                                    
                                                        />
                                                        <Checkbox
                                                            value="Weekends"
                                                            name="weekends"
                                                            label="Weekends" 
                                                            onChange={() => {
                                                                    if(values.schedule.includes("Weekends")){
                                                                        const index = values.schedule.indexOf("Weekends");
                                                                        if (index > -1) {
                                                                            values.schedule.splice(index, 1);
                                                                        }
                                                                    } else values.schedule.push("Weekends")
                                                                }                                                   
                                                            }
                                                                        
                                                        />
                                                        <Checkbox
                                                            value="On Call"
                                                            name="on_call"
                                                            label="On Call"                                             
                                                            onChange={() => {
                                                                if(values.schedule.includes("On Call")){
                                                                    const index = values.schedule.indexOf("On Call");
                                                                    if (index > -1) {
                                                                        values.schedule.splice(index, 1);
                                                                    }
                                                                } else values.schedule.push("On Call")
                                                                }                                                   
                                                            }                                                                     
                                                        />
                                                        <Checkbox
                                                            value="Live In"
                                                            name="live_in"
                                                            label="Live In"                                             
                                                            onChange={() => {
                                                                    if(values.schedule.includes("Live In")){
                                                                        const index = values.schedule.indexOf("Live In");
                                                                        if (index > -1) {
                                                                            values.schedule.splice(index, 1);
                                                                        }
                                                                    } else values.schedule.push("Live In")
                                                                }                                                   
                                                            }                                                                      
                                                        />
                                                    </SemanticForm.Group> 

                                                    {/* <label><b>Where do you want to work?</b></label> 
                                                    <SemanticForm.Group widths="equal">                                            
                                                        <Checkbox
                                                            value="Hospital"
                                                            name="hospital"
                                                            label="Hospital/Clinic"
                                                            onChange={() => {
                                                                    if(values.type_of_setting.includes("Hospital")){
                                                                        const index = values.type_of_setting.indexOf("Hospital");
                                                                        if (index > -1) {
                                                                            values.type_of_setting.splice(index, 1);
                                                                        }
                                                                    } else values.type_of_setting.push("Hospital")
                                                                }                                                   
                                                            }             
                                                        />
                                                        <Checkbox
                                                            value="Assisted Living/Nursing Home"
                                                            name="alh/nh"
                                                            label="Assisted Living/Nursing Home"
                                                            onChange={() => {
                                                                    if(values.type_of_setting.includes("ALH/NH")){
                                                                        const index = values.type_of_setting.indexOf("ALH/NH");
                                                                        if (index > -1) {
                                                                            values.type_of_setting.splice(index, 1);
                                                                        }
                                                                    } else values.type_of_setting.push("ALH/NH")
                                                                }                                                   
                                                            }                      
                                                        />
                                                        <Checkbox
                                                            value="Adult Family/Boarding Home"
                                                            name="afh"
                                                            label="Adult Family/Boarding Home" 
                                                            onChange={() => {
                                                                    if(values.type_of_setting.includes("Adult Family/Boarding Home")){
                                                                        const index = values.type_of_setting.indexOf("Adult Family/Boarding Home");
                                                                        if (index > -1) {
                                                                            values.type_of_setting.splice(index, 1);
                                                                        }
                                                                    } else values.type_of_setting.push("Adult Family/Boarding Home")
                                                                }                                                   
                                                            }       
                                                        />
                                                        <Checkbox
                                                            value="Home Care Agency"
                                                            name="hca"
                                                            label="Home Care Agency"                                             
                                                            onChange={() => {
                                                                    if(values.type_of_setting.includes("Home Care Agency")){
                                                                        const index = values.type_of_setting.indexOf("Home Care Agency");
                                                                        if (index > -1) {
                                                                            values.type_of_setting.splice(index, 1);
                                                                        }
                                                                    } else values.type_of_setting.push("Home Care Agency")
                                                                }                                                   
                                                            }                                                                    
                                                        />
                                                        <Checkbox
                                                            value="Other"
                                                            name="other"
                                                            label="Other"                                             
                                                            onChange={() => {
                                                                    if(values.type_of_setting.includes("Other")){
                                                                        const index = values.type_of_setting.indexOf("Other");
                                                                        if (index > -1) {
                                                                            values.type_of_setting.splice(index, 1);
                                                                        }
                                                                    } else values.type_of_setting.push("Other")
                                                                }                                                   
                                                            }                                                                     
                                                        />
                                                    </SemanticForm.Group>                                              */}
                                
                                                    <SubmitButton 
                                                        fluid        
                                                        id="caregiver_search_for_jobs"                              
                                                        primary
                                                        type="submit"
                                                        //loading={loading}
                                                    >
                                                        Find jobs
                                                    </SubmitButton>
                                                </Form> 
                                            )
                                        }                            
                                        </Formik>                           
                                    </Segment>  
                                </Grid.Row>
                                <Grid.Row>
                                    <JobListings openings={openings} loading={loading} error={error} userData={userData}/>
                                </Grid.Row>    
                            </> 
                            :
                            (<Message info floating className='add_resume'>
                                <Message.Header> Add your information about you</Message.Header>
                                <p>To get the most out of Kinscare, add your information in order to:</p>
                                <ul> 
                                    <li>add your qualifications e.g. license, certifications and availability</li> 
                                    <li>search job openings and contact hiring employers</li>                                            
                                    <li>match with employers nearest you</li>
                                    <li>let employers know how to contact you</li>                               
                                </ul>
                                <Button as={Link} to='/vitae/create' color='red' id="caregiver_add_resume">
                                <Icon name="plus square"></Icon>
                                    Add your resume
                                </Button>
                            </Message>)
                        }                      
                        </Grid.Column>
                    </Grid.Row>                  
                </Grid>
            </Container>            
        </div>
    )
}

export default JobSearchPanelUI
