import React from 'react'
import { Segment } from 'semantic-ui-react'
import { Link } from "react-router-dom"

const CaregiverPanel = ({ user }) => {

    React.useEffect(() => {

        async function checkUserCustomData() {

            //refresh the user data 
            await user?.refreshCustomData()
        }

        checkUserCustomData() 
    }, [user])
    return (

        <>
        {
             user?.customData?.complete ?

             <Segment.Group raised>                          
                <Segment><Link to='/vitae/jobs/all'>Find jobs</Link></Segment>
                <Segment><Link to='/vitae/details'>View resume</Link></Segment>
                <Segment><Link to='/vitae/jobs/favorites'>Saved jobs</Link></Segment>   
                <Segment><Link to='/vitae/read/resources'>Training resources</Link></Segment> 
             </Segment.Group>
                :
             <Segment.Group raised>                          
                <Segment><Link to='/user/current'>Add your resume</Link></Segment>
             </Segment.Group>
        }

       
        </>
        
        // </Grid.Column>
    )
}

export default CaregiverPanel
