import { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import {
    Form,     
    Button, 
    Divider,   
    Grid,
    Message,
    Header as SemanticHeader,
    Segment,
  } from "semantic-ui-react";


  
const ResetPasswordUI = ({ mongoContext: { app }}) => {

    //initiate state
    const [ email, setEmail ] = useState("")
    const [ error, setError ] = useState({        
        message: "",
        error_state: false
    })
    
    //get history for routing   
    const history = useHistory()

    const onSubmit = async(e) => {
        //prevent default behavior
        e.preventDefault()

        try {
            console.log("reset password email....: ->", await app.emailPasswordAuth.sendResetPasswordEmail({ email : email.toLowerCase() }))

            alert('Please check your email to complete resetting your email.')

            history.push(`/reset/confirmation`)
        } catch(err){
           
            setError({
                message: err.error.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign up instead.',
                error_state: true
            })            
        }
    }
  
    return (
        <div>   
            <Grid centered>
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 550, marginTop: 20 }}>
                        <SemanticHeader as='h2'>Please enter your email</SemanticHeader>
        
                        <Segment>
                            <Form onSubmit= {onSubmit}>                                       
                                <Form.Field>
                                    <Form.Input                                      
                                        onChange={e => setEmail(e.target.value)} 
                                        name="email"
                                        size="large"
                                        type="email"
                                        placeholder="Enter your email"
                                        label="Email"                     
                                        required
                                    />
                                </Form.Field>                                    

                                <Button
                                    onClick={onSubmit}
                                    
                                    fluid
                                    //   loading={loading}
                                    primary
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Form>
                    
                        </Segment>
                        { error.error_state && (<Message centered negative>
                            <Message.Header> {error.message} </Message.Header>                            
                        </Message>) }
                            <Segment>
                                <span className="bold">  Don't have an account? <Link to="/signup">Register for one.</Link> </span>
                            </Segment>                    
                        <Divider horizontal hidden />                                
                    </Grid.Column>
                </Grid.Row>                          
            </Grid>   
                
        </div>
    )
}
  
export default ResetPasswordUI
  
  //google