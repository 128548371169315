import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Card, Divider, Form, Grid, Icon, Header, Message, Modal } from "semantic-ui-react";
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import EmployerPanel from '../../../components/EmployerPanel';
import { Redirect, withRouter } from 'react-router-dom';

const CARD_ELEMENT_OPTIONS = {
    //theme:"stripe", 
    hidePostalCode: true 
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); 

const Wrapper = (props) => (    
    <Elements stripe={stripePromise}>        
        <AccountDetailsUI {...props} />
    </Elements>
)

const AccountDetailsUI = ({ subscription, userData, user, updatePlan }) => {

    const [open, setOpen] = React.useState(false)
    const [clientSecret, setClientSecret] = React.useState('');
    const [messages, _setMessages] = React.useState('');
    const [paymentIntent, setPaymentIntent] = React.useState();
    const [name, setName] = React.useState(userData.name);
    const [loading, setLoading]=React.useState(false)
    const [trialDate, setTrialDate] = React.useState('')
    //const date = new Date(userData?.created)

    function addDays(date, days) {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy.toDateString()
    }   

   
    React.useEffect(() => {
        //if the provider is in trial, set the end date of the trial period
        if(user?.customData?.trial){         

            const date = userData.created;
    
            const newDate = addDays(date, 15)

            setTrialDate(newDate)
            
        }

        //define function to get a user's to fetch stripe client sectret
        const fetchAccountDetails = async () => {

            //get client secret in case user wants to add a payment method
            const data = await user?.callFunction('stripe_pay_provider_posting', { receipt_email: userData.settings.hr_email })

            setClientSecret(data?.clientSecret)
        }

        //if the job id, fetch secret
        fetchAccountDetails()
       
    }, [user])

    // helper for displaying status messages.
    const setMessage = (message) => {
        _setMessages(`${messages}\n\n${message}`);
    } 

    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();      

    if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
        return '';
    }    

    // When the subscribe-form is submitted we do a few things:
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async (e) => {

        e.preventDefault()

        //set loading to true to prevent clicking subscribe button multiple times
        setLoading(true)

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        //const cardElement = elements.getElement("card");     
        console.log('client secret', clientSecret)
        // Use card Element to tokenize payment details
        let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret , {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name
                }
            }
        });

        if(error) {   
            // show error and collect new card details.
            setMessage(error.message);
            setLoading(false)
            return;
        }
        
        setPaymentIntent(paymentIntent);
        setLoading(false)
    }

    if(paymentIntent && paymentIntent.status === 'succeeded') {
       return <Redirect to={{pathname: '/provider/plans/account'}} />
    }

    return (

        <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>
            <Grid stackable columns={2}>  
                <Grid.Row>
                    <Grid.Column width={4}>
                        <EmployerPanel user={user}/>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        
                        <Header as="h4">{userData.name}</Header>
                        <p>{userData.address}<br/>
                        {userData.city}, {userData.zipcode}</p>      
                        <Divider hidden/>
                        { Object.keys(subscription).length > 0 && Object.getPrototypeOf(subscription) === Object.prototype ? (
                        <>
                            {
                                subscription?.description === "Kinshealth Basic Provider Recruiting Plan." && 
                                <Button 
                                    color="red" 
                                    onClick={()=>updatePlan('upgrade')}
                                    id="provider_upgrade_subscription"
                                >
                                <Icon name="chevron circle up"></Icon>
                                    Upgrade to Premium Provider Plan
                                </Button> 
                            }

                            <Divider hidden/>
                            {subscription?.description && <Card fluid style={{ marginTop: 10, marginBottom: 35, maxWidth: 450}}>
                                <p>
                                <strong>Plan: { subscription?.description } </strong><br/>
                                <span>Current period end: {(new Date(subscription?.current_period_end * 1000).toDateString())}</span><br/>
                                Card last4: { subscription?.default_payment_method?.card?.last4 } <br/>
                                Status: { subscription?.status }
                                </p>     

                                <Modal
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                open={open}
                                id="provider_add_extra_card"
                                trigger={<Button color="teal">
                                    <Icon name="credit card"></Icon>
                                        Add payment method</Button>
                                }
                                size="small"
                            >
                                <Modal.Header>Add a card</Modal.Header>
                                <Modal.Content >            
                                    <Form onSubmit={ handleSubmit }>
                                        <label><b>Name on the card </b></label> <br/>                               
                                        <Form.Input
                                            type="text"
                                            name="name"
                                            onChange={(e) => setName(e.target.value)}
                                            value = {userData?.name} 
                                            placeholder="Enter card holder's name."                                            
                                            required
                                        />
                                        <Divider />
                                        <CardElement options={CARD_ELEMENT_OPTIONS}/>
                                        <Divider />
                                        <Button
                                            fluid
                                            loading={loading}
                                            primary
                                            type="submit"
                                            id="provider_add_pay_method"
                                        >
                                        Add payment method
                                        </Button>
                                    </Form>     
                                { messages && <Message negative>
                                    <Message.Header>{messages}</Message.Header>    
                                </Message> }  
                    
                                </Modal.Content>
                                
                                </Modal>                                                   
                            </Card>}  
                           
                            <Divider hidden/>  
                            Need a caregiver? <Link to='/provider/job/create'>Post your job opening now!</Link><br/><br/>                
                            <Link to="/provider/settings/form">Update/view settings</Link><br/><br/>

                            

                            <Divider hidden/>
                            <Divider hidden/>

                            { subscription?.description && 
                                <Button 
                                onClick={()=>updatePlan('pause')} 
                                size='small' 
                                id="provider_pause_subscription"
                                compact  
                                basic 
                                icon>
                                    <Icon name="pause"></Icon> 
                                        Pause Subscription
                                </Button>   
                            }
                        </>) : (
                        <>
                            <Card fluid style={{ marginTop: 10, marginBottom: 35, maxWidth: 450}}>
                                <p>
                                <strong>Plan: Trial Plan of Kinshealth Premium Provider Plan </strong><br/>
                                <span>Current period end: { trialDate }</span><br/>
                                Status: TRIAL
                                </p>                                                        
                            </Card>

                            {/* <Divider hidden/>                   */}
                            <Link to="/provider/settings/form">Update/view settings</Link><br/><br/>
                            <Link to='/provider/plans/view'>View subscription plans</Link>
                        </>                   
                        )}                
                    </Grid.Column>
                </Grid.Row>           
            </Grid>
        </Container>  
    )
}

//   export default AccountSubscription
export default withRouter(Wrapper);