import { useEffect, useState } from 'react'
import {
    Form,
    SubmitButton,  
    Input
} from 'formik-semantic-ui-react'
import { useHistory, useParams, Link } from 'react-router-dom'
import {     
    Button,
    Checkbox,
    Container,
    Divider,
    Grid,
    Header as SemanticHeader,
    Message,
    Segment    
} from 'semantic-ui-react';
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as Realm from 'realm-web'
import TagManager from 'react-gtm-module';

const TwilioSignUpUI = ({ mongoContext: { app, client, setUser, setUserData, user, userData } }) => {   
    
    //initiate the state
    const [ error, setError ] = useState({        
        message: "",
        error_state: false
    })
    const [ loading, setLoading ] = useState(false)

    //get history for routing   
    const history = useHistory()

    //get the id of settings
    const { newUserId } = useParams()    
    //define user collection
    const collection = client?.db('kinshealth').collection('users')
    //define the contacts collection
    const contacts = client?.db('kinshealth').collection('contacts')

    useEffect(() => {             

        if( user?.providerType === "oauth2-facebook" ||
            user?.providerType === "oauth2-google" ||
            user?.providerType === "local-userpass" 
        ){
             //if data role is not null
             if(!user?.customData?.role ) history.push("/select")

             //if the role is caregiver, direct user to caregiver panel
             if(user?.customData?.role === 'caregiver') history.push('/vitae/jobs/all')

             //if the role is employer, direct user to employer
             if(user?.customData?.role === 'provider') history.push(`/provider/candidates/all`)
        }

    }, [app, client, user])    

    //fn to update user after social authentication
    const updateUserAfterSocialAuthentication = async ({ userID, fname, lname, auth_mode, auth_email}) => {

        try{    
            //update user 
            await collection
                .updateOne({ _id: Realm.BSON.ObjectID(newUserId) },
                { 
                    $set: {
                        userID,
                        auth: {
                            "fname" : `${fname}`,
                            "lname": `${lname}`,
                            "mode": auth_mode, 
                            "email": auth_email        
                        },
                        fname,
                        lname
                    }
                },
                //{ upsert: true }
            )
               //add the email from authentication to the document used for custom data
               //if the users first subscribed via SMS text, the telephone from TWILIO will be error proof - therefore use it to fetch custom data document           
     
            //create contact object to add
            const contactObject = userData.role === "provider" ? { userID, auth_mode, auth_email, complete: false, trial: true  }:{ userID, auth_mode, auth_email, complete: false, trial: true }

            //update contacts document
            await contacts
                .updateOne({ cell: userData.settings.cell },
                    { 
                        $set : contactObject
                    }
                )
       
            //this also refreshes the token
            await app.currentUser.refreshCustomData()
            //set currrent user as the user
            setUser( app.currentUser )     
            //update userdata
            setUserData(await collection.findOne({ userID }))    

            
            const tagManagerArgs = {
                dataLayer: {
                    event: `sign_up`,
                    added: new Date(),
                    authMode: auth_mode,                   
                    role: `${user?.customData?.role}`,
                    type: 'Twilio',
                    userId: userID      
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)

            if(auth_email === userData.settings.smsEmail){

                await user?.callFunction('mailchimp_subscribe_user', {
                    type: 'twilio sign up',
                    email: auth_email ,                
                    userId: userID,
                    fname: fname,
                    lname: lname,
                    auth_mode: auth_mode            
                }) 

            } else {
                await user?.callFunction('mailchimp_subscribe_user', {
                    type: 'twilio sign up',
                    email: auth_email ,                
                    userId: userID,
                    fname: fname,
                    lname: lname,
                    settings_email: userData.settings.smsEmail,
                    auth_mode: auth_mode            
                }) 
            }

             

        }catch(err){
            //capitalize the first letter of the error message before sending to user         
            setError({
                message: err?.error?.charAt(0).toUpperCase() + err?.error.slice(1)+'. Sign in instead.',
                error_state: true
            })  
        }        
    }   

    //fn to update user after local authentication
    const updateUserAfterEmailAuthentication = async ({ userID, full_name, auth_mode, auth_email}) => {

        try{
                     
            //update the user collection                         
            await collection.updateOne(
                        { _id: Realm.BSON.ObjectID(newUserId) },
                        { $set : 
                            { 
                                userID,     
                                name: full_name,
                                auth : {
                                    "name" : `${full_name}`,
                                    "mode": auth_mode,
                                    "email": auth_email
                                },                                                                 
                            }
                        },
                       // {upsert: true}           
                    )

            //create contact object to add
            const contactObject = userData.role === "provider" ? { userID, auth_mode, auth_email, trial: true, complete: false }:{ userID, auth_mode, auth_email, complete: false }        

            //update contacts document
            await contacts
                .updateOne(
                { cell: userData.settings.cell },
                { 
                    $set : contactObject
                }
            )
       
            //this also refreshes the token
            await app.currentUser.refreshCustomData()

            
            const tagManagerArgs = {
                dataLayer: {
                    event: `sign_up`,
                    added: new Date(),
                    authMode: auth_mode,                   
                    role: `${user?.customData?.role}`,
                    type: 'Twilio',
                    userId: userID      
                }           
            }
    
            TagManager.dataLayer(tagManagerArgs)
            //set currrent user as the user
            setUser( app.currentUser )     
            //update userdata
            setUserData(await collection.findOne({ userID }))    

            if(auth_email === userData.settings.smsEmail){

                await user?.callFunction("mailchimp_subscribe_user", {
                    type: 'twilio sign up',
                    email: auth_email ,                
                    userId: userID,
                    auth_mode: auth_mode            
                }) 

            } else {
                await user?.callFunction("mailchimp_subscribe_user", {
                    type: 'twilio sign up',
                    email: auth_email ,                
                    userId: userID,
                    settings_email: userData.settings.smsEmail,
                    auth_mode: auth_mode            
                }) 
            }             

        }catch(err){
            //capitalize the first letter of the error message before sending to user         
            setError({
                message: err?.error?.charAt(0).toUpperCase() + err?.error.slice(1)+'. Sign in instead.',
                error_state: true
            })  
        }        
    } 

    //make sure confirm password matches password
    const passwordCheck = ( password, password1) => {
        return !(password === password1)      
    }     

    //if password, password2 and email are filled out,
    //it returns true and form can be submitted
    const registerFormValid = (values) => {

        return !(values.password === values.password1) || 
        !values.password?.length  ||
        !values.password1?.length ||
        !values.email?.length ||
        !(values.terms_policy) ;          
    }

    //this checks that the user has agreed to the term of agreements
    // const tosCheck = (values) => {
    //     return !(values.terms_policy)      
    // }   

    //create 
    const signUpSchema = Yup.object().shape({
        email: Yup.string().email().required('Enter email address.'),//.max(2, 'Full name can only be 6 characters long.'),
        password : Yup.string().required("Please enter password."),
        password1: Yup.string().required("Please confirm password.")
    })  
    
    const onfbSignUp = async () => {        
        // The redirect URI should be on the same domain as this app and
        // specified in the auth provider configuration.
        const redirectUri = process.env.NODE_ENV==="production" ? "https://www.kinscare.org/oauthredirect" : "localhost:3000/oauthredirect" 
        const credentials = Realm.Credentials.facebook(redirectUri)
    
        try{
            //get the user
            await app.logIn(credentials)            
    
            //create payload for updating user that initiates service using SMS
            const payload = { 
                userID: app.currentUser.id, 
                auth_email: app.currentUser.profile.email,  
                fname: app.currentUser.profile.firstName,
                lname: app.currentUser.profile.lastName,
                auth_mode: "oauth2-facebook", 
                facebookId: app.currentUser._profile.identities[0]['id']
            }
            //invoke update user function
            updateUserAfterSocialAuthentication(payload)    
        } catch (err){
                  //capitalize the first letter of the error message before sending to user         
            setError({
                message: err.error.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign in instead.',
                error_state: true
            })    
        }          
    }

    const ongoogleSignUp = async () =>  {    
        // The redirect URI should be on the same domain as this app and
         // specified in the auth provider configuration.
        const redirectUri = process.env.NODE_ENV==="production" ? "https://www.kinscare.org/oauthredirect" : "localhost:3000/oauthredirect" 
        const credentials = Realm.Credentials.google(redirectUri)
    
        try{
            //get the user
            await app.logIn(credentials)
             
            //create payload for updating user that initiates service using SMS
            const payload = { 
                userID: app.currentUser.id, 
                auth_email: app.currentUser.profile.email,  
                fname : app.currentUser.profile.firstName ,
                lname : app.currentUser.profile.lastName,
                auth_mode: "oauth2-google",
                googleId: app.currentUser._profile.identities[0]['id']
            }
            //invoke update user function
            updateUserAfterSocialAuthentication(payload)          
  
        } catch (err){
            console.log('error in sign up ', err)
            //capitalize the first letter of the error message before sending to user         
            setError({
                message: err.error.charAt(0).toUpperCase() + err.error.slice(1)+'. Sign in instead.',
                error_state: true
            })       
        }     
    }

    return (     
        <div>
            <Container style={{ maxWidth: 450, marginTop: 30, marginBottom: 75 }}>
                <Grid centered >  
                    <Grid.Row>                        
                        <Grid.Column width={12}>
                        <SemanticHeader as='h3'>Sign up form</SemanticHeader>
                            <Segment> 
                                <Formik
                                    initialValues ={{                                  
                                        email: userData?.settings?.email ? userData?.settings?.email : "", 
                                        password: "",
                                        password1: ""                                    
                                    }}

                                    validationSchema = { signUpSchema }

                                    enableReinitialize

                                    onSubmit={ async (values, { setSubmitting }) => {

                                        setSubmitting(true)
                                        //convert email to lower case   
                                        const email = values.email.toLowerCase()
                                        //register user
                                        await app.emailPasswordAuth.registerUser(email, values.password) 
                                        //get user credentials
                                        const credentials = Realm.Credentials.emailPassword(email, values.password)
                                        //login user
                                        await app.logIn(credentials)

                                        console.log(`user Data `, userData)

                                        const payload = { 
                                            userID: app.currentUser.id, 
                                            auth_email: email,  
                                            full_name : userData.auth.user_name ? userData.auth.user_name : "",
                                            auth_mode: "local-userpass", 
                                        }
                                        //invoke update user function
                                        updateUserAfterEmailAuthentication(payload) 

                                        setSubmitting(false)
                                    }}                        
                                > 
                                {
                                    ({ errors, touched, values}) => (
                                    
                                        <Form  >   
                                            
                                            <label><b>Enter your email address</b></label>
                                           
                                            <Input
                                                type="email"
                                                name="email"
                                               // placeholder="Enter your email address"                                           
                                               // required
                                            />   
                                            { touched.email && errors.email ? (<Message>{ errors.email}</Message>) : null }      
                                            <label><b>Enter your password</b></label>
                                            <Input
                                                type="password"
                                                name="password"
                                                placeholder="Enter your password"     
                                                minLength="6"                                       
                                               // required
                                            />  
                                            { passwordCheck(values.password, values.password1) && (<Message negative>
                                                <Message.Header>Your password and confirm password must match </Message.Header>                            
                                            </Message>) }
                                            <label><b>Confirm your password</b></label>
                                            <Input
                                                type="password"
                                                name="password1"
                                                placeholder="Confirm your password"     
                                                minLength="6"                                       
                                               // required
                                            />     

                                            <Form.Field>                                
                                                <Checkbox 
                                                    name='terms_policy'
                                                    label={<label>I accept the <Link to='/terms'>Terms of Service</Link></label>}
                                                    required
                                                />
                                            </Form.Field>                                    
                                        
                                            <SubmitButton 
                                                fluid                                    
                                                disabled={ registerFormValid(values)  }                                
                                                loading={ loading }
                                                //onClick={handleSubmit}
                                                primary
                                                id="user_local_sign_up"
                                                type="submit"
                                            >
                                                Sign up
                                            </SubmitButton>
                                        </Form> 
                                    )
                                }                            
                                </Formik>                           
                            </Segment>
                            { error.error_state && (<Message centered negative>
                                <Message.Header> {error.message} </Message.Header>                            
                            </Message>) }
                            
                            <Divider horizontal>Or</Divider>
                        </Grid.Column>
                    </Grid.Row> 

                    <Grid.Row>
                        <Grid.Column style={{ maxWidth: 500, marginBottom: 50 }}>
                            <Button                
                                color='blue'
                                content='Continue with Facebook'
                                icon='facebook f'
                                id="user_facebook_sign_up"
                                // loading={loading}
                                labelPosition='right'
                                onClick={ onfbSignUp  }
                            />
 
                            <Button                    
                                color='red'
                                content='Continue with Google'
                                icon='google plus g'
                                id="user_google_sign_up"
                                // loading={loading}
                                labelPosition='right'
                                onClick={ ongoogleSignUp }
                            />
                        </Grid.Column>
                    </Grid.Row>                 
                </Grid>
            </Container>            
        </div>
    )
}

export default TwilioSignUpUI
