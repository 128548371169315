import React from 'react'
import {  
    Button,
    Container,   
    Grid,
    Header,
    Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom"

const FooterFindCaregiversUI = () => {         

    return (     
        <Container style={{ maxWidth: 650, margin: 50 }}>
            <Grid centered stackable>              
                <Grid.Row>                   
                    <Grid.Column width={8} >                   
                    <Header as='h4'>
                        Finding caregivers
                    </Header>

                    <p>
                        <b>As a provider, you know full well how important caregivers are for the care and the wellbeing of your patients. </b> At the same time, you know how hard it is now, to find and keep caregivers. 
                        <br/><br/>
                        <b>We created Kinscare with a goal of making it easier for providers such as yourself to find and recruit caregivers. </b> To do that, Kinscare focuses on caregivers recruitment only - it is not for medical assistant, nurses, any therapists and/or technicians recruitment.  We believe this focus makes Kinscare superior to all other means of recruiting caregivers. 
                        <br/><br/>
                        <b>You can search caregivers by shift availability and/or license requirements.</b>  To assist you with your search, Kinscare will:
                            <ol>
                                <li>alert you when a new caregiver within 45 miles of your location signs up</li>
                                <li>let you post your job opening and initiate contact with caregivers directly</li>
                                <li>send you a weekly summary of caregivers seeking jobs every Morning morning</li>
                            </ol>
                        
                        <b>You have a job opening?  Sign up and post it now for FREE.</b>
                        <br/> <br/>
                        <Button as={Link} to="/signup" fluid primary icon>
                        <Icon name="sign-in"></Icon>
                            Sign up
                        </Button>                  
                    </p>                                                               
                    </Grid.Column>
                </Grid.Row>                  
            </Grid>
        </Container>
    )
}

export default FooterFindCaregiversUI