import RepostJobFormUI from '../../../layouts/Job/update/RepostJobFormUI'
import React from 'react'
import { useParams } from 'react-router-dom'
import { BSON } from 'realm-web'

const RepostJobPostingFormContainer = ({mongoContext : { client, user, userData }}) => {  
   //declare initial state of the component
   const [ job, setJob ] = React.useState({}) 
   const [ loading, setLoading ] = React.useState(true)
   const [ error, setError ] = React.useState(null) 
   //get job id params from the route path
   const  { jobId } = useParams()   
   //fetch job using user id and job id everytime component loads
    //declare async get job function inside use effect

   const jobs = client?.db('kinshealth').collection('jobs') 

   React.useEffect(() => {
     
    // setLoading(true)
    const getJob = async () => {      
       
      try{

        const results = await jobs?.findOne({ _id: BSON.ObjectId(jobId)})
        console.log('job results ', results)
        setJob(results)
       
        console.log('job ', job)
        setLoading(false)
      } catch (err){
        setLoading(false)

        setError(err)
      }
    }
 
    getJob()
 
   }, [ jobId])
  
  return (    
    <RepostJobFormUI job={job} user={user} loading={loading} error={error} userData={userData} />   
  )
}

export default RepostJobPostingFormContainer