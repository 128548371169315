import React from 'react'
import {
    Card,
    Button,  
    Message,
    Container,
    Grid,  
    Divider,
    Placeholder,
    Icon,
    Item,
    List,
    Header
} from "semantic-ui-react";
import { Link } from 'react-router-dom'
import EmployerPanel from '../../../components/EmployerPanel';

const ViewSinglePostingUI = ({ candidates, job, error, loading, user, deleteJob }) => {   
    
    return (     
        <div>      
            <style>
                {`#p-wrap {
                    white-space: pre-line;
                }`}
            </style>
            <Container style={{ maxWidth: 850, marginTop: 30, marginBottom: 75 }}>              
                <Grid centered stackable columns={2}>  
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <EmployerPanel user={user}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                        {
                            error && !loading && (                                
                                <Message>
                                    <Message.Header>Oops!  Something went amock!</Message.Header>
                                        <p>
                                            We couldn't fetch you the caregiver's resume.  Log out and then back in and try again :(.
                                        </p>
                                </Message>                               
                            )
                        }

                        {
                            loading && !error && (
                                
                                <Placeholder>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                                // </Grid.Column>
                            )
                        }

                        { job && !loading && 
                            (
                                <>
                                    <Card fluid>
                                        <Card.Content>
                                            <Card.Header><span id='provider_job_title'>{ job.title }</span></Card.Header>                                       
                                        </Card.Content> 

                                        <Card.Content>
                                            <Header as='h4'>Licenses</Header> 
                                            <Card.Description>
                                                <List divided horizontal relaxed>
                                                    { job?.licenses?.map((license, index)=> (
                                                        <List.Item key={index}>{license}</List.Item>
                                                    ))}
                                                </List>                                        
                                            </Card.Description>
                                        </Card.Content>              
                                        <Card.Content>
                                            <Header as='h4'>Certifications</Header>
                                            <Card.Description><p id='p-wrap'>{job?.certifications}</p></Card.Description>  
                                        </Card.Content>

                                        <Card.Content>
                                            <Header as='h4'>Description</Header>
                                            <Card.Description><p id='p-wrap'>{job?.description }</p></Card.Description>  
                                        </Card.Content>                                                                    
                                    
                                        <Card.Content>
                                            <Header as='h4'>Availability</Header> 
                                            <Card.Description>
                                                <List divided horizontal relaxed>
                                                    { job?.schedule?.map((schedule, index)=> (
                                                        <List.Item key={index}>{schedule}</List.Item>
                                                    ))}
                                                </List>                                        
                                            </Card.Description>
                                        </Card.Content>

                                        <Card.Content>
                                            <Header as='h4'>Compensation</Header>
                                            <Card.Description> {job?.compensation} </Card.Description>
                                        </Card.Content>
                                        {
                                            job?.mobility==='car_needed' && 
                                            (<Card.Content>
                                                <Header as='h4'>Mobile </Header>  
                                                <Card.Description>
                                                    Job requires caregiver to drive
                                                </Card.Description>                                  
                                            </Card.Content>) 
                                        }  
                                        {
                                            job?.mobility==='no_car_needed' && 
                                            (<Card.Content>
                                                <Header as='h4'>Not mobile</Header> 
                                                <Card.Description>
                                                    Job doesn't require caregiver to drive
                                                </Card.Description>                                      
                                            </Card.Content>) 
                                        } 

                                        <Card.Content>
                                            <Header as='h4'>Job location</Header>
                                            <Card.Description> {job?.contacts?.address}, {job?.contacts?.city}, {job?.contacts?.zipcode} </Card.Description>
                                        
                                        </Card.Content> 
                                        <Card.Content>
                                            <Header as='h4'>How you want to be contacted - [Visit settings tab to change your alert preferences].</Header>
                                            <Card.Description>
                                                <List divided horizontal relaxed>
                                                    { user?.customData?.alert_preferences?.map((preference, index)=> (
                                                        <List.Item key={index}>{preference}</List.Item>
                                                    ))}
                                                </List>
                                            </Card.Description>
                                        </Card.Content> 
                                        {job?.applicants?.length > 0 &&
                                        (<Card.Content>
                                            <Card.Header>Applicants</Card.Header>
                                            <Card.Description>
                                                <List hidden horizontal relaxed>
                                                { job?.applicants?.map((applicant, index)=> (
                                                
                                                    <>
                                            
                                                    <Link to={`/provider/view/candidate/${applicant.userID}`} key={index}><Header as="h4" color="blue">{ applicant?.name}</Header></Link>
                                                
                                                    { applicant?.licenses?.map((license, i)=> (
                                                        <><List.Item key={i}>{license}</List.Item></>
                                                    ))}
                                                <br/>
                                                
                                                    { applicant?.availability?.map((availability, j)=> (
                                                            <List.Item key={j}>{availability}</List.Item>
                                                        ))}                                                
                                                    </>                                                    
                                                ))}
                                                </List>
                                            </Card.Description>
                                        </Card.Content>)
                                        }
                                    </Card>                        
                                    <Divider hidden />                              

                                    <Button as={Link}
                                        to={{
                                            pathname:`/provider/job/form/repost/${job._id}`                                  
                                        }}                            
                                        floated="right" 
                                        color='blue'
                                        id='provider_repost_existing_job'
                                        size='big' 
                                        icon>
                                        <Icon name="copy"></Icon>
                                        Repost job post
                                    </Button>
                                    <Button as={Link}
                                        to={{
                                            pathname:`/provider/job/form/update/${job._id}`                                  
                                        }}                            
                                        floated="right" 
                                        positive
                                        size='big' 
                                        id='provider_update_existing_job'
                                        icon>
                                        <Icon name="edit"></Icon>
                                        Edit job post
                                    </Button>                                     
                                    <Button 
                                    onClick={deleteJob}                         
                                        floated="right" 
                                        color='red'
                                        size='medium' 
                                        id='provider_delete_existing_job'
                                        icon>
                                        <Icon name="delete"></Icon>
                                        Delete job post
                                    </Button>
                                    
                                </>                      
                            )
                        }
                        

                        { candidates?.length > 0 && !loading && (
                            <>
                                 <Header as='h3' id='potential_caregivers_match'>Candidates that match your job opening</Header>
                                 <hr></hr>
                            </>
                        )}
                        { candidates?.length > 0 && !loading && (   
                                               
                            candidates?.map((candidate, index) => (
                                <Item key={index}>
                                    <Item.Content verticalAlign='left'>
                                    <Divider />  
                                        <Item.Header><Link to={`/provider/view/candidate/${candidate.userID}`} ><Header as="h4" color="blue">{ candidate?.fname} {candidate?.lname}</Header></Link></Item.Header>
                                        <Item.Description >
                                            <span><strong><i>License(s): ~~</i> </strong> </span>
                                            <List divided horizontal relaxed>    
                                                { candidate?.licenses?.map((license, index) => (
                                                    <List.Item key={index}>{license}</List.Item>
                                                ))}
                                            </List>           
                                        </Item.Description>
                                        <Item.Description>
                                        <span><strong><i>Availability: ~~</i> </strong> </span>
                                            <List divided horizontal relaxed>
                                                { candidate?.availability?.map((availability, index) => (
                                                    <List.Item key={index}> {availability}</List.Item>
                                                ))}                                        
                                            </List> 
                                        </Item.Description>
                                        <Item.Extra>

                                    
                                        </Item.Extra>
                                    </Item.Content>
                                </Item>
                            ))
                      
                        )
                        }
                        
                        </Grid.Column>
                    </Grid.Row>                   
                </Grid> 
            </Container>
        </div>
    )
}

export default ViewSinglePostingUI
